import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { comingSoonSubscribe } from "../../calls/post/subscribe";
import { ComingSoonFooter } from "../../components/ComingSoonFooter/ComingSoonFooter";
import { COMING_SOON_ACTIVE } from "../../config";
import { validateSubscribeForm } from "../../validation/subscribe";
import { MainTitle, Wrapper, MainContentWrapper, Line } from "./styles";

export const PrivaciPolicy = () => {
  const { params, footer, page } = useLoaderData();
  const pageData = page[0].acf;
  const footerData = footer[0].acf;

  const { title, main_content } = pageData;

  const [isFormPristine, setIsFormPristine] = useState(true);
  const [formValidationResult, setFormValidationResult] = useState(null);
  const [subscriptionResult, setSubscriptionResult] = useState(null);
  const {
    main_logo,
    main_background_image,
    main_section_content,
    main_section_title,
  } = pageData;
  const onPrivacyLinkClick = () => (window.location.href = "/privacypolicy");
  const onFormSubmit = async (e) => {
    setIsFormPristine(false);
    const { result, canProceed } = validateSubscribeForm(e.target);
    setFormValidationResult((old) => ({ ...old, ...result }));
    if (canProceed) {
      const fetchedResult = await comingSoonSubscribe(e);
      setSubscriptionResult(fetchedResult);
    }
  };

  return (
    <Wrapper>
      <MainTitle>{title}</MainTitle>
      <Line />
      <MainContentWrapper dangerouslySetInnerHTML={{ __html: main_content }} />
      {COMING_SOON_ACTIVE && (
        <ComingSoonFooter
          {...{ ...footerData, onPrivacyLinkClick, onFormSubmit }}
          validation={{
            isFormPristine,
            formValidationResult,
            subscriptionResult,
          }}
        />
      )}
    </Wrapper>
  );
};
