import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Img, Title, Date, Content, Column } from "./style";

export const CardM = ({
  withOpenSans = false,
  title,
  date,
  content,
  img,
  style = {},
  onClick = () => { },
}) => {
  return (
    <Wrapper style={style} onClick={onClick}  {...{withOpenSans}}>
      <Column style={{ height: "174px" }}>
        <Img src={img} />

        {date ? <Date>{date}</Date> : ""}
      </Column>
      <Column>
        <Title {...{ withOpenSans }}>{title}</Title>
        <Content
          text={content}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
          {...{ withOpenSans }}
        />
      </Column>
    </Wrapper>
  );
};

CardM.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.string,
  img: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

CardM.defaultProps = {
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  date: "16 March",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
  img: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
  buttonText: "",
  onButtonClick: () => { },
};
