import { RedirectPage } from ".";
import { getHomePageData } from "../../calls/get/HomePageData";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
import { getLanguagesData } from "../../calls/get/LanguagesData";
import { getRedirectPageData } from "../../calls/get/RedirectPageData";

export const redirectPageRoute = {
  path: ROUTES.REDIRECT,
  element: <RedirectPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    homepage: await getHomePageData(),
    langs: await getLanguagesData(),
    redirectPageData: await getRedirectPageData(),
  }),
};
