import { EMAIL_TEST_REGEX } from "../config";

export const validateSubscribeForm = (formElement) => {
  const f = new FormData(formElement);
  const fname = f.get("FNAME");
  const lname = f.get("LNAME");
  const privacypolicy = f.get("MMERGE3");
  const email = f.get("EMAIL");
  const result = {
    FNAME: !!fname,
    LNAME: !!lname,
    MMERGE3: !!privacypolicy,
    EMAIL: EMAIL_TEST_REGEX.test(email),
  };
  const canProceed = !Object.values(result).includes(false);
  return { result, canProceed };
};
