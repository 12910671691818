import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const RadioLabel = styled.label`
  font-size: 0.7rem;
`;

export const PrivacyLink = styled.span`
  color: var(--comanage-green-1);
  font-size: 0.8rem;
  cursor: pointer;
  @media (${SM_MEDIA_QUERY_RULE}) {
    font-size: 0.7rem;
    padding-bottom: 30px;
  }
`;

export const ErrorWarningRadio = styled.div`
  margin-top: 5px;
  position: absolute;
  border: solid 1px red;
  background-color: white;
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  transform: translate(calc(-50% + ${({ width }) => width * 0.5}px), -30%);
  width: fit-content;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  color: red;
`;

export const Wrapper = styled.div`
  width: fit-content;

  padding-right: 5px;
  ${({ withError }) =>
    withError
      ? css`
          border-bottom: solid 1px red;
        `
      : ""}
`;
