import React from "react";
import { Section4Styled as S4 } from "../style";

export const Section4 = ({
  list = [{ title: "", content: "", buttonText: "", buttonUrl: "" }],
}) => {
  return (
    <S4.Wrapper>
      {list.map(({ title, content, buttonUrl, buttonText }, key) => (
        <S4.Content.Wrapper {...{ key }}>
          <S4.Content.Title>{title}</S4.Content.Title>
          <S4.Content.Row>
            <S4.Content.Body dangerouslySetInnerHTML={{ __html: content }} />
            <S4.Content.Button href={buttonUrl} target="_blank">
              {buttonText}
            </S4.Content.Button>
          </S4.Content.Row>
        </S4.Content.Wrapper>
      ))}
    </S4.Wrapper>
  );
};
