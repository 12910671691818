import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.footer`
  background-color: var(--comanage-purple);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 30px;
  color: white;
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
`;

export const Infosection = styled.div`
  width: 540px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const FormSection = styled.div`
  width: 580px;
`;
export const InfoImage = styled.img`
  width: 300px;
  margin-left: "0px";
`;
export const InfoContent = styled.p`
  font-family: "bicyclette" sans-serif;
  font-size: 0.85rem;
  line-height: 1.8rem;
  @media (${SM_MEDIA_QUERY_RULE}) {
    line-height: 1.3rem;
    font-size: 0.7rem;
  }
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    align-items: center;
    input[type="text"] {
      width: 90%;
    }
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;
export const PrivacyPolicySection = styled.div`
  width: calc(540px + 580px + 60px);
  padding: 5px;
  margin-top: 40px;
  border-top: solid 1px white;
  text-align: center;
  font-size: 0.7em;
  letter-spacing: 0.5px;
  padding: 1.875em 0;
  span {
    color: var(--comanage-green-1);
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    border-top: solid 0px white;
    margin-right: 0px;
  }
`;

export const SocialMediaLinks = styled.div`
  @media (${SM_MEDIA_QUERY_RULE}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const SocialLink = styled.a`
  margin-right: 10px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-right: 0px;
  }
`;
export const SocialImag = styled.img`
  height: 25px;
  margin-right: 10px;
  margin-right: 10px;
  :hover {
    opacity: 0.7;
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-right: 0px;
  }
`;

export const SocialMadiaTitle = styled.h3`
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  font-style: normal;
  margin-bottom: 30px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

export const FormTitle = styled.h5`
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 0px;
  font-size: 0.8rem;
  line-height: 1.5rem;

  @media (${SM_MEDIA_QUERY_RULE}) {
    font-size: 20px;
    font-weight: 600;
    font-family: "bicyclette", sans-serif !important;
    margin-bottom: 0px;
  }
`;

export const ArrowImg = styled.img`
  visibility: visible;
  height: 50px;
  margin: 15px;
  display: unset;
`;

export const FetchResultMsgWrapper = styled.div`
  border: solid 1px ${({ success }) => (success ? "rgba(0,0,0,0)" : "red")};
  width: 50%;
  font-size: small;
  padding: 10px;
  border-radius: 10px;

  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-bottom: 20px;
  }
`;
