import React from "react";
import { NewsCardStyle } from "../style";

const { Wrapper, Img, ContentWrapper, Title, Date } = NewsCardStyle;
export const NewsCard = ({ date, img, title }) => {
  return (
    <Wrapper>
      <Img {...{ img }} />
      <ContentWrapper>
        <Title>{title}</Title>
        <Date>{date}</Date>
      </ContentWrapper>
    </Wrapper>
  );
};
