import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";
import { LoremIpsum } from "lorem-ipsum";

export const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 5,
    min: 2,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

const stepStruct = ({ title = "", body = "", action = "", position = "" }) => ({
  title: lorem.generateWords(3),
  body: lorem.generateParagraphs(1),
  position,
  action,
});

const mock = async () => [
  // intro
  stepStruct({
    title: "intro",
    body: "where it has to be explained how to use the dss",
    action: "start",
    position: "0",
  }),
  // layer 1
  stepStruct({
    title: "option 1 layer 1",
    body: "option 1 layer 1 body",
    action: "choose",
    position: "01",
  }),
  stepStruct({
    title: "option 2 layer 2",
    body: "option 2 layer 2 body",
    action: "choose",
    position: "02",
  }),
  stepStruct({
    title: "option 3 layer 3",
    body: "option 3 layer 3 body",
    action: "choose",
    position: "03",
  }),
  // layer 2
  // from step 1
  stepStruct({
    title: "option 1 layer 2 option 1",
    body: "option 1 layer 2 option 1 body",
    action: "chat",
    position: "011",
  }),
  stepStruct({
    title: "option 1 layer 2 option 2",
    body: "option 1 layer 2 option 2 body",
    action: "chat",
    position: "012",
  }),
  stepStruct({
    title: "option 1 layer 2 option 3",
    body: "option 1 layer 2 option 3 body",
    action: "chat",
    position: "013",
  }),
  // from step 2
  stepStruct({
    title: "option 2 layer 2 option 1",
    body: "option 2 layer 2 option 1 body",
    action: "chat",
    position: "021",
  }),
  stepStruct({
    title: "option 2 layer 2 option 2",
    body: "option 2 layer 2 option 2 body",
    action: "chat",
    position: "022",
  }),
  stepStruct({
    title: "option 2 layer 2 option 3",
    body: "option 2 layer 2 option 3 body",
    action: "chat",
    position: "023",
  }),
  // from step 3
  stepStruct({
    title: "option 3 layer 2 option 1",
    body: "option 3 layer 2 option 1 body",
    action: "chat",
    position: "031",
  }),
  stepStruct({
    title: "option 3 layer 2 option 2",
    body: "option 3 layer 2 option 2 body",
    action: "chat",
    position: "032",
  }),
  stepStruct({
    title: "option 3 layer 2 option 3",
    body: "option 3 layer 2 option 3 body",
    action: "chat",
    position: "033",
  }),
];

export const getDssTreeStruct = async () => {
  const data = await fetchData(ENDPOINTS.GET.DSS_CARDS, {
    method: METHODS.GET,
  });

  return data.map(({ acf }) => acf);
  const m = await mock();
  console.log(m);
  return m;
};
