import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "./../../config";
export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  height: fit-content;
  width: 394px;
  flex-direction: column;
  gap: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100%;
  }
`;
export const SuccessWarning = styled.div`
  margin-top: 5px;
  border: solid 1px var(--comanage-green-1);
  position: relative;
  top: -20px;
  background-color: white;
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  transform: translate(calc(-50% + ${({ width }) => width * 0.5}px), -50%);
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  color: var(--comanage-green-3);
`;
export const ErrorWarning = styled.div`
  margin-top: 5px;
  border: solid 1px red;
  position: relative;
  top: -20px;
  background-color: white;
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  transform: translate(calc(-50% + ${({ width }) => width * 0.5}px), -50%);
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  color: red;
`;

export const Button = styled.button`
  all: unset;
  text-transform: uppercase;
  color: white;
  border-radius: 0px 10px 0px 0px;
  min-width: 107px;
  min-height: 43px;
  background: var(--comanage-green-2) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background: var(--comanage-green-1) 0% 0% no-repeat padding-box;
  }
`;

export const StyledFormInput = styled.input`
  ${({ width }) => (width ? `width:${width};` : "")}
  all:unset;
  background-color: var(--comanage-blue-2);
  letter-spacing: 1px;
  color: white;
  padding-left: 20px;
  width: 287px;
  height: 43px;
  ::placeholder {
    color: white;
    padding-left: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  cursor: pointer;
  /* text-decoration: underline;
  color: white; */
  color: var(--comanage-green-2);
  margin-left: 5px;
`;
