import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const getLanguagesData = async () => {
  const data = await fetchData(ENDPOINTS.GET.COP_LANGUAGES_DATA, {
    method: METHODS.GET,
  });
  return data;
};
