import AppTemplate from ".";
import { getCopPageData } from "../../calls/get/CopPageData";
import { getLanguagesList } from "../../calls/get/LanguagesList";
import { getFooterData } from "../../calls/get/footer";
import { getPilotsList } from "../../calls/get/pilotsList";
import { MULTI_LANG_PAGES } from "../../config";
import { FetchErrorPage } from "../FetchError";

export const appTemplate = ({ subRoutes, config }) => {
  return {
    path: "/",
    element: <AppTemplate />,
    errorElement: <FetchErrorPage />,
    children: subRoutes,
    loader: async ({ params, ...stuff }) => {
      const multiLang = MULTI_LANG_PAGES.includes(window.location.pathname);
      let struct = {
        params,
        footer: await getFooterData(),
        pilots: await getPilotsList(),
        copPageData: await getCopPageData(),
      };
      if (multiLang) {
        struct = {
          multiLang,
          languages: await getLanguagesList(),
          ...struct,
        };
      }
      return struct;
    },
  };
};
