import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const getCOPPostDetail = async ({ id }) => {
  const url = ENDPOINTS.GET.COP_POST;

  const data = await fetchData(url, {
    method: METHODS.GET,
  });
  return [data.find(({ id: pId }) => pId === +id)];
};
