import React, { useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { comingSoonSubscribe } from "../../calls/post/subscribe";
import { ComingSoonFooter } from "../../components/ComingSoonFooter/ComingSoonFooter";
import { ROUTES } from "../../config/menuRoutes";
import { validateSubscribeForm } from "../../validation/subscribe";
import {
  MainSection,
  Wrapper,
  LogoImg,
  LeftSection,
  RightSection,
  Title,
  LogoTitle,
  MainContent,
  LogoWrapper,
  BgImage,
} from "./styles";

export const ComingSoon = () => {
  const { params, page, footer } = useLoaderData();
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [formValidationResult, setFormValidationResult] = useState(null);
  const [subscriptionResult, setSubscriptionResult] = useState(null);
  const pageData = page[0].acf;
  const footerData = footer[0].acf;
  const {
    main_logo,
    main_background_image,
    main_section_content,
    main_section_title,
  } = pageData;
  const onPrivacyLinkClick = () => (window.location.href = ROUTES.COMING_SOON_PRIVACY);
  const onFormSubmit = async (e) => {
    setIsFormPristine(false);
    const { result, canProceed } = validateSubscribeForm(e.target);
    setFormValidationResult((old) => ({ ...old, ...result }));
    if (canProceed) {
      const fetchedResult = await comingSoonSubscribe(e);
      setSubscriptionResult(fetchedResult);
    }
  };
  return (
    <Wrapper>
      <MainSection>
        <BgImage {...{ src: main_background_image.url }} />
        <LeftSection>
          <LogoWrapper>
            <LogoTitle>Coming Soon</LogoTitle>
            <LogoImg {...{ src: main_logo.url }} />
          </LogoWrapper>
        </LeftSection>
        <RightSection>
          <Title>{main_section_title}</Title>
          <MainContent
            dangerouslySetInnerHTML={{ __html: main_section_content }}
          ></MainContent>
        </RightSection>
      </MainSection>
      <ComingSoonFooter
        {...{ ...footerData, onPrivacyLinkClick, onFormSubmit }}
        validation={{
          isFormPristine,
          formValidationResult,
          subscriptionResult,
        }}
      />
    </Wrapper>
  );
};
