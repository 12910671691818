import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Wrapper,
  Icon,
  Line,
  LinkList,
  SocialMediaLinks,
  MenuLink,
  SocialTitle,
  Row,
  SocialLink,
  SocialImag,
  MenuSubLink,
  StyledLink,
  LangIcon,
  LineWrapper,
  MenuLinkIcon,
} from "./style";
import {
  COP_LOCALSTORAGE_LANG_KEY,
  COP_LOCALSTORAGE_LANG_LIST_KEY,
  SM_MEDIA_QUERY_THRESHOLD,
} from "../../config";
import { ROUTES } from "../../config/menuRoutes";

const MenuItem = ({
  children = [],
  title,
  href,
  toggleOpen = () => {},
  icon,
  onClick = () => {},
}) => {
  const scrollToElement = (id) =>
    document.querySelector(id).scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  const scrollToTop = () => window.scrollTo({ top: 0 });

  return (
    <React.Fragment key={title}>
      {href === "#footer" ? (
        <MenuLink
          onClick={() => {
            scrollToElement(href);
            toggleOpen();
          }}
        >
          {title}
        </MenuLink>
      ) : (
        <StyledLink
          to={href}
          {...(children.length ? { to: href } : {})}
          onClick={(e) => {
            if (href)
              href.includes("#") ? scrollToElement(href) : scrollToTop();
            onClick(e);
            toggleOpen();
          }}
        >
          <MenuLink>
            {icon ? (
              <LangIcon src={icon} style={{ "--size": "20px" }} />
            ) : (
              <></>
            )}
            {title}
          </MenuLink>{" "}
        </StyledLink>
      )}
      {children &&
        children.map(({ title, href = "" }, i) => (
          <StyledLink
            to={href}
            onClick={toggleOpen}
            {...{
              key: title,
              style: children.length - 1 === i ? { marginBottom: "10px" } : {},
            }}
          >
            <MenuSubLink>- {title}</MenuSubLink>
          </StyledLink>
        ))}
    </React.Fragment>
  );
};

export const Menu = ({
  designer_label,
  menuLinks,
  social_img_1_menu,
  social_img_2_menu,
  social_img_3_menu,
  social_url_1,
  social_url_2,
  social_url_3,
  sb = false,
  multiLang,
  languages = [],
  menuLess,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const socialNetworksDatas = [
    [social_img_1_menu, social_url_1],
    [social_img_2_menu, social_url_2],
    [social_img_3_menu, social_url_3],
  ].filter(([img]) => !!img);
  const [open, setOpen] = useState(false);
  const pristine = useRef(true);
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const toggleOpen = () => {
    setOpen(!open);
    pristine.current = false;
  };

  const navigatorLang = window.navigator.language.split("-")[0].toLowerCase();
  let currentLangFromNavigator;
  try {
    currentLangFromNavigator =
      languages.find((l) => l.acf.navigator_reference === navigatorLang)?.acf
        ?.id || "eng";
  } catch (err) {
    console.error(err);
  }
  const currentLang =
    window.localStorage.getItem(COP_LOCALSTORAGE_LANG_KEY) ||
    currentLangFromNavigator ||
    "eng";
  if (!currentLang && multiLang) {
    const defaultlangId = languages.find((v) => v.acf.default === true).acf.id;
    window.localStorage.setItem(COP_LOCALSTORAGE_LANG_KEY, defaultlangId);
  }

  const setNewLanguage = (e, name, id, icon) => {
    window.localStorage.setItem(COP_LOCALSTORAGE_LANG_KEY, id);
  };
  const languagesIcons = JSON.parse(
    localStorage.getItem(COP_LOCALSTORAGE_LANG_LIST_KEY)
  );
  const currentLangIcon = languagesIcons[currentLang];
  const currentRoute = window.location.pathname.replace(/^./gm, "");
  return (
    <>
      <Icon
        onClick={toggleOpen}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseOver={() => setHover(true)}
      >
        {multiLang ? (
          <LangIcon
            src={currentLangIcon}
            style={{ "--size": isMobile ? "30px" : "50px" }}
          />
        ) : (
          <></>
        )}
        <LineWrapper open={open}>
          <Line open={open} rotateLine={true} hover={hover} />
          <Line open={open} rotateLine={false} hover={hover} />
          {!open && <Line hover={hover} />}
        </LineWrapper>
      </Icon>
      <Wrapper {...{ open, pristine: pristine.current }}>
        {multiLang && !menuLess && (
          <LinkList>
            {currentRoute === ROUTES.COP_CATEGORY_PAGE ? (
              <React.Fragment>
                <MenuItem
                  href={ROUTES.COPPAGE}
                  title="HOME"
                  toggleOpen={toggleOpen}
                />
              </React.Fragment>
            ) : (
              <></>
            )}
            {languages.map(({ acf: { name, id, icon }, ...stuff }, key) => {
              return sb ? (
                <React.Fragment key={key + "_lang"}>
                  <MenuLink {...{ key }}>{name}</MenuLink>{" "}
                </React.Fragment>
              ) : (
                <MenuItem
                  {...{
                    children: [],
                    title: name,
                    href: document.location.href,
                    key,
                    isMobile,
                    icon: languagesIcons[id],
                    toggleOpen,
                    onClick: (e) => {
                      setNewLanguage(e, name, id, icon);
                      if (
                        window.location.pathname ===
                        "/" + ROUTES.COP_CATEGORY_PAGE
                      ) {
                        window.location.pathname = `/${ROUTES.COPPAGE}`;
                        window.location.search = ``;
                      }
                    },
                  }}
                />
              );
            })}
          </LinkList>
        )}
        {!multiLang && !menuLess && (
          <LinkList>
            {menuLinks.map(({ title, href = "", children = [] }, key) =>
              sb ? (
                <React.Fragment key={title}>
                  <MenuLink {...{ key }}>{title}</MenuLink>{" "}
                  {children &&
                    children.map(({ title, href = "" }) => (
                      <MenuSubLink {...{ key: title }}>- {title}</MenuSubLink>
                    ))}
                </React.Fragment>
              ) : (
                <MenuItem
                  {...{ children, title, href, key, isMobile, toggleOpen }}
                />
              )
            )}
          </LinkList>
        )}
        {menuLess && (
          <LinkList>
            <MenuItem
              {...{
                children: [],
                title: "Back",
                isMobile,
                toggleOpen,
                onClick: () => {
                  navigate(-1);
                },
              }}
            />
          </LinkList>
        )}
        <SocialMediaLinks>
          <SocialTitle>Follow Us</SocialTitle>
          <Row style={{ justifyContent: "flex-start" }}>
            {socialNetworksDatas.map(([{ url: src }, href]) => (
              <SocialLink {...{ key: src, href }}>
                <SocialImag {...{ src }} />
              </SocialLink>
            ))}
          </Row>
        </SocialMediaLinks>
      </Wrapper>
    </>
  );
};

Menu.propTypes = {
  designer_label: PropTypes.string,
};

Menu.defaultProps = {
  designer_label: "designer_label",
};
