import styled from "styled-components";

export const StyledFormButton = styled.input`
  background-color: ${({ outline }) =>
    !outline ? "var(--comanage-green-3)" : "rgba(0,0,0,0)"};
  border: solid 1px
    ${({ outline }) =>
      !outline ? "var(--comanage-green-2)" : "var(--comanage-green-3)"};
  /* border: solid 1px var(--comanage-green-1); */
  border-radius: 20px 3px 20px 3px;
  /* transition: all 0.23s ease-in-out 0s; */
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 0px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: fit-content;
  letter-spacing: 2px;
  :hover {
    background-color: var(--comanage-green-3);
    border-color: var(--comanage-green-3);
  }
`;
