import styled from "styled-components";

export const Element = styled.div`
  @keyframes hide {
    0% {
      opacity: 1;
    }
    1% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  min-height: 30px;
  min-width: 30px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  transition: opacity 3s;
  animation-duration: 3s;
  background: linear-gradient(
    0turn,
    var(--comanage-green-2),
    var(--comanage-green-1)
  );
  :hover{
    transform: scale(1.3);
  }
  transition: transform 200ms;
`;
