import { getPastEventPageData } from "../../calls/get/pastEventPage";
import { getPostList } from "../../calls/get/postList";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
import { PastEventsPage } from "./index";

export const pastEventsPageRoute = {
  path: ROUTES.PAST_EVENTS,
  element: <PastEventsPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    pageData: await getPastEventPageData(),
    list: await getPostList(),
  }),
};
