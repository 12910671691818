import { useLoaderData } from "react-router-dom";
import { Title, Wrapper } from "./style";
import { Header } from "./../../components/Header/Header";

import { Deliverables } from "./components/Deliverables";
import { TrainingMaterial } from "./components/Trainingmaterial";
import { CommKits } from "./components/Commkits";
import { Images } from "./components/Images";
import {
  SM_MEDIA_QUERY_THRESHOLD,
  TRAINING_MATERIALS_NEWS_ENEBLED,
} from "../../config";
import { useEffect } from "react";

export const LibraryPage = () => {
  const { files: files_raw, pageData, pilots } = useLoaderData();
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const { deliverables = [], training_material } = files_raw
    .map(({ acf }) => acf)
    .reduce((acc, f) => {
      if (!acc[f.type]?.length) acc[f.type] = [];
      acc[f.type] = [...acc[f.type], f];
      return acc;
    }, {});

  const images = Object.entries(pageData[0].acf)
    .filter(([n]) => n !== "header_logo" && n !== "header_background")
    .filter(([, { type = null }]) => type && type === "image")
    .map(([, data]) => data);

  const {
    header_logo,
    header_title,
    header_background,
    general_comm_kit_download_1,
    general_comm_kit_download_2,
    general_comm_kit_download_3,
    general_comm_kit_download_4,
    general_comm_kit_download_5,
    general_comm_kit_download_6,
    general_comm_kit_download_7,
    general_comm_kit_download_8,
    general_comm_kit_download_9,
    general_comm_kit_download_10,
  } = pageData[0].acf;

  const generalCommKits = {
    id: "Project",
    general_comm_kit_download_1,
    general_comm_kit_download_2,
    general_comm_kit_download_3,
    general_comm_kit_download_4,
    general_comm_kit_download_5,
    general_comm_kit_download_6,
    general_comm_kit_download_7,
    general_comm_kit_download_8,
    general_comm_kit_download_9,
    general_comm_kit_download_10,
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Header
        logo={header_logo.url}
        title={header_title}
        subTile={""}
        content={""}
        background={header_background?.url}
        clearBg={true}
        smallHeader={isMobile}
      />
      <Wrapper>
        <Title style={{ marginTop: "100px" }}>Deliverables</Title>
        {deliverables.length ? (
          <>
            <Deliverables {...{ deliverables }} />
          </>
        ) : (
          <Title
            style={{ marginTop: "100px", fontWeight: 300, fontSize: "20px" }}
          >
            Coming Soon
          </Title>
        )}
        {TRAINING_MATERIALS_NEWS_ENEBLED && (
          <>
            <Title style={{ marginTop: "100px" }}>Training Material</Title>
            <TrainingMaterial {...{ training_material, isMobile }} />
          </>
        )}
      </Wrapper>
      <CommKits
        {...{ pilots: [{ acf: generalCommKits }, ...pilots], isMobile }}
      />
      {images.length ? (
        <>
          <Title style={{ marginTop: "100px" }}>Pictures</Title>
          <Images {...{ images: [...images] }} />
        </>
      ) : (
        ""
      )}
    </>
  );
};
