import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

const absolutePosition = css`
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;
export const BgImage = styled.img`
  ${absolutePosition}

  z-index: 1;
`;
export const BgImageFilter = styled.div`
  ${absolutePosition}

  backdrop-filter: contrast(1.5) hue-rotate(6deg);
  /* backdrop-filter:blur(14px) contrast(1.5) hue-rotate(6deg); */
  z-index: 2;
`;
export const BgImageLayer = styled.div`
  ${absolutePosition}
  z-index: 3;
  background: linear-gradient(
    310deg,
    rgb(0 48 17 / 75%),
    rgba(173, 246, 255, 0.27)
  );
`;
export const BgImageWrapper = styled.div`
  ${absolutePosition}
`;

export const SvgWrapper = styled.svg`
  position: absolute;
  transform: scaleX(-1);
  /* ${absolutePosition} */
  z-index: 4;
`;
