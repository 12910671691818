import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyledFormButton } from "./style";

export const FormButton = ({
  value,
  name,
  style = {},
  onClick,
  outline,
  onHoverStyle = {},
}) => {
  const [hover, setHover] = useState(false);
  return (
    <StyledFormButton
      {...{
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onMouseOver: () => setHover(true),
        type: "submit",
        value,
        name,
        style: hover ? { ...style, ...onHoverStyle } : style,
        onClick,
        outline,
        onHoverStyle,
      }}
    />
  );
};

FormButton.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  errorText: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};

FormButton.defaultProps = {
  value: "form button text",
  name: "subscribe",
  width: "fit-content",
  onClick: () => {},
};
