import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Img, ContentWrapper, Title, Date, Content } from "./style";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";

export const CardHomepage = ({ date, img, title, content }) => {
  return (
    <Wrapper>
      <Img {...{ img }} />
      <ContentWrapper>
        <Title>{title}</Title>
        <Date>{date}</Date>
        {content && (
          <Content
            text={content}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

CardHomepage.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  img: PropTypes.string,
  content: PropTypes.string,
};

export const defaultProps = {
  title: "Lorem ipsum ",
  date: "22 mar 2023",
  img: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet. consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
};

CardHomepage.defaultProps = defaultProps;
