import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 1000px;
`;

export const Title = styled.h2`
  color: var(--comanage-purple);
  margin: 30px;
`;

export const Row = styled.div`
  width: 90vw;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 0px;
  }
`;

export const EventsWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;
  margin-bottom: 100px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    justify-content: center;
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
