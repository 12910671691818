import styled, { css } from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  ${({ smallHeader }) =>
    smallHeader
      ? css`
          max-height: 327px;
          min-height: 327px;
        `
      : css`
          max-height: 604px;
          min-height: 604px;
        `}

  position: relative;
  overflow: hidden;
  margin-bottom: -1px;
`;

export const Logo = styled.img`
  position: absolute;
  width: 252px;
  top: 10px;
  left: 2%;
  z-index: 5;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 150px;
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
    left: calc(50% - (var(--max-width) / 2));
  }
`;
export const ImageBottomLeft = styled.img`
  position: absolute;
  z-index: 2;
  bottom: 0%;
  top: 10%;
  left: -38%;
  @media (${SM_MEDIA_QUERY_RULE}) {
    top: 24%;
    left: -174%;
  }
`;
export const ImageTopRight = styled.img`
  position: absolute;
  z-index: 2;
  bottom: 0%;
  width: 48%;
  top: -22%;
  right: 48%;
  transform: translate(100%, -50%);
`;
export const BackgroundImage = styled.div`
  width: 100%;
  top: 0%;
  bottom: 0%;
  position: absolute;

  ${({ bg }) =>
    !!bg
      ? css`
          background: url(${bg});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          background: linear-gradient(
            27deg,
            var(--comanage-green-2) 0%,
            var(--comanage-green-1) 35%,
            var(--comanage-purple) 100%
          );
        `}
`;
export const Title = styled.h1`
  font: normal normal bold 50px/56px bicyclette;
  max-width: 893px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    font-size: 20pt;
    line-height: 20pt;
    margin-right: 5%;
  }
`;
export const SubTitle = styled.h4`
  font: normal normal bold 24px/30px bicyclette;
`;
export const Content = styled.div`
  font: normal normal normal 16px/19px bicyclette;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  z-index: 6;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  color: white;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
    position: relative;
    left: calc(50% - (var(--max-width) / 2));
  }
`;

export const Filter = styled.div`
  position: absolute;
  top: 0%;
  bottom: 0%;
  width: 100%;
  ${({ filterColor }) =>
    filterColor
      ? css`
          background-color: ${filterColor};
        `
      : css`
          opacity: 0.7;
          background-color: var(--comanage-green-1);
        `}
  z-index: 1;
`;
