import { Link, useLoaderData } from "react-router-dom";
import { Title, Wrapper, NewsWrapper, Row, EventsWrapper } from "./style";
import { Header } from "../../components/Header/Header";
import { CardXL } from "../../components/CardXL/CardXL";
import { CardM } from "../../components/CardM/CardM";
import { ROUTES } from "../../config/menuRoutes";
import { useEffect, useState } from "react";
import { FormButton } from "../../components/FormButton/FormButton";
import { FirstCard } from "./components/FirstCard";
import { checkIfFutureDate } from "../../utils/checkIfFutureDate";
import { getTimestampFromStr } from "../../utils/getTimestampFromStr";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { DateTime } from "luxon";
import { eventDateFormat } from "../../utils/getLatestEvent";

const newsStep = 2;
const eventsStep = 4;
export const NewsEventsPage = () => {
  const [displayedNewsLength, setDisplayedNewsLength] = useState(newsStep);
  const [displayedEventsLength, setDisplayedEventsLength] =
    useState(eventsStep);
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const {
    pageData: [
      {
        acf: {
          header_logo: { url: logo },
          header_bg_image: { url: header_bg_image },
          header_title: title,
        },
      },
    ],
    list: listRaw,
  } = useLoaderData();

  const list = listRaw.map(({ acf, id, date }) => ({ ...acf, id, date }));
  const news = list
    .filter(({ type }) => type === "news")
    .sort(({ date: A }, { date: B }) =>
      new Date(A).getTime() > new Date(B).getTime() ? -1 : 1
    );

  const events = list
    .filter(({ type, event_date }) =>
      type === "event" ? checkIfFutureDate(event_date) : false
    )
    .map((e) => ({
      unix: DateTime.fromFormat(e.event_date, eventDateFormat).toUnixInteger(),
      ...e,
    }))
    .sort((a, b) => (a.unix > b.unix ? -1 : 1));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Header
        {...{
          logo,
          title,
          content: "",
          background: header_bg_image,
          subTile: "",
          clearBg: true,
          smallHeader: isMobile,
        }}
      />
      <Wrapper>
        {news?.length ? (
          <>
            <Title firstTitle>News</Title>
            <NewsWrapper>
              {news
                .filter((_, i) => i < displayedNewsLength)
                .map(
                  (
                    {
                      title,
                      date,
                      preview_text: content,
                      header_img: { url: img },
                      id,
                    },
                    key
                  ) => (
                    <Link
                      {...{ key }}
                      to={`/post/${id}`}
                      style={{ all: "unset", cursor: "pointer" }}
                    >
                      {isMobile ? (
                        <CardM
                          {...{
                            title,
                            date: new DateTime(date).toFormat("dd MMMM yyyy"),
                            content,
                            img,
                          }}
                        />
                      ) : (
                        <CardXL
                          {...{
                            title,
                            date: new DateTime(date).toFormat("dd MMMM yyyy"),
                            content,
                            img,
                          }}
                        />
                      )}
                    </Link>
                  )
                )}
            </NewsWrapper>
          </>
        ) : (
          <></>
        )}
        {displayedNewsLength < news.length && (
          <Row style={{ justifyContent: "center", marginTop: "5%" }}>
            <FormButton
              {...{
                style: {
                  backgroundColor: "white",
                  color: "var(--comanage-green-3)",
                  borderColor: "var(--comanage-green-3)",
                },
                onHoverStyle: {
                  backgroundColor: "var(--comanage-green-2)",
                  color: "white",
                },
                name: "SHOW MORE",
                value: "SHOW MORE",
                onClick: () =>
                  setDisplayedNewsLength(displayedNewsLength + newsStep),
              }}
            />
          </Row>
        )}
        <Title style={{ marginTop: "100px" }}>Events</Title>
        {!isMobile && events[0]?.id && (
          <Row>
            <Link
              to={`/post/${events[0].id}`}
              style={{ all: "unset", cursor: "pointer" }}
            >
              <FirstCard
                {...{
                  ...events[0],
                  date: DateTime.fromFormat(
                    events[0].event_date,
                    "dd/MM/yyyy h:mm a"
                  ).toFormat("dd MMMM yyyy"),
                }}
              />
            </Link>
          </Row>
        )}
        <EventsWrapper>
          <Row
            style={{
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            {events
              .filter((_, i) =>
                isMobile ? true : i !== 0 && i < displayedEventsLength
              )
              .map(
                (
                  {
                    title,
                    event_date,
                    preview_text: content,
                    header_img: { url: img },
                    id,
                  },
                  key
                ) => (
                  <Link
                    {...{ key }}
                    to={`/post/${id}`}
                    style={{ all: "unset", cursor: "pointer" }}
                  >
                    <CardM
                      {...{
                        key: `${title}_${key}`,
                        title,
                        date: DateTime.fromFormat(
                          event_date,
                          "dd/MM/yyyy h:mm a"
                        ).toFormat("dd MMMM yyyy"),
                        content,
                        img,
                      }}
                    />
                  </Link>
                )
              )}
          </Row>
        </EventsWrapper>
        <Row
          style={{
            justifyContent: "center",
            marginTop: "5%",
            flexDirection: isMobile ? "column" : "row",
            gap: "20px",
            marginBottom: "50px",
          }}
        >
          {displayedEventsLength < events.length && (
            <FormButton
              {...{
                name: "SHOW MORE",
                value: "SHOW MORE",

                onClick: () =>
                  setDisplayedEventsLength(displayedEventsLength + eventsStep),
              }}
            />
          )}
          <Link to={`/${ROUTES.PAST_EVENTS}`}>
            <FormButton
              {...{
                name: "PAST EVENTS",
                value: "PAST EVENTS",
                onHoverStyle: {
                  backgroundColor: "var(--comanage-green-1)",
                  borderColor: "var(--comanage-green-1)",
                },
              }}
            />
          </Link>
        </Row>
      </Wrapper>
    </>
  );
};
