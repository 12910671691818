import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const MenuSubLink = styled.span`
  font: normal normal normal 20px/28px bicyclette;
  letter-spacing: 0px;
  cursor: pointer;
  margin-left: 6px;
  :hover {
    opacity: 0.6;
  }
`;
export const MenuLink = styled.span`
  font: normal normal normal 30px/47px bicyclette;
  letter-spacing: 0px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100vw - (30px * 2));
  }
`;
export const SocialMediaLinks = styled.div`
  margin-left: 50px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const Wrapper = styled.div`
  @keyframes slidein {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes slideout {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
  }
  position: fixed;
  width: 500px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100%;
    min-width: 100%;
  }
  top: 0px;
  bottom: 0px;
  right: 0px;

  animation: ${({ open, pristine }) =>
      pristine ? "" : open ? "slidein" : "slideout"}
    0.3s;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(100%)")};
  background-color: var(--comanage-green-3);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-family: "bicyclette" !important;
  z-index: 19;
`;

export const LineWrapper = styled.div`
  width: 100px;
  height: 100px;
  ${({ open }) =>
    !open
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        `
      : css`
          position: relative;
        `}
`;

export const Icon = styled.div`
  z-index: 20;
  background-color: var(--comanage-green-3);
  /* width: 180px; */
  width: fit-content;
  padding: 0% 20px;
  height: 100px;
  border-radius: 0px 0px 0px 10px;
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* gap: 10px; */
  cursor: pointer;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 80px;
    height: 80px;
  }
`;
export const Line = styled.div`
  ${({ hover }) =>
    hover
      ? css`
          opacity: 0.6;
        `
      : ""}
  border-radius: 5px;
  height: 5px;
  width: 50%;
  min-width: 40px;
  background-color: var(--comanage-blue-1);
  ${({ open }) =>
    open
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
            rotate(${({ rotateLine }) => (rotateLine ? "45deg" : "-45deg")});
        `
      : ""}
`;
export const LineContainer = styled.div`
  width: 100px;
  min-height: 100px;
`;

export const SocialTitle = styled.h4`
  font: normal normal normal 30px/47px bicyclette;
  margin-bottom: 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const SocialLink = styled.a``;

export const SocialImag = styled.img`
  height: 25px;
  margin-right: 10px;
  margin-right: 10px;
  :hover {
    opacity: 0.7;
  }
`;

export const StyledLink = styled(Link)`
  all: unset;
`;

export const LangIcon = styled.div`
  background: url(${({ src }) => src});
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  background-size: cover;
  background-position: center;
  border-radius: var(--size);
`;
