import React from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  PrivacyPolicySection,
  MainSection,
  FetchResultMsgWrapper,
} from "./style";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { DesktopTemplate } from "./deviceTemplates/Desktop.template";
import { MobileTemplate } from "./deviceTemplates/Mobile.template";
import { Fields } from "./components/Fields";
import { PrivacyLink } from "../FormRadio/styles";
import { ROUTES } from "../../config/menuRoutes";

const rowInputStyle = { width: "45%", style: { width: "45%" } };

export const ComingSoonFooter = ({
  footer_info_image,
  footer_content,
  subscribe_form_title,
  social_network_img_1,
  social_network_img_2,
  social_network_img_3,
  social_network_url_1,
  social_network_url_2,
  social_network_url_3,
  onPrivacyLinkClick,
  mobile_arrow_img,
  onFormSubmit,
  validation: { isFormPristine, formValidationResult, subscriptionResult },
  ...props
}) => {
  const socialNetworksDatas = [
    [social_network_img_1, social_network_url_1],
    [social_network_img_2, social_network_url_2],
    [social_network_img_3, social_network_url_3],
  ].filter(([img]) => !!img);

  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const templateData = {
    socialNetworksDatas,
    subscribe_form_title,
    footer_content,
    onFormSubmit,
    footer_info_image,
    rowInputStyle,
    onPrivacyLinkClick,
    subscriptionResult,
    MessageComponent: () =>
      subscriptionResult ? (
        <FetchResultMsgWrapper
          success={subscriptionResult.result === "success"}
        >
          {subscriptionResult.msg}
        </FetchResultMsgWrapper>
      ) : (
        <></>
      ),
  };
  if (isMobile) {
    templateData.mobile_arrow_img = mobile_arrow_img;
  }

  const fieldsData = {
    isMobile,
    rowInputStyle,
    formValidationResult,
    isFormPristine,
    onPrivacyLinkClick: () =>
      (window.location.href = ROUTES.COMING_SOON_PRIVACY),
  };
  return (
    <Wrapper>
      <MainSection>
        {isMobile ? (
          <MobileTemplate {...templateData}>
            <Fields {...fieldsData} />
          </MobileTemplate>
        ) : (
          <DesktopTemplate {...templateData}>
            <Fields {...fieldsData} />
          </DesktopTemplate>
        )}
      </MainSection>

      <PrivacyPolicySection>
        <PrivacyLink onClick={onPrivacyLinkClick}>Privacy Policy</PrivacyLink>
      </PrivacyPolicySection>
    </Wrapper>
  );
};

ComingSoonFooter.propTypes = {
  infoImageUrl: PropTypes.string,
};

ComingSoonFooter.defaultProps = {
  validation: {
    isFormPristine: true,
    formValidationResult: {},
    subscriptionResult: null,
  },
  onPrivacyLinkClick: () => {},
  onFormSubmit: () => {},
  main_section_title: "main_section_title",
  main_section_content: "main_section_content",
  footer_content: "footer_content",
  social_network_url_1: "social_network_url_1",
  social_network_url_2: "social_network_url_2",
  social_network_url_3: "social_network_url_3",
  subscribe_form_title: "subscribe_form_title.",
  footer_info_image: {
    url: "url",
  },
  social_network_img_1: {
    url: "url",
  },
  social_network_img_2: {
    url: "url",
  },
  social_network_img_3: {
    url: "url",
  },
};
