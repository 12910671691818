import styled, { css } from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  --sub-width: calc(var(--max-width) - 40px);
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    margin-left: 0%;
    width: var(--max-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    --sub-width: calc(var(--max-width) - 40px);
  }
`;

export const Title = styled.h2`
  color: var(--comanage-purple);
  margin: 30px;
  margin-top: 0px;
  margin-bottom: 50px;
`;

export const DeliverablesWrapper = styled.div`
  width: var(--sub-width);
  margin-left: 20px;
  margin-top: 0px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    gap: 40px;
    width: 90vw;
  }
`;

export const CommKitsWrapper = styled.div`
  margin-top: 100px;
  background-color: var(--comanage-purple);
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TmaterialsWrapper = styled.div`
  width: var(--sub-width);
  margin-left: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
  }
`;
export const Row = styled.div`
  width: var(--sub-width);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
export const Column = styled.div`
  width: var(--sub-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
  }
`;

export const PicturesWrapper = styled.div`
  max-width: var(--max-width);
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: calc((100vw - var(--max-width)) / 2);
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 5%;
    margin-right: 5%;
    gap: 0px;
    width: 90vw;
  }
`;
export const LibraryImage = styled.div`
  min-width: 197px;
  min-height: 167px;
  background: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  border: solid 2px white;
  :hover {
    border: solid 2px var(--comanage-green-2);
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    min-width: 40vw;
  }
`;

export const ModalBg = styled.div`
  position: fixed;
  z-index: 50;
  width: 100%;
  top: 0px;
  bottom: 0px;
  opacity: 0.4;
  background-color: var(--comanage-green-1);

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CloseModalButton = styled.div`
  position: fixed;
  width: 100px;
  height: 100px;
  z-index: 51;
  right: 0px;
  top: 0px;
  background-color: var(--comanage-green-3);
  border-radius: 0px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 80px;
    height: 80px;
  }
`;
export const BigImage = styled.img`
  width: 90%;
  position: fixed;
  width: 60%;
  top: 10%;
  left: 20%;
  z-index: 51;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Line = styled.div`
  :hover {
    opacity: 0.6;
  }
  border-radius: 5px;
  height: 5px;
  width: 70%;
  background-color: black;
  ${({ open }) =>
    open
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
            rotate(${({ rotateLine }) => (rotateLine ? "45deg" : "-45deg")});
        `
      : ""}
`;

export const Body = styled.div`
  width: var(--max-width);
`;
