import { io } from "socket.io-client";
import { SOCKET_CONFIG, SOCKET_DSS_MESSAGE_TYPE } from "../../config";
import { SAME_URL } from "../../config/connectionSettings";

export class DssSocket {
  static socket;
  static callbackObj = {};
  static connected = false;
  constructor() {
    try {
      if (!DssSocket.socket) {
        DssSocket.socket = io("https://comanage-dev-be.spindoxlabs.com/", SOCKET_CONFIG);
        // DssSocket.socket = io(window.location.origin, SOCKET_CONFIG);
      }
      this.onMessageRecived();
    } catch (err) {
      console.error("socket initialization failed");
      console.error(err);
    }
  }

  onMessageRecived = () => {
    for (const id in DssSocket.callbackObj) {
      DssSocket.callbackObj[id]();
    }
    DssSocket.socket.on(SOCKET_DSS_MESSAGE_TYPE, (value) => {
      const {
        data: { response, pages },
      } = JSON.parse(value);
      let text = response;
      try {
        text = response.replace("<html>", "").replace("</html>", "");
      } catch (err) {
        text = response;
      }
      for (const id in DssSocket.callbackObj) {
        DssSocket.callbackObj[id](text, pages);
      }
    });
  };

  waitAnswer = (callbackId, callback) => {
    DssSocket.callbackObj[callbackId] = callback;
  };

  askQuestion = async (message) => {
    return new Promise((resolve) => {
      if (!DssSocket.connected) {
        console.error("socket initialization failed");
        resolve();
      }
      try {
        setTimeout(() => {
          DssSocket.socket.emit(
            SOCKET_DSS_MESSAGE_TYPE,
            JSON.stringify({
              message,
              lang: window.localStorage.getItem("comanage_dss_lang") || "eng",
            })
          );
        }, 50);
      } catch (err) {
        console.error(err);
        resolve(false);
      }
    });
  };

  disconnect = () => {
    // DssSocket.socket.off("connect");
    // DssSocket.socket.off("disconnect");
    // DssSocket.socket.off("dss_question");
  };
}
