import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const ChatStep = styled.div`
  width: 60vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 80vw;
  }
  /* border: dotted 1px red; */
  display: flex;
  flex-direction: row;
  justify-content: ${({ left }) => (left ? "flex-start" : "flex-end")};
  position: relative;
`;
export const ChatChunk = styled.div`
  overflow: hidden;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: ${({ loading }) => (loading ? "0" : "2")}vw;
  padding-bottom: ${({ loading }) => (loading ? "0" : "2")}vw;
  max-height: ${({ contentHeight }) => contentHeight}px;
  transition: max-height 2s, padding-top 2s, padding-bottom 2s;
  background: linear-gradient(
    0.25turn,
    var(--comanage-green-2),
    var(--comanage-green-1)
  );
  width: 30vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 80vw;
  }
  /* backdrop-filter: blur(15px) hue-rotate(362deg) brightness(1.1); */
  /* background-image: radial-gradient(rgb(238 0 248 / 29%), rgb(219 0 255 / 45%)); */

  /* ${({ last }) =>
    last
      ? css`
          box-shadow: rgba(130, 0, 222, 0.33) 2px 1px 3px,
            rgba(200, 119, 255, 0.4) 1px 0px 5px,
            rgba(93, 10, 162, 0.69) 4px -7px 60px,
            rgba(142, 69, 176, 0.46) -20px -20px 60px;
          border: solid 0.1px rgb(189 120 0);
        `
      : css`
          box-shadow: rgba(130, 0, 222, 0.33) 2px 1px 3px,
            rgba(200, 119, 255, 0.4) 1px 0px 5px,
            rgb(93 10 162 / 69%) 20px 20px 60px,
            rgba(142, 69, 176, 0.46) -20px -20px 60px;
        `} */
  transition: all 2s;
  border-radius: ${({ left }) =>
    left ? "20px 20px 20px 0px" : "20px 20px 0px 20px"};
  /* border-radius: 20px 0px; */
  text-align: ${({ left }) => (left ? "left" : "right")};
  /* padding: 2vw; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const ContentBody = styled.p`
  padding: 0px;
  margin: 0px;
  /* color: white; */
  color: var(--comanage-purple);
  font-size: 14pt;
  font-weight: 300;
  text-align: left;
`;

export const DownloadButton = styled.div`
  color: white;
  cursor: pointer;
  text-decoration: underline;
  color: white;
`;

export const RedirectLink = styled.a`
all: unset;
`