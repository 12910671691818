import React from "react";
import { COMANAGE_LOCAL_BGI_MG, COMANAGE_LOCAL_LOGO_IMG } from "../../config";

import { Wrapper, LogoImg, BgImage } from "./style";

export const FetchErrorPage = () => {
  return (
    <Wrapper>
      <BgImage src={COMANAGE_LOCAL_BGI_MG} />
      <LogoImg src={COMANAGE_LOCAL_LOGO_IMG} />
      <h1>
        Something went wrong
        <br />
      </h1>
      <h2>please reload the page</h2>
    </Wrapper>
  );
};
