import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const CardWrapper = styled.div`
  color: white;
  width: 380px;
  /* min-height: 363px;
  max-height: 363px; */
  /* border: 0.1px solid white; */
  /* background: linear-gradient(0.25turn, #8dc63f, #45b97c); */
  border-radius: 20px 0px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  * {
    margin: 0px;
  }
  margin: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: linear-gradient(
    0.25turn,
    var(--comanage-green-2),
    var(--comanage-green-1)
  );
  /* backdrop-filter: blur(15px) hue-rotate(362deg) brightness(1.1);
  background-image: radial-gradient(
    rgba(238, 0, 248, 0.47),
    rgb(219 0 255 / 36%)
  );
  box-shadow: rgba(130, 0, 222, 0.33) 2px 1px 3px,
    rgba(200, 119, 255, 0.4) 1px 0px 5px, rgb(93 10 162 / 69%) 20px 20px 60px,
    rgba(142, 69, 176, 0.46) -20px -20px 60px; */
  /* box-shadow: rgb(127, 112, 162) 1px 3px 20px 0px, rgb(123 134 209) 1px 0px 12px,
    rgba(0, 51, 28, 0.69) 12px 13px 20px,
    rgba(69, 176, 121, 0.46) -13px -13px 20px; */

  padding-bottom: 40px;
  position: relative;
  left: 0px;
  :hover {
    left: 30px;
  }
  /* border: ${({ last }) =>
    last
      ? css`
         solid 0.1px rgb(189 120 0)
        `
      : css`
          solid 0.1px transparent
        `}; */
  transition: border 1s, left 0.5s;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    transform: scale(0.8)
  }
`;
export const CardTitle = styled.h4`
  display: flex;
  flex-direction: row;
  margin-top: 19px;
  align-items: center;
  justify-content: space-between;
  /* max-width: 300px; */
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font: normal normal bold 14px/23px bicyclette;
  width: 90%;
  color: var(--comanage-purple);
`;
export const CardBody = styled.div`
  width: 299px;
  text-align: center;
  font-size: 13px;
  line-height: 15px;

  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90%;
  }
`;
export const CardButton = styled.a`
  all: unset;
  /* margin-bottom: 40px; */
  margin-left: 10px;
  font: normal normal normal 15px/18px bicyclette;
  border: 1px solid white;
  border-radius: 15px 3px;
  width: fit-content;
  padding: 0px 10px;
  min-height: 30px;
  max-height: 30px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
  color: white;

  /* transition: background 1s, opacity 0.5s; */
  /* opacity: ${({ isHover }) => (isHover ? "1" : "0.0")}; */
  /* :hover {
    background-color: rgba(255, 255, 255, 0.3);
  } */
`;
