import styled, { css } from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";
export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    width: var(--max-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Title = styled.h2`
  color: var(--comanage-purple);
  margin: 30px;
  margin-bottom: 50px;
  ${({ firstTitle }) =>
    firstTitle
      ? css`
          margin-top: 115px;
        `
      : ""}
`;

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90vw;
  margin-left: 30px;
  gap: 30px;
`;

export const Row = styled.div`
  width: 90vw;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 5vw;
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    width: var(--max-width);
  }
`;

export const EventsWrapper = styled.div`
  width: 90vw;
  margin-left: 30px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 0px;
  }
`;

export const FirstCard = {
  Wrapper: styled.div`
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
    height: 367px;
    margin-bottom: 100px;
  `,
  Img: styled.div`
    width: 1255px;
    height: 367px;
    background: url(${({ img }) => img});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 100px;
  `,
  ContentWrapper: styled.div`
    padding-left: 50px;
    padding-right: 20px;
    width: 441px;
    height: 226px;
    background-color: rgba(255, 255, 255, 0.8);
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    border-radius: 0px 10px 0px 0px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  `,
  Date: styled.h5`
    font: normal normal bold 20px/23px bicyclette;
    margin: 0px;
  `,
  Title: styled.h3`
    font: normal normal bold 24px/28px bicyclette;
    letter-spacing: 0px;
  `,
  Content: styled(LinesEllipsis)``,
};
