import React from "react";
import { Section1Styled as S1, Title } from "../style";

export const Links = ({
  currentSection,
  isMobile,
  title = "",
  onCardClick = () => {},
  tabs = [
    {
      title: "",
      content: "",
      url: "",
      illustration: null,
      buttonText: "",
    },
  ],
}) => {
  return (
    <S1.Wrapper>
      <Title style={{ color: "white" }}>{title}</Title>
      <S1.Row {...{ isMobile }}>
        {tabs.map(
          ({ title, content, url, illustration, id, buttonText }, key) => (
            <S1.Content.Wrapper {...{ key }}>
              <S1.Content.Title>{title}</S1.Content.Title>
              {illustration ? (
                <S1.Content.Illustration src={illustration.url} />
              ) : (
                <S1.Content.Body
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
              <S1.Content.Button
                style={{ visibility: buttonText !== ' ' ? "visible" : "collapse" }}
                //  href={url}
                onClick={(e) => {
                  if (currentSection !== id)
                    onCardClick(e, title, content, url, illustration, id);
                }}
              >
                {buttonText}
              </S1.Content.Button>
            </S1.Content.Wrapper>
          )
        )}
      </S1.Row>
    </S1.Wrapper>
  );
};
