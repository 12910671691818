import { useLoaderData } from "react-router-dom";
import {
  Wrapper,
  MainSection,
  PartnersSection,
  PilotssSection,
  Column,
  Title,
  Line,
} from "./style";

import { Header } from "../../components/Header/Header";
import { useEffect } from "react";
import { EventsNewsSection } from "../../components/EventsNewsSection/EventsNewsSection";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { getLatestEvent } from "../../utils/getLatestEvent";

export const AboutPage = () => {
  const { page, postList: postListRaw = [] } = useLoaderData();
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const {
    main_title,
    header_bg_image,
    header_logo,
    pilots,
    pilots_sm,
    first_section_content,
    "partners _first_column": partners_first_column,
    "partners _second_column": partners_second_column,
  } = page[0].acf;

  useEffect(() => {
    if (window.location.search) {
      const id = window.location.search.match(/\w+$/gm)[0];
      const target = document.getElementById(id);
      target.scrollIntoView();
      // target.scrollIntoView({ behavior: "smooth" });
    }
  }, [window.location.search]);

  const postList = postListRaw.map(({ acf, id, date_gmt: date }) => ({
    ...acf,
    id,
    date,
  }));
  const event = getLatestEvent(postList);
  const news = postList
    .filter(({ type }) => type === "news")
    .sort(({ date: A, date: B }) =>
      new Date(A).getTime() > new Date(B).getTime() ? 1 : -1
    )
    .filter((_, i) => i <= 1);

  return (
    <>
      <Header
        title={main_title}
        subTile="About"
        content=""
        // imageBottomLeft={header_image_bottom_left?.url}
        // imageTopRight={header_image_top_right?.url}
        background={header_bg_image?.url}
        logo={header_logo.url}
        clearBg={true}
        smallHeader={isMobile}
      />
      <Wrapper>
        {first_section_content && (
          <MainSection
            dangerouslySetInnerHTML={{ __html: first_section_content }}
          />
        )}
        <Title id="partners" style={isMobile ? { marginBottom: "0px" } : {}}>
          Partners
        </Title>
        <PartnersSection>
          {partners_first_column && (
            <Column
              dangerouslySetInnerHTML={{ __html: partners_first_column }}
            />
          )}
          {partners_second_column && (
            <Column
              style={isMobile ? { marginTop: "-90px" } : {}}
              dangerouslySetInnerHTML={{ __html: partners_second_column }}
            />
          )}
        </PartnersSection>
        <Line />
        {pilots && (
          <>
            <Title id="partners">Pilots</Title>
            <PilotssSection
              dangerouslySetInnerHTML={{
                __html: isMobile ? pilots_sm : pilots,
              }}
            />
          </>
        )}
      </Wrapper>
      <EventsNewsSection {...{ event, news }} />
    </>
  );
};
