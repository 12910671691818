import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorWarning,
  StyledFormInput,
  Wrapper,
  Button,
  Row,
  SuccessWarning,
  Link,
} from "./style";
import jsonp from "jsonp";
import { subscribe } from "../../calls/post/subscribe";
import { ROUTES } from "../../config/menuRoutes";
export const SingUpForm = ({ name, width, style = {}, styleType }) => {
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const onClick = async () => {
    setErrorText("");
    const privacyOk = document.getElementById("subscribe_radio").checked;
    if (privacyOk) {
      const fetchedResult = await subscribe({
        EMAIL: email,
        MMERGE3: privacyOk,
      });
      if (fetchedResult.result === "error") {
        setErrorText(fetchedResult.msg);
      }
      if (fetchedResult.result === "success") {
        setSuccessText(fetchedResult.msg);
      }
    } else {
      setErrorText("you must accept privacy policy");
    }
  };
  const inputProps = {
    type: "text",
    name,
    placeholder: "Email address",
    error: !!errorText,
    width,
    styleType,
  };
  const onPrivacyLinkClick = () =>
    (window.location.href = ROUTES.COMING_SOON_PRIVACY);
  return (
    <Wrapper {...{ style }}>
      <Row>
        <StyledFormInput
          onChange={(e) => setEmail(e.target.value)}
          {...inputProps}
        />
        <Button {...{ onClick }}>Sign Up</Button>
      </Row>
      <Row
        style={{
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <input id="subscribe_radio" type={"checkbox"} />
        Yes, I agree to the COMANAGE
        <Link onClick={onPrivacyLinkClick}>privacy policy.</Link>
      </Row>

      {!!errorText && (
        <Row>
          <ErrorWarning>{errorText}</ErrorWarning>
        </Row>
      )}
      {!!successText && (
        <Row>
          <SuccessWarning>{successText}</SuccessWarning>
        </Row>
      )}
    </Wrapper>
  );
};

SingUpForm.propTypes = {
  textarea: PropTypes.bool,
  styleType: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.any,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  width: PropTypes.string,
};

export const detProps = {
  textarea: false,
  styleType: "a",
  name: "LNAME",
  placeholder: "placeholder",
  errorText: "",
  width: "200px",
};

SingUpForm.defaultProps = detProps;
