import styled from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Line = styled.div`
  width: 95%;
  height: 1px;
  background: var(--comanage-green-3);
  margin: 40px auto 48px;
`;

export const Title = styled.h2`
  color: var(--comanage-purple);
  margin: 30px;
  margin-top: 100px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 1000px;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    width: var(--max-width);
    position: relative;
    left: calc(50% - (var(--max-width) / 2));
  }
`;

export const MainSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  table {
    width: 100%;
    border-spacing: 0px;
  }

  p,
  h1,
  ul {
    margin: 50px;
  }
  img {
    width: 50vw;
    height: auto;
  }
  h1 {
    color: var(--comanage-purple);
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    tbody {
      display: flex;
      flex-direction: column;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
    }
    tr:nth-child(2) {
      flex-direction: column-reverse;
    }
    img {
      width: 90vw;
      margin-left: 5vw;
    }
    p {
      margin-left: 5vw;
      margin-right: 5vw;
    }
    ul {
      margin-left: 5vw;
      margin-right: 5vw;
    }
    h1 {
      margin-left: 5%;
      margin-top: 100px;
    }
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
    position: relative;
    table {
      max-width: var(--max-width);
    }
    img {
      width: calc(var(--max-width) / 2);
    }
  }
`;
export const PartnersSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
  font-size: 16px;
  line-height: 21px;
  flex-wrap: wrap;
  table {
    border-spacing: 10px 50px;
  }
  img {
    width: 134px;
    height: auto;
  }
  p {
    width: 359px;
  }
  img {
    margin-right: 30px;
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 0px;
    p {
      width: 80vw;
    }
    tbody {
      display: flex;
      flex-direction: column;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
    img {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 50vw;
    }
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    table {
      border-spacing: 10px 20px;
    }
  }
`;
export const Column = styled.div`
  margin: 0vw 5vw;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    margin: 0vw 0vw;
  }
`;
export const PilotssSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  table {
    width: 90%;
  }
  img {
    height: fit-content;
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    td {
      width: 90vw;
    }
    tbody {
      display: flex;
      flex-direction: column;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    img {
      position: relative;
      margin-left: 50vw;
      margin-top: 30px;
      width: 50vw;
      height: auto;
      transform: translateX(-30vw);
    }
    p {
      width: 80vw;
    }
  }
`;
