import LinesEllipsis from "react-lines-ellipsis";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  width: 310px;
  height: 367px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
export const Img = styled.img`
  width: 310px;
  height: 174px;
  border-top-right-radius: 10px;
`;

export const Title = styled.h2`
  text-align: left;
  ${({ withOpenSans }) => withOpenSans ? css`
  font-family: opensans;
  ` : css`
  font: normal normal bold 24px/28px bicyclette;
  `}
  letter-spacing: 0px;
  color: black;
  margin-top: 20px;
  width: 279px;
  max-height: 78px;
`;
export const Date = styled.h5`
  position: absolute;
  bottom: 0%;
  width: 170px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  background-color: var(--comanage-green-2);
  /* font: normal normal 400 16px/19px bicyclette; */
  ${({ withOpenSans }) => withOpenSans ? css`
  font-family: opensans;
  ` : css`
  font: normal normal bold 24px/28px bicyclette;
  `}
  letter-spacing: 1px;
  letter-spacing: 0px;
  margin: 0px;
`;
export const Content = styled(LinesEllipsis)`
  margin-top: 15px;
  font-weight: 200;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 279px;
  text-align: left;
  /* font: normal normal 300 16px/19px bicyclette; */
  ${({ withOpenSans }) => withOpenSans ? css`
  font-family: opensans;
  ` : css`
  font: normal normal bold 24px/28px bicyclette;
  `}
  height: 55px;
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;
