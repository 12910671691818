import { Link, useLoaderData } from "react-router-dom";
import {
  Wrapper,
  TopGradient,
  Title,
  Row,
  MainContent,
  Column,
  SubTitle,
  Separator,
  MainContentWrapper,
  NewsWrapper,
} from "./style";
import { Header } from "./../../components/Header/Header";

import { DateTime } from "luxon";
import { FormButton } from "../../components/FormButton/FormButton";
import { ROUTES } from "../../config/menuRoutes";
import { NewsCard } from "./components/NewsCard";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { useEffect } from "react";
import { SocialLogos } from "./components/SocialLogos";
import { checkIfFutureDate } from "../../utils/checkIfFutureDate";

export const PostPage = () => {
  const {
    postDetail: [{ acf: postData, date, ...rawData }],
    pageData: [
      {
        acf: { header_logo },
      },
    ],
    params: { id },
    postList,
  } = useLoaderData();
  const {
    title,
    maincontent,
    type,
    social_img,
    social_text,
    header_img: { url: background },
    event_date,
    event_url,
  } = postData;
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;

  const list = postList.map(({ acf, id, date }) => ({ ...acf, id, date }));
  const news = list
    .filter(({ type }) => type === "news")
    .sort(({ date: A }, { date: B }) =>
      new Date(A).getTime() > new Date(B).getTime() ? -1 : 1
    )
    .filter((_, i) => i <= 1);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  let isFutureDate = false;
  if (type === "event") isFutureDate = checkIfFutureDate(event_date);
  return (
    <>
      <Header
        {...{
          title: "",
          logo: header_logo?.url,
          background,
          imageBottomLeft: null,
          imageTopRight: null,
          subTile: "",
          content: "",
          // clearBg: true,
          smallHeader: true,
          filterColor: "var(--comanage-green)",
        }}
      />
      <TopGradient />
      <Wrapper>
        <Title>{title}</Title>
        <MainContentWrapper>
          <Column>
            {type === "event" && (
              <>
                <Separator />
                <SubTitle>DATE</SubTitle>
                <Row style={{ fontWeight: 700 }}>
                  {DateTime.fromFormat(
                    event_date,
                    "dd/MM/yyyy h:mm a"
                  ).toFormat("dd MMMM yyyy")}
                </Row>
              </>
            )}
            <Separator />
            <SubTitle>SHARE THIS ARTICLE</SubTitle>
            <Row>
              <SocialLogos {...{ id }} />
            </Row>
            {type === "event" && isFutureDate && (
              <>
                <Separator />
                <SubTitle></SubTitle>
                <Row>
                  <FormButton
                    styleType={"b"}
                    name="REGISTER"
                    value="REGISTER"
                    onClick={() => window.open(event_url, "_blank")}
                    style={{
                      backgroundColor: "var(--comanage-green-2)",
                      width: "100%",
                      letterSpacing: "0px",
                    }}
                    onHoverStyle={{
                      backgroundColor: "var(--comanage-green-1)",
                      borderColor: "var(--comanage-green-1)",
                    }}
                  />
                </Row>
              </>
            )}
          </Column>
          <MainContent
            style={{ width: "80vw" }}
            dangerouslySetInnerHTML={{ __html: maincontent }}
          />
        </MainContentWrapper>
        {news.length ? (
          <>
            <Title>You might be interested in…</Title>
            <NewsWrapper>
              {news.map(
                (
                  {
                    title,
                    date,
                    preview_text: content,
                    header_img: { url: img },
                    id,
                  },
                  key
                ) => (
                  <Link
                    {...{ key }}
                    to={`/post/${id}`}
                    style={{ all: "unset", cursor: "pointer" }}
                  >
                    <NewsCard
                      {...{
                        title,
                        date: new DateTime(date).toFormat("dd MMMM yyyy"),
                        content,
                        img,
                      }}
                    />
                  </Link>
                )
              )}
            </NewsWrapper>
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </>
  );
};
