import { comingSoonRoute } from "./pages/ComingSoon/route";
import { appTemplate } from "./pages/AppTemplate/route";
import { homePageRoute } from "./pages/HomePage/route";
import { COMING_SOON_ACTIVE } from "./config";
import { privacyPolicyRoute } from "./pages/PrivacyPolicy/route";
import { aboutPageRoute } from "./pages/About/route";
import { partnersPageRoute } from "./pages/Partners/route";
import { newsEventsPageRoute } from "./pages/NewsEvents/route";
import { copPostPageRoute, postPageRoute } from "./pages/Post/route";
import { pilotRoute } from "./pages/Pilot/route";
import { libraryRoute } from "./pages/Library/route";
import { pastEventsPageRoute } from "./pages/PastEvents/route";
import { dssPageRoute } from "./pages/dss/route";
import { newsletterPageRoute } from "./pages/NewsLetter/route";
import { COPPageRoute } from "./pages/COPPage/route";
import { redirectPageRoute } from "./pages/RedirectPage/route";
import { COPCategoryPageRoute } from "./pages/COPCategory/route";

const partialRoutes = [comingSoonRoute, privacyPolicyRoute];

const fullSiteRoutes = [
  appTemplate({
    subRoutes: [
      homePageRoute,
      aboutPageRoute,
      partnersPageRoute,
      newsEventsPageRoute,
      postPageRoute,
      pilotRoute,
      libraryRoute,
      pastEventsPageRoute,
      privacyPolicyRoute,
      newsletterPageRoute,
      COPPageRoute,
      COPCategoryPageRoute,
      redirectPageRoute,
      copPostPageRoute,
    ],
  }),
  // dssPageRoute,
];

export const routes = COMING_SOON_ACTIVE ? partialRoutes : fullSiteRoutes;
