import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;
export const Img = styled.img`
  width: 547px;
  height: 406px;
  margin-left: 103px;
`;
export const ImgContainer = styled.div`
  width: 547px;
  height: 406px;
  margin-left: 103px;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-position: center;
  background-size: cover;
`;
export const InfoWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 10px;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  left: 0%;
  width: 441px;
  height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 70px;
`;
export const Title = styled.h2`
  text-align: left;
  font: normal normal bold 24px/28px bicyclette;
  letter-spacing: 0px;
  color: black;
  margin: 0px;
`;
export const Date = styled.h5`
  text-align: left;
  font: normal normal 600 16px/19px bicyclette;
  letter-spacing: 0px;
  margin-top: ${({ withSubTitle }) => (withSubTitle ? "10px" : "30px")};
  margin-bottom: 10px;
`;
export const Content = styled(LinesEllipsis)`
  margin-top: 15px;
  font-weight: 200;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 388px;
  text-align: left;
  font: normal normal 300 16px/19px bicyclette;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const SubTitle = styled.h5`
  margin: 0px;
  margin-top: 10px;
  font-weight: 300;
`;
