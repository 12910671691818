import { Link, useLoaderData } from "react-router-dom";
import { Wrapper, EventsWrapper, Row } from "./style";
import { Header } from "../../components/Header/Header";
import { useEffect, useState } from "react";
import { ROUTES } from "../../config/menuRoutes";
import { CardM } from "../../components/CardM/CardM";
import { FormButton } from "../../components/FormButton/FormButton";
import { getTimestampFromStr } from "../../utils/getTimestampFromStr";
import { checkIfFutureDate } from "../../utils/checkIfFutureDate";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { DateTime } from "luxon";

const eventsStep = 10;

export const PastEventsPage = () => {
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const [displayedEventsLength, setDisplayedEventsLength] =
    useState(eventsStep);
  const {
    pageData: [
      {
        acf: {
          header_logo: { url: logo },
          header_bg_image,
          header_title: title,
        },
      },
    ],
    list: listRaw,
  } = useLoaderData();

  const list = listRaw.map(({ acf, id }) => ({ ...acf, id }));

  const events = list
    .filter(({ type, event_date }) => {
      const isFutureDate = checkIfFutureDate(event_date);
      return type === "event" ? !isFutureDate : false;
    })
    .sort(({ event_date: A }, { event_date: B }) =>
      getTimestampFromStr(A) > getTimestampFromStr(B) ? -1 : 1
    );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  
  return (
    <Wrapper>
      <Header
        {...{
          logo,
          title,
          content: "",
          subTile: "",
          background: header_bg_image?.url,
          smallHeader: isMobile,
        }}
      />
      <EventsWrapper>
        <Row
          style={{
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "flex-start",
            gap: "10px",
            width: "fit-content",
          }}
        >
          {events
            .filter((_, i) => i < displayedEventsLength)
            .map(
              (
                {
                  title,
                  event_date: date,
                  preview_text: content,
                  header_img: { url: img },
                  id,
                },
                key
              ) => (
                <Link
                  {...{ key }}
                  to={`/post/${id}`}
                  style={{ all: "unset", cursor: "pointer" }}
                >
                  <CardM
                    {...{
                      key,
                      title,
                      date: DateTime.fromFormat(
                        date,
                        "dd/MM/yyyy h:mm a"
                      ).toFormat("dd MMMM yyyy"),
                      content,
                      img,
                    }}
                  />
                </Link>
              )
            )}
        </Row>
      </EventsWrapper>
      {displayedEventsLength < events.length && (
        <Row
          style={{
            justifyContent: "center",
            marginTop: "5%",
            marginBottom: isMobile ? "40px" : "100px",
          }}
        >
          <FormButton
            {...{
              name: "SHOW MORE",
              value: "SHOW MORE",
              onClick: () =>
                setDisplayedEventsLength(displayedEventsLength + eventsStep),
            }}
          />
        </Row>
      )}
    </Wrapper>
  );
};
