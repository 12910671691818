import React from "react";
import { COMING_SOON_FORM_DATA } from "../../../config";
import { FormButton } from "../../FormButton/FormButton";
import {
  ArrowImg,
  FormTitle,
  InfoContent,
  InfoImage,
  Infosection,
  Row,
  SocialImag,
  SocialLink,
  SocialMadiaTitle,
  SocialMediaLinks,
  StyledForm,
} from "../style";

export const MobileTemplate = ({
  socialNetworksDatas,
  subscribe_form_title,
  footer_content,
  onFormSubmit,
  footer_info_image,
  onPrivacyLinkClick,
  mobile_arrow_img,
  children,
  MessageComponent,
}) => {
  return (
    <>
      <Infosection>
        <SocialMediaLinks>
          <SocialMadiaTitle>Follow us</SocialMadiaTitle>
          <Row style={{ justifyContent: "center", gap: "10px" }}>
            {socialNetworksDatas.map(([{ url: src }, href], key) => (
              <SocialLink {...{ key, href }}>
                <SocialImag {...{ src }} />
              </SocialLink>
            ))}
          </Row>
        </SocialMediaLinks>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit(e);
          }}
        >
          <Row
            style={{
              flexWrap: "nowrap",
              margin: "5px",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <ArrowImg src={mobile_arrow_img.url} />
            <FormTitle>{subscribe_form_title}</FormTitle>
          </Row>
          {children}
          <FormButton
            value={"SIGN UP"}
            name={COMING_SOON_FORM_DATA.subscribe}
            style={{ marginBottom: "30px", marginTop: "10px" }}
          />
          <MessageComponent />
        </StyledForm>
        <InfoImage src={footer_info_image.url} style={{ marginLeft: "10px" }} />
        <InfoContent
          dangerouslySetInnerHTML={{ __html: footer_content }}
          style={{ marginLeft: "10px" }}
        />
      </Infosection>
    </>
  );
};
