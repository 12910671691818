import React from "react";
import PropTypes from "prop-types";

import { ListContent, ListDot, Wrapper } from "./style";
import { Logo, Title, List, ListElement } from "./style";

export const PARAGRAPH_LIST_BORDERS = {
  GREEN: "var(--comanage-green-3)",
  PURPLE: "var(--comanage-purple)",
};

export const ParagraphList = ({ border, content = "", logo, title }) => {
  return (
    <Wrapper {...{ border }}>
      {!!logo && <Logo src={logo} {...{ border }} />}
      <Title>{title}</Title>
      <List>
        {content.map((c, key) => (
          <ListElement {...{ key }}>
            <ListDot />
            <ListContent>{c}</ListContent>
          </ListElement>
        ))}
      </List>
    </Wrapper>
  );
};

ParagraphList.propTypes = {
  title: PropTypes.string,
  border: PropTypes.string,
  content: PropTypes.array,
  logo: PropTypes.string,
};

ParagraphList.defaultProps = {
  title: "title",
  border: PARAGRAPH_LIST_BORDERS.GREEN,
  content: [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
  ],
  logo: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
};
