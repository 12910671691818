import React from "react";
import PropTypes from "prop-types";

import {
  LogoCircle,
  LogoWrapper,
  Wrapper,
  Title,
  SubTitle,
  ContentWrapper,
} from "./style";

export const InfoPoint = ({ content = "" }) => {
  const [title = "", ...subtitle] = content.split(" ");
  return (
    <Wrapper>
      <LogoWrapper>
        <LogoCircle size={"80%"} />
        <LogoCircle size={"60%"} />
        <LogoCircle size={"40%"} fillBg />
      </LogoWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        {subtitle && <SubTitle>{subtitle.join(" ")}</SubTitle>}
      </ContentWrapper>
    </Wrapper>
  );
};

InfoPoint.propTypes = {
  content: PropTypes.string,
};

InfoPoint.defaultProps = {
  content: "3.2 Million people",
};
