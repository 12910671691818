import styled, { css } from "styled-components";

export const InfoContentWrapper = styled.div`
  position: relative;
  /* min-height: 40vh;
  max-height: 40vh; */
  /* border: solid 1px pink; */
  padding-left: 2vw;
  padding-top: 0vh;
  width: 100%;
  img {
    width: 500px;
  }
`;

export const InfoParagraph = styled.div`
  max-width: 550px;
  color: white;
  font-size: 15pt;
  font-weight: 300;
  line-height: 0.9em;
  p {
    column-count: 2;
    text-align: justify;
    margin: 0px;
  }
  h5 {
    font-size: 16pt;
    margin: 10px;
  }
  i {
    font-size: 12pt;
    font-weight: 100;
    font-style: italic;
  }
  strong {
    font-size: 14pt;
    font-weight: 600;
  }
`;

export const LangWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  transform: translateY(-50%);
`;
export const LangIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-position: center;
  background-size: 150%;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
  transition: transform 500ms;
`;
