import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  width: 310;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px var(--comanage-green-3);
  gap: 10px;
  border-radius: 0px 10px 0px 0px;

  height: 367px;
`;
export const Img = styled.img`
  width: 310px;
  height: 174px;
  border-radius: 0px 10px 0px 0px;
`;

export const Title = styled.h2`
  text-align: left;
  font: normal normal bold 24px/28px bicyclette;
  letter-spacing: 0px;
  color: black;
  margin-top: 20px;
  width: 279px;
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Content = styled(LinesEllipsis)`
  margin-top: 15px;
  font-weight: 200;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 279px;
  text-align: left;
  font: normal normal 300 16px/19px bicyclette;
  height: 55px;
`;
