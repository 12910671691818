import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  width: 650px;
  position: relative;
  border-radius: 0px 10px;
  border: 1px solid ${({ border }) => (!!border ? border : "rgba(0,0,0,0)")};
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0px;
  left: 30px;
  transform: translate(-50%, -50%);
  border: solid 1px ${({ border }) => (!!border ? border : "lightgray")};
`;
export const Title = styled.h4`
  font-family: "bicyclette" !important;
  font-size: 20px;
  position: absolute;
  transform: translateY(-50%);
  top: 0px;
  left: 60px;
  margin: 0px;
  background-color: white;
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ListElement = styled.span`
  display: flex;
  justify-content: center;
`;
export const ListDot = styled.div`
  border-radius: 50%;
  width: 7px;
  max-height: 7px;
  min-height: 7px;
  background-color: black;
  margin-right: 10px;
  transform: translateY(50%);
`;
export const ListContent = styled.div`
  width: 90%;
`;
