import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-bottom: 40px;
  }
`;
export const LogoWrapper = styled.div`
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  position: relative;
`;
export const LogoCircle = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  border-radius: 50%;
  border: solid 1px var(--comanage-green-3);
  ${({ fillBg }) =>
    fillBg
      ? css`
          background-color: var(--comanage-green-3);
        `
      : ""}
`;

export const Title = styled.div`
  font: normal normal bold 40px/24px bicyclette;
`;
export const SubTitle = styled.div`
  font: normal normal bold 20px/24px bicyclette;
`;
export const ContentWrapper = styled.div`
  display: flex;
  color: var(--comanage-blue-2);
  flex-direction: column;
  gap: 10px;
`;
