import styled from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 1000px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100vw;
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    width: var(--max-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TopGradient = styled.div`
  width: 100%;
  min-height: 8px;
  max-height: 8px;
  background: linear-gradient(
    0.25turn,
    var(--comanage-green-1),
    var(--comanage-purple)
  );
`;

export const Title = styled.h1`
  color: var(--comanage-purple);
  margin-left: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
  font: normal normal bold 50px/56px bicyclette;
  text-transform: capitalize;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 5%;
    font-size: 30px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const NewsWrapper = styled(Row)`
  justify-content: flex-start;
  margin-left: 30px;
  margin-bottom: 100px;
  gap: 30px;
  flex-wrap: wrap;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: 5%;
  }
`;

export const MainContentWrapper = styled(Row)`
  margin-top: 0px;
  gap: 10%;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    flex-wrap: wrap;
  }
  width: 96vw;
  margin-left: 2vw;
  margin-right: 2vw;
  flex-wrap: nowrap;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    gap: 3%;
    margin-left: 0vw;
    margin-left: 0vw;
    justify-content: space-between;
    width: var(--max-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const MainContent = styled.div`
  position: relative;
  img {
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 40px;
    img {
      margin-top: 0px;
      width: 100vw;
      margin-left: -5vw;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: 800px;
  }
`;
export const DetailSection = styled.div``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100vw;
  }
`;

export const SubTitle = styled.h5`
  font-weight: 400;
  color: var(--comanage-blue-1);
`;

export const Separator = styled.div`
  width: 100%;
  min-height: 2px;
  background-color: var(--comanage-green-3);
  margin-top: 30px;
`;

export const NewsCardStyle = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  `,
  Img: styled.div`
    width: 293px;
    height: 159px;
    background: url(${({ img }) => img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  ContentWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  `,
  Title: styled.h3`
    width: 290px;
    font: normal normal bold 24px/28px bicyclette;
    margin-bottom: 13px;
    margin-top: 0px;
  `,
  Date: styled.div``,
};
