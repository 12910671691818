import React from "react";
import { FirstCard as Styled } from "../style";

const { Wrapper, Img, ContentWrapper, Date, Title, Content } = Styled;
export const FirstCard = ({
  header_img: { url: img },
  date,
  title,
  preview_text,
}) => {
  return (
    <Wrapper>
      <Img {...{ img }} />
      <ContentWrapper>
        <Date>{date}</Date>
        <Title>{title}</Title>
        <Content
          text={preview_text}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </ContentWrapper>
    </Wrapper>
  );
};
