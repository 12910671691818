import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  ImageBottomLeft,
  ImageTopRight,
  BackgroundImage,
  Logo,
  Title,
  SubTitle,
  Content,
  ContentWrapper,
  Filter,
} from "./style";
import { Link } from "react-router-dom";

export const Header = ({
  logo,
  title,
  subTile,
  content,
  imageBottomLeft,
  imageTopRight,
  background,
  clearBg,
  smallHeader,
  filterColor = null,
}) => {
  return (
    <Wrapper {...{ smallHeader }}>
      {logo && (
        <Link to="/" style={{ zIndex: 18 }}>
          <Logo src={logo} />
        </Link>
      )}
      {imageBottomLeft && <ImageBottomLeft src={imageBottomLeft} />}
      {imageTopRight && <ImageTopRight src={imageTopRight} />}
      {background && !clearBg && <Filter {...{ filterColor }} />}
      <BackgroundImage bg={background} />
      <ContentWrapper>
        <SubTitle>{subTile}</SubTitle>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </ContentWrapper>
    </Wrapper>
  );
};

Header.propTypes = {
  title: PropTypes.any,
  subTile: PropTypes.any,
  content: PropTypes.any,
  imageBottomLeft: PropTypes.any,
  imageTopRight: PropTypes.any,
  background: PropTypes.any,
  clearBg: PropTypes.any,
};

Header.defaultProps = {
  logo: "",
  title: "title",
  subTile: "subTile",
  content: "content",
  imageBottomLeft: "",
  imageTopRight: "",
  background: null,
  clearBg: false,
};
