import { DssPage } from ".";
import { getDssInfoSection } from "../../calls/get/dssInfoSection";
import { getDssTreeStruct } from "../../calls/get/dssTreeStruct";
import { getLanguagesData } from "../../calls/get/LanguagesData";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const dssPageRoute = {
  path: ROUTES.DSS,
  // element: () => <div>fuuuuuck</div>,
  element: <DssPage />,
  // errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    treeDssStruct: await getDssTreeStruct(),
    languages: (await getLanguagesData()).map(({ acf }) => acf),
    dssInfoSection: await getDssInfoSection(),
  }),
};
