import { COPCategoryPage } from ".";
import { getCopPageCategoriesData } from "../../calls/get/CopPageCategoriesData";
import { getCopPageContentsData } from "../../calls/get/CopPageContentsData";
import { getCopPageData } from "../../calls/get/CopPageData";
import { getLanguagesData } from "../../calls/get/LanguagesData";
import { getHomePageData } from "../../calls/get/HomePageData";
// import { getCOPPageData } from "../../calls/get/COPPageData";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
// import { getPostList } from "./../../calls/get/postList";

export const COPCategoryPageRoute = {
  path: ROUTES.COP_CATEGORY_PAGE,
  element: <COPCategoryPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    copPage: await getCopPageData(),
    categories: await getCopPageCategoriesData(),
    homepage: await getHomePageData(),
    contents: await getCopPageContentsData(),
    langs: await getLanguagesData(),
  }),
};
