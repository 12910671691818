import styled, { css } from "styled-components";
import {
  BIG_SCREEN_MEDIA_QUERY_RULE,
  SM_MEDIA_QUERY_RULE,
} from "./../../config/index";
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${({ backgroundUrl }) => backgroundUrl});
  display: flex;
  flex-direction: ${({ isMobile = false }) => (isMobile ? `column` : `row`)};
  justify-content: center;
  background-size: cover;
  align-items: center;
  position: fixed;
  z-index: 100;
`;

export const Section = styled.div`
  ${({ isMobile = false }) =>
    isMobile
      ? css`
          width: 100vw;
          height: 50vh;
        `
      : css`
          width: 100vw;
          height: 100vh;
        `}

  ${({ hovered }) =>
    hovered
      ? css`
          backdrop-filter: saturate(1);
        `
      : css`
          backdrop-filter: saturate(0);
        `}

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 300px;
  position: fixed;
  top: 10px;
  z-index: 10;
`;
