import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Logo, Title, Content, Column } from "./style";

export const PARAGRAPH_COLORS = {
  BLACK: "BLACK",
  PURPLE: "PURPLE",
  MIXED: "MIXED",
};

const colorMapper = {
  BLACK: {
    title: "black",
    content: "black",
  },
  PURPLE: {
    title: "var(--comanage-purple)",
    content: "var(--comanage-purple)",
  },
  MIXED: {
    title: "var(--comanage-purple)",
    content: "black",
  },
};

export const Paragraph = ({
  title,
  logo,
  content,
  color,
  keepMarginLogo = false,
  style = {},
}) => {
  return (
    <Wrapper {...{ style }}>
      <Column {...{ keepMarginLogo }}>{!!logo && <Logo src={logo} />}</Column>
      <Column>
        <Title color={colorMapper[color].title}>{title}</Title>
        <Content
          color={colorMapper[color].content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Column>
    </Wrapper>
  );
};

Paragraph.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
};

Paragraph.defaultProps = {
  color: PARAGRAPH_COLORS.MIXED,
  logo: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Praesent feugiat</strong>, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",

  title: "What’s already going on?",
};
