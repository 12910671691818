import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    color: var(--comanage-purple);
  }
  h2 {
    font-weight: 200;
  }
`;
export const LogoImg = styled.img`
  width: 30%;
  min-width: 300px;
`;

export const BgImage = styled.img`
position: absolute;
z-index: -1;
top: 20%;
bottom: 20%;
width: 60%;
`
