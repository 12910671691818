import React, { useEffect } from "react";
import { Map } from "../../../components/Map/Map";
import { METHODS } from "../../../config";
import { fetchData } from "../../../utils/fetchData";
import { Section3Styled as S3 } from "../style";

export const Section3 = ({
  title = "",
  content = "",
  svg,
  onCircleClick,
  isMobile,
  bg,
}) => {
  return (
    <S3.Wrapper>
      <S3.Column>
        <S3.Title>{title}</S3.Title>
        <S3.Content>{content}</S3.Content>
      </S3.Column>
      {isMobile ? (
        <>
          <S3.Background {...{ bg }} />
          <S3.MobileImg dangerouslySetInnerHTML={{ __html: svg }} />
        </>
      ) : (
        <>
          <S3.Background {...{ bg }} />
          <Map
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
            }}
            {...{ onCircleClick, svg }}
          />
        </>
      )}
    </S3.Wrapper>
  );
};
