import styled, { css } from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const LeftSection = styled.div`
  /* border: solid 1px yellow; */
  position: relative;
  width: 35vw;
  /* width: var(--window-width); */
  min-height: var(--window-height);
  max-height: var(--window-height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
    min-height: 120vh;
    align-items: flex-start;
  }
`;

export const Pagewrapper = styled.div`
  overflow: hidden;
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  --window-width: 50vw;
  --window-height: 100vh;
  @media (${SM_MEDIA_QUERY_RULE}) {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
`;

export const VerticalSlider = styled.div`
  min-height: var(--window-height);
  max-height: var(--window-height);
  overflow: hidden;
  /* box-shadow: rgba(0, 222, 122, 0.33) 2px 1px 3px,
    rgb(203 255 119 / 40%) 1px 0px 5px, rgba(0, 51, 28, 0.69) 20px 20px 60px,
    hsl(149deg 44% 48% / 46%) -20px -20px 60px; */
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 95vw;
  }
`;
export const SliderDepth = styled.div`
  min-height: var(var(--window-height) * 2);
  max-height: var(var(--window-height) * 2);
  position: relative;
  bottom: ${({ slideDown }) => (slideDown ? "var(--window-height)" : "0px")};
  transition: bottom 1s;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 95vw;
  }
`;
