import { PostPage } from ".";
import { getPostDetail } from "../../calls/get/postDetail";
import { getPostList } from "../../calls/get/postList";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
import { getNewsEventPageData } from "../../calls/get/newsEventsPage";
import { getCOPPostDetail } from "../../calls/get/copPostDetail";

export const postPageRoute = {
  path: ROUTES.POST,
  element: <PostPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    postDetail: await getPostDetail(params),
    postList: await getPostList(),
    pageData: await getNewsEventPageData(),
  }),
};

export const copPostPageRoute = {
  path: ROUTES.COP_POST,
  element: <PostPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => {
    return ({
    test: "test",
    params,
    postDetail: await getCOPPostDetail(params),
    postList: [],
    pageData: await getNewsEventPageData(),
  })},
};
