import {
  COMING_SOON_FORM_DATA,
  SUBSCRIBE_FORM_VALIDATION_ERROR,
} from "../../../config";
import { FormInput } from "../../FormInput/FormInput";
import { FormRadio } from "../../FormRadio/FormRadio";
import { Row } from "../style";

export const Fields = ({
  isMobile,
  rowInputStyle,
  formValidationResult,
  isFormPristine,
  onPrivacyLinkClick,
}) => {
  return isMobile ? (
    <>
      <FormInput
        placeholder={"First Name"}
        name={COMING_SOON_FORM_DATA.FNAME}
        width="95%"
        style={{ marginLeft: "10%" }}
        errorText={
          !isFormPristine && !formValidationResult.FNAME
            ? SUBSCRIBE_FORM_VALIDATION_ERROR
            : null
        }
      />
      <FormInput
        placeholder={"Last Name"}
        name={COMING_SOON_FORM_DATA.LNAME}
        width="95%"
        style={{ marginLeft: "10%" }}
        errorText={
          !isFormPristine && !formValidationResult.LNAME
            ? SUBSCRIBE_FORM_VALIDATION_ERROR
            : null
        }
      />
      <FormInput
        {...{
          placeholder: "Email",
          name: COMING_SOON_FORM_DATA.EMAIL,
          width: "95%",
        }}
        style={{ marginLeft: "10%" }}
        errorText={
          !isFormPristine && !formValidationResult.EMAIL
            ? SUBSCRIBE_FORM_VALIDATION_ERROR
            : null
        }
      />
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <FormRadio
          {...{
            onPrivacyLinkClick,
            name: COMING_SOON_FORM_DATA.MMERGE3,
            value: "I+agree+to+privacy+policy",
          }}
          errorText={
            !isFormPristine && !formValidationResult.MMERGE3
              ? SUBSCRIBE_FORM_VALIDATION_ERROR
              : null
          }
        />
      </Row>
    </>
  ) : (
    <>
      <Row>
        <FormInput
          placeholder={"First Name"}
          name={COMING_SOON_FORM_DATA.FNAME}
          {...rowInputStyle}
          errorText={
            !isFormPristine && !formValidationResult.FNAME
              ? SUBSCRIBE_FORM_VALIDATION_ERROR
              : null
          }
        />
        <FormInput
          placeholder={"Last Name"}
          name={COMING_SOON_FORM_DATA.LNAME}
          {...rowInputStyle}
          errorText={
            !isFormPristine && !formValidationResult.LNAME
              ? SUBSCRIBE_FORM_VALIDATION_ERROR
              : null
          }
        />
      </Row>
      <FormInput
        {...{
          placeholder: "Email",
          name: COMING_SOON_FORM_DATA.EMAIL,
          width: "100%",
        }}
        errorText={
          !isFormPristine && !formValidationResult.EMAIL
            ? SUBSCRIBE_FORM_VALIDATION_ERROR
            : null
        }
      />
      <Row style={{ justifyContent: "flex-start", alignItems: "center" }}>
        <FormRadio
          {...{
            onPrivacyLinkClick,
            name: COMING_SOON_FORM_DATA.MMERGE3,
            value: "I+agree+to+privacy+policy",
          }}
          errorText={
            !isFormPristine && !formValidationResult.MMERGE3
              ? SUBSCRIBE_FORM_VALIDATION_ERROR
              : null
          }
        />
      </Row>
    </>
  );
};
