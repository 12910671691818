import { useLoaderData } from "react-router-dom";
import { Wrapper, Background } from "./style";

import { Header } from "../../components/Header/Header";
import { SubscribeToNewsLetterSection } from "../../components/EventsNewsSection/EventsNewsSection";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";

export const NewsletterPage = () => {
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const {
    pageData: [
      {
        acf: {
          header_logo: { url: logo },
          header_bg_image: { url: header_bg_image },
          newsletter_header_title: title = "Newsletter Subscription",
        },
      },
    ],
  } = useLoaderData();

  return (
    <>
      <Header
        {...{
          logo,
          title,
          content: "",
          background: header_bg_image,
          subTile: "",
          clearBg: true,
          smallHeader: isMobile,
        }}
      />
      <Background>
        <Wrapper>
          <SubscribeToNewsLetterSection />
        </Wrapper>
      </Background>
    </>
  );
};
