import React, { Children } from "react";
import { COMING_SOON_FORM_DATA } from "../../../config";
import { FormButton } from "../../FormButton/FormButton";
import { FormInput } from "../../FormInput/FormInput";
import {
  FormSection,
  FormTitle,
  InfoContent,
  InfoImage,
  Infosection,
  Row,
  SocialImag,
  SocialLink,
  SocialMadiaTitle,
  SocialMediaLinks,
  StyledForm,
} from "../style";

export const DesktopTemplate = ({
  socialNetworksDatas,
  subscribe_form_title,
  footer_content,
  onFormSubmit,
  footer_info_image,
  onPrivacyLinkClick,
  MessageComponent,
  children,
}) => {
  return (
    <>
      <Infosection>
        <InfoImage src={footer_info_image.url} />
        <InfoContent dangerouslySetInnerHTML={{ __html: footer_content }} />
        <SocialMediaLinks>
          <SocialMadiaTitle>Follow us</SocialMadiaTitle>
          <Row style={{ justifyContent: "flex-start" }}>
            {socialNetworksDatas.map(([{ url: src }, href], key) => (
              <SocialLink {...{ key, href }}>
                <SocialImag {...{ src }} />
              </SocialLink>
            ))}
          </Row>
        </SocialMediaLinks>
      </Infosection>
      <FormSection>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit(e);
          }}
        >
          <Row>
            <FormTitle>{subscribe_form_title}</FormTitle>
          </Row>
          {children}
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <MessageComponent />
            <FormButton
              value={"SIGN UP"}
              name={COMING_SOON_FORM_DATA.subscribe}
            />
          </Row>
        </StyledForm>
      </FormSection>
    </>
  );
};
