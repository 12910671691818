import React from "react";
import PropTypes from "prop-types";

import {
  Wrapper,
  Img,
  InfoWrapper,
  Title,
  Date,
  Content,
  ButtonWrapper,
  SubTitle,
  ImgContainer,
} from "./style";
import { FormButton } from "../FormButton/FormButton";

export const CardL = ({
  title,
  date,
  content,
  img,
  buttonText,
  contentMaxLine = "1",
  subTitle,
}) => {
  return (
    <Wrapper>
      <ImgContainer imgUrl={img} />
      <InfoWrapper>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Date withSubTitle={!!subTitle}>{date}</Date>
        <Title>{title}</Title>
        <Content
          text={content}
          maxLine={contentMaxLine}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
        {!!buttonText && (
          <ButtonWrapper>
            <FormButton
              value={buttonText}
              style={{ backgroundColor: "var(--comanage-green-2)" }}
            />
          </ButtonWrapper>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

CardL.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.string,
  img: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

CardL.defaultProps = {
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  date: "16 March",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
  img: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
  buttonText: "",
  onButtonClick: () => {},
};
