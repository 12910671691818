import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const Wrapper = styled.div`
  width: 65vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 95vw;
  }
  /* border: solid 2px blue; */
  min-height: var(--window-height);
  max-height: var(--window-height);
  /* box-shadow: rgba(112, 112, 0, 0.35) 2px 1px 3px,
    rgba(255, 255, 133, 0.4) 1px 0px 5px, rgb(0 51 28 / 69%) 20px 20px 60px,
    rgb(0 222 122 / 33%) -20px -20px 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Input = styled.input`
  all: unset;
  background: linear-gradient(
    0.25turn,
    var(--comanage-green-2),
    var(--comanage-green-1)
  );
  color: var(--comanage-purple);
  /* box-shadow: rgb(0 222 122 / 14%) -6px -4px 11px 4px,
    rgb(0 51 28 / 32%) 19px 20px 20px 8px,
    rgb(151 210 47 / 39%) 10px -9px 52px 4px;
  background-image: radial-gradient(#459f2bb8, #02724c57); */
  border: solid 1px #009e1c;
  /* backdrop-filter: blur(10px); */
  border-radius: 20px;
  /* max-width: 40vw; */
  max-width: 70ch;
  @media (${SM_MEDIA_QUERY_RULE}) {
    min-width: 70vw;
  }
  padding: 10px;
  font-size: 15pt;
  /* color: white; */
  padding-left: 1vw;
  /* border: solid 1px #0098bd; */
`;
export const InputSection = styled.div`
  /* border: solid 1px blue; */
  width: 65vw;
  height: calc(var(--window-height) * 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  padding-left: 5vw;
  overflow: visible;
`;
export const Button = styled.div`
  background-color: rgb(179 0 189);
  /* box-shadow: rgba(0, 222, 122, 0.33) -6px -4px 25px 4px,
    rgba(0, 51, 28, 0.69) 19px 20px 35px 8px,
    rgba(151, 210, 47, 0.57) 20px -7px 32px 4px; */
  border: 1px solid rgb(242 0 255 / 58%);
  /* color: white; */
  padding: 5px;
  border-radius: 129px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    min-width: 30px;
    height: 30px;
  }
  transition: width 0.5s, height 0.5s;
  :hover {
    width: 40px;
    height: 40px;
  }
`;

export const ChatStep = styled.div`
  width: 60vw;
  /* border: dotted 1px red; */
  display: flex;
  flex-direction: row;
  justify-content: ${({ left }) => (left ? "flex-start" : "flex-end")};
  position: relative;
  top: calc(var(--window-height) * 0.9);
`;
export const ChatChunk = styled.div`
  overflow: hidden;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: ${({ loading }) => (loading ? "0" : "2")}vw;
  padding-bottom: ${({ loading }) => (loading ? "0" : "2")}vw;
  max-height: ${({ contentHeight }) => contentHeight}px;
  transition: max-height 2s, padding-top 2s, padding-bottom 2s;
  width: 30vw;
  box-shadow: rgba(0, 222, 122, 0.33) 2px 1px 3px,
    rgba(203, 255, 119, 0.4) 1px 0px 5px,
    rgba(0, 51, 28, 0.69) 2px 19px 20px 0px,
    rgba(69, 176, 121, 0.46) -20px -20px 60px;
  backdrop-filter: blur(10px);
  border-radius: ${({ left }) =>
    left ? "20px 20px 20px 0px" : "20px 20px 0px 20px"};
  /* border-radius: 20px 0px; */
  text-align: ${({ left }) => (left ? "left" : "right")};
  /* padding: 2vw; */
  margin-bottom: 50px;
`;

export const ContentBody = styled.p`
  padding: 0px;
  margin: 0px;
  color: white;
`;

export const ChatStepsWrapper = styled.div`
  /* border: solid 10px red; */
  /* width: 65vw; */
  /* height: 300px; */
  max-height: calc(var(--window-height) * 0.9);
  min-height: calc(var(--window-height) * 0.9);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
  /* justify-content: flex-end;  */
`;

export const ChatTopSpaceSection = styled.div`
  width: 20vw;
`;

export const Loader = styled.div`
  @keyframes loaderanimation {
    0% {
      rotate: 45deg;
    }
    
    50% {
      rotate: 180deg;
    }
    100% {
      rotate: 45deg;
    }
  }
  background-color: rgb(179 0 189);
  opacity: 1;
  min-width: 20px;
  min-height: 5px;
  border-radius: 5px;
  animation: loaderanimation 2s ease-in-out 500ms infinite;
  transition: opacity 1s;
  /* rotate: 45deg; */
`;
