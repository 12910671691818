import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const SendMail = async (from, message) => {

  const data = await fetchData(
    ENDPOINTS.POST.SEND_MAIL,
    {
      method: METHODS.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ from, message }),
    },
    true
  );
  return data;
};
