import { DateTime } from "luxon";
export const eventDateFormat = "dd/MM/yyyy h:mm a";

export const getLatestEvent = (postList) => {
  const sortedPostList = postList
    .filter(({ type }) => type === "event")
    .map((e) => ({
      unix: DateTime.fromFormat(e.event_date, eventDateFormat).toUnixInteger(),
      ...e,
    }))
    .sort((a, b) => (a.unix > b.unix ? -1 : 1));

  const latest = sortedPostList[0];
  return latest;
};
