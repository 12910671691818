import { useLoaderData, useNavigate } from "react-router-dom";
import {
  Wrapper,
  Section,
  Logo,
  Rotated,
  Container1,
  Container2,
} from "./style";

import {
  COP_LOCALSTORAGE_LANG_LIST_KEY,
  SM_MEDIA_QUERY_THRESHOLD,
} from "../../config";
import { useEffect, useState } from "react";
import { Section1Styled as S1 } from "../COPPage/style";



export const RedirectPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const [hovered, sethovered] = useState(0);
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;

  const fetchedData = useLoaderData();
  const {
    homepage: [
      { acf: { header_image, header_image_sm, header_logo } = {} } = {},
    ],
    postList: postListRaw = [],
    redirectPageData: [{
      acf: {
        cop_section, homepage_section
      }
    }],
    langs,
  } = fetchedData;

  const data = [
    {
      title: homepage_section.title,
      content: homepage_section.content,
      href: window.location.origin + homepage_section.redirect_path,
      button: homepage_section.button_text,
    },
    {
      title: cop_section.title,
      content: cop_section.content,
      href: window.location.origin + cop_section.redirect_path,
      button: cop_section.button_text,
    },
  ];

  const currentImage = isMobile ? header_image_sm : header_image;
  const cardStyle = isMobile ? { width: "80vw" } : {};

  const langlist = JSON.stringify(
    Object.fromEntries(
      langs.map(
        ({
          acf: {
            id,
            icon: { url },
          },
        }) => [id, url]
      )
    )
  );
  window.localStorage.setItem(COP_LOCALSTORAGE_LANG_LIST_KEY, langlist);

  const onButtonClick = (href) => {
    window.location.replace(href);
  };
  return (
    <Wrapper backgroundUrl={currentImage.url} {...{ isMobile }}>
      <Logo src={header_logo.url} />
      {data.map(({ title, content, button, href }, index) => (
        <Section
          {...{
            hovered: hovered === index,
            isMobile,
            onMouseOver: () => sethovered(index),
          }}
        >
          <S1.Content.Wrapper
            onClick={() => onButtonClick(href)}
            {...{ key: title, style: cardStyle }}
          >
            <S1.Content.Title>{title}</S1.Content.Title>
            {content ? <S1.Content.Body>{content}</S1.Content.Body> : ""}
            {button ? <S1.Content.Button>{button}</S1.Content.Button> : ""}
          </S1.Content.Wrapper>
        </Section>
      ))}
    </Wrapper>
  );
};
