import { DEBOUNCE_TIMEOUT_MS } from "../config";

export class Debouncer {
  static lastDebounceTs = 1;

  async = async (cb) => {
    const now = Date.now();
    if (Debouncer.lastDebounceTs + DEBOUNCE_TIMEOUT_MS < now) {
      Debouncer.lastDebounceTs = now;
      return await cb();
    }
  };
  sync = (cb) => {
    const now = Date.now();
    if (Debouncer.lastDebounceTs + DEBOUNCE_TIMEOUT_MS < now) {
      Debouncer.lastDebounceTs = now;
      return cb();
    }
  };
}
