import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
`;
export const Img = styled.img`
  width: 547px;
  height: 297px;
`;
export const Colomn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Title = styled.h2`
  text-align: left;
  font: normal normal bold 24px/28px bicyclette;
  letter-spacing: 0px;
  color: black;
  margin-bottom: 20px;
`;
export const Date = styled.h5`
  text-align: left;
  font: normal normal 300 16px/19px bicyclette;
  letter-spacing: 0px;
`;
export const Content = styled(LinesEllipsis)`
  font-weight: 200;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  width: 563px;
  max-height: 70px;
  text-align: left;
  font: normal normal 300 16px/19px bicyclette;
`;
