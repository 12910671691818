import React, { useState } from "react";
import {
  BigImage,
  CloseModalButton,
  LibraryImage,
  ModalBg,
  PicturesWrapper,
  Line,
  Row,
} from "../style";
import { FormButton } from "../../../components/FormButton/FormButton";

export const Images = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(null);
  return (
    <>
      <PicturesWrapper>
        {images.map(({ url: src }, key) => (
          <LibraryImage
            {...{ src, key, onClick: () => setCurrentImage(src) }}
          />
        ))}
      </PicturesWrapper>
      {currentImage && (
        <>
          <ModalBg />
          <CloseModalButton onClick={() => setCurrentImage(null)}>
            <Line open={true} rotateLine={true} />
            <Line open={true} rotateLine={false} />
          </CloseModalButton>
          <BigImage {...{ src: currentImage }} />
        </>
      )}
      <Row
        style={{
          justifyContent: "center",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        {false && (
          <FormButton
            {...{
              outline: false,
              style: { color: "white", marginBottom: "100px" },
              name: "VIEW MORE ON FLICKER",
              value: "VIEW MORE ON FLICKER",
              onHoverStyle: {
                backgroundColor: "var(--comanage-green-1)",
                borderColor: "var(--comanage-green-1)",
              },
              onClick: () => {},
            }}
          />
        )}
      </Row>
    </>
  );
};
