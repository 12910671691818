import styled, { css } from "styled-components";
import {
  ANIMATION_TIMING_MILSECS,
  BIG_SCREEN_MEDIA_QUERY_RULE,
  SM_MEDIA_QUERY_RULE,
} from "./../../config/index";
export const NoContentWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

export const PlatformSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  a {
    all: unset;
    padding: 10px 30px;
    background-color: var(--comanage-green-3);
    border-radius: 20px 3px;
    width: fit-content;
    color: white;
    cursor: pointer;
    :hover {
      background-color: var(--comanage-green-1);
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      padding: 10px;
      width: 80vw;
      font-size: 12px;
      text-align: center;
    }
  }
  table {
    width: 100%;
    border-spacing: 0px;
  }

  p,
  h1,
  ul {
    margin: 50px;
  }
  img {
    width: 50vw;
    height: auto;
  }
  h1 {
    color: var(--comanage-purple);
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    tbody {
      display: flex;
      flex-direction: column;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
    }
    tr:nth-child(2) {
      flex-direction: column-reverse;
    }
    img {
      width: 90vw;
      margin-left: 5vw;
    }
    p {
      margin-left: 5vw;
      margin-right: 5vw;
    }
    ul {
      margin-left: 5vw;
      margin-right: 5vw;
    }
    h1 {
      margin-left: 5%;
      margin-top: 100px;
    }
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
    position: relative;
    table {
      max-width: var(--max-width);
    }
    img {
      width: calc(var(--max-width) / 2);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: opensans;
`;

export const HomepageHeader = {
  Wrapper: styled.div`
    --header-h: 1079px;
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 100%;
      --header-h: 850px;
    }
    width: 100%;
    height: var(--header-h);
    background: url(${({ img }) => img}) left center / cover no-repeat;
    position: relative;
  `,
  Logo: styled.img`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    width: 252px;
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 50vw;
    }
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      position: absolute;
      left: calc(50% - (var(--max-width) / 2));
    }
  `,
  Content: styled.div`
    width: 663px;
    height: var(--header-h);
    position: absolute;
    right: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-content: center;
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      right: calc(50% - (var(--max-width) / 2));
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      top: -10%;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  `,
  Title: styled.h1`
    width: 663px;
    font: normal normal bold 60px/70px bicyclette;
    color: white;
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 100%;
      font-size: 40px;
      letter-spacing: 0px;
      line-height: 40px;
    }
  `,
  SubTitle: styled.h5`
    color: white;
    width: 600px;
    font-size: 17px;
    font-weight: 400;
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 100%;
    }
  `,
};

export const Section1Styled = {
  Wrapper: styled.div`
    min-height: 673px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--comanage-purple);
    justify-content: center;
    align-items: center;
    gap: 55px;
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 100%;
      height: fit-content;
      padding-bottom: 40px;
    }
  `,
  Row: styled.div`
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    row-gap: 0px !important;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    ${({ isMobile }) =>
      isMobile
        ? css``
        : css`
            justify-content: center;
          `}
    align-items: center;
    flex-wrap: nowrap;
    gap: 0px;
    overflow-x: scroll;
    @media (${SM_MEDIA_QUERY_RULE}) {
      margin-left: 5%;
      margin-right: 5%;
      row-gap: 0px !important;
      gap: 20px;
    }
  `,
  Title: styled.h2`
    margin-left: 30px;
    margin-right: 30px;
    color: white;
    /* color: var(--comanage-purple); */
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      max-width: var(--max-width);
      position: relative;
      left: 50vw;
      transform: translate(-50%);
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  `,

  Content: {
    Illustration: styled.img`
      width: 40%;
      filter: invert(1);
    `,
    Wrapper: styled.div`
      color: white;
      width: 350px;
      min-height: 363px;
      max-height: 363px;
      /* border: 1px solid var(--comanage-green-2); */
      background: linear-gradient(
        0.25turn,
        var(--comanage-green-2),
        var(--comanage-green-1)
      );
      border-radius: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 20px;
      * {
        margin: 0px;
      }
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      @media (${SM_MEDIA_QUERY_RULE}) {
        margin-left: 0px;
        margin-right: 0px;
      }
    `,
    Title: styled.h4`
      margin-top: 40px;
      max-width: 300px;
      text-align: center;
      letter-spacing: 1px;
      font: normal normal bold 20px/23px bicyclette;
    `,
    Body: styled.div`
      width: 299px;
      text-align: center;
      font-size: 13px;
      line-height: 15px;
      @media (${SM_MEDIA_QUERY_RULE}) {
        width: 90%;
      }
    `,
    Button: styled.a`
      all: unset;
      margin-bottom: 40px;
      margin: 10px;
      font: normal normal normal 15px/18px bicyclette;
      border: 2px solid white;
      border-radius: 20px 3px;
      width: 224px;
      min-height: 40px;
      max-height: 40px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: 300;
    `,
  },
};

export const Section2Styled = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 55px;
    @media (${SM_MEDIA_QUERY_RULE}) {
      height: fit-content;
    }
  `,
  Title: styled.h2`
    width: 90%;
    text-align: center;
    color: var(--comanage-purple);
    font-weight: 800;
    margin-bottom: -10px;
  `,
  SubTitle: styled.h4`
    margin-top: 0px;
    width: 573px;
    text-align: center;
    font-weight: 400;

    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 90%;
    }
  `,
  imgDesktop: styled.img`
    height: 684px;
  `,
  imgSm: styled.div`
    width: 684px;
    overflow: visible;
    background: url(${({ img }) => img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (${SM_MEDIA_QUERY_RULE}) {
      height: 500px;
      width: 120vw;
      background-size: 80%;
    }
  `,
};

export const Section3Styled = {
  Title: styled.h3`
    font: normal normal bold 30px/36px bicyclette;
    @media (${SM_MEDIA_QUERY_RULE}) {
      margin-top: 100px;
    }
  `,
  Content: styled.div`
    width: 368px;
    font-weight: 400;
  `,
  Background: styled.div`
    background: url(${({ bg }) => bg});
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      width: 100vw;
    }
  `,

  MobileImg: styled.div`
    position: relative;
    height: 652px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 652px;
      position: absolute;
    }
  `,
  Column: styled.div`
    z-index: 2;
    position: absolute;
    width: fit-content;
    top: 0%;
    left: 2%;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    @media (${SM_MEDIA_QUERY_RULE}) {
      justify-content: flex-start;
      margin-left: 5%;
      margin-right: 5%;
    }
  `,
  Wrapper: styled.div`
    @media (${SM_MEDIA_QUERY_RULE}) {
      margin-top: 50px;
    }
    height: 657px;
    position: relative;
    overflow: hidden;
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      max-width: var(--max-width);
      position: relative;
      left: 50vw;
      transform: translate(-50%);
      overflow: visible;
    }
  `,
};

export const Section4Styled = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 100px;
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      max-width: var(--max-width);
      position: relative;
      left: 50vw;
      transform: translate(-50%);
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      margin-bottom: 100px;
      flex-wrap: wrap;
      gap: 100px;
      height: fit-content;
    }
  `,
  Content: {
    Wrapper: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 30px;
      margin-right: 30px;
      flex-wrap: wrap;
      @media (${SM_MEDIA_QUERY_RULE}) {
        margin-left: 5vw;
        width: 90vw;
      }
    `,
    Title: styled.h4`
      font: normal normal bold 30px/36px bicyclette;
      color: var(--comanage-purple);
      width: 474px;
      margin-top: 0px;
    `,
    Row: styled.div`
      width: 715px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      @media (${SM_MEDIA_QUERY_RULE}) {
        width: 100vw;
      }
    `,
    Body: styled.div`
      a {
        color: var(--comanage-green-2);
      }
      a:hover {
        opacity: 0.8;
      }
      p {
        margin-top: 0px;
      }
    `,
    Button: styled.a`
      all: unset;
      padding: 10px 30px;
      background-color: var(--comanage-green-3);
      border-radius: 20px 3px;
      width: fit-content;
      color: white;
      cursor: pointer;
      :hover {
        background-color: var(--comanage-green-1);
      }
      @media (${SM_MEDIA_QUERY_RULE}) {
        padding: 10px;
        width: 80vw;
        font-size: 12px;
        text-align: center;
      }
    `,
  },
};

export const Section5Styled = {
  Wrapper: styled.div`
    margin-right: 30px;
    margin-bottom: 100px;
    @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
      max-width: var(--max-width);
      position: relative;
      left: 50vw;
      transform: translateX(-50%);
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      width: 100%;
      margin: 0%;
    }
  `,
  Title: styled.h1`
    margin-top: 100px;
    color: var(--comanage-purple);
    @media (${SM_MEDIA_QUERY_RULE}) {
      margin-top: initial;
      margin-left: 5vw;
    }
  `,
  Content: styled.div`
    table {
      border-spacing: 20px 50px;
    }

    td {
      text-align: center;
    }
    img {
      width: 250px;
      height: auto;
    }
    @media (${SM_MEDIA_QUERY_RULE}) {
      table {
        border-spacing: 0px 0px;
        margin-bottom: 100px;
      }
      tbody {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        width: 50vw;
      }
      img {
        width: 80%;
        height: auto;
        margin: 10%;
      }
    }
  `,
};

export const Section1Detail = {
  VisualWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5%;
    margin-bottom: 0px;
    gap: 20px;
    max-width: var(--max-width);
    align-self: center;
  `,
  Title: styled.h1``,
  Wrapper: styled.div`
    width: 100vw;
    max-width: var(--max-width);
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
    justify-content: space-between;
    overflow: visible;
    /* overflow-y: scroll; */
    height: ${({ wrapperHeigth }) => wrapperHeigth};
    -webkit-transition: height ${ANIMATION_TIMING_MILSECS * 0.001}s;
    -moz-transition: height ${ANIMATION_TIMING_MILSECS * 0.001}s;
    -ms-transition: height ${ANIMATION_TIMING_MILSECS * 0.001}s;
    -o-transition: height ${ANIMATION_TIMING_MILSECS * 0.001}s;
    transition: height ${ANIMATION_TIMING_MILSECS * 0.001}s,
      opacity ${ANIMATION_TIMING_MILSECS * 0.001}s;
  `,
};

export const Body = styled.div`
  width: var(--max-width);
`;
export const Column = styled.div`
  width: var(--sub-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
  }
`;
export const DocumentNoImageWrapper = styled.div`
  margin-top: 100px;
  background-color: var(--comanage-purple);
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

export const Title = styled.h1`
  color: var(--comanage-purple);
  margin-left: 30px;
  margin-right: 30px;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
    position: relative;
    /* left: 50vw; */
    /* transform: translate(-50%); */
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

export const Row = styled.div`
  width: var(--sub-width);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
