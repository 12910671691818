import { ComingSoon } from ".";
import { getComingSoonFooterData } from "../../calls/get/comingSoonFooter";
import { getComingSoonPageData } from "../../calls/get/comingSoonPage";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const comingSoonRoute = {
  path: ROUTES.COMING_SOON_HOMEPAGE,
  element: <ComingSoon />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    page: await getComingSoonPageData(),
    footer: await getComingSoonFooterData(),
  }),
};
