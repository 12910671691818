import { BASE_PATH_PROXY, BASE_PATH_WP, SAME_URL } from "./connectionSettings";

export const ENDPOINTS = {
  GET: {
    COP_POST: BASE_PATH_WP`cop_post?per_page=100`,
    POST: BASE_PATH_WP`posts/?filter[p]=%ID%`,
    FOOTER: BASE_PATH_WP`footer`,
    LBRARY_PAGE: BASE_PATH_WP`library_page`,
    LBRARY_PAGE_DOWNLOADS: BASE_PATH_WP`library_downloads`,
    PILOT_LIST: BASE_PATH_WP`pilots?per_page=100`,
    COMING_SOON: BASE_PATH_WP`coming_soon_page`,
    COMING_SOON_FOOTER: BASE_PATH_WP`coming_soon_footer`,
    COMING_SOON_PRIVACY: BASE_PATH_WP`coming_soon_privacy`,
    ABOUT: BASE_PATH_WP`about_page`,
    DSS_CARDS: BASE_PATH_WP`dss_card?per_page=100`,
    DSS_INFO_SECTION: BASE_PATH_WP`dss_info_section?per_page=100`,
    PAST_EVENTS_PAGE: BASE_PATH_WP`past_event_page`,
    NEWS_EVENTS_PAGE: BASE_PATH_WP`news_and_events_page`,
    POST_LIST: BASE_PATH_WP`posts?per_page=100`,
    HOMEPAGE: BASE_PATH_WP`homepage`,
    REDIRECT_PAGE: BASE_PATH_WP`redirect_page`,
    LANG_LIST: BASE_PATH_WP`cop_languages`,
    COP_PAGE: BASE_PATH_WP`cop_page?per_page=100`,
    COP_PAGE_CONTENTS: BASE_PATH_WP`cop_contents?per_page=100`,
    COP_PAGE_CATEGORIES: BASE_PATH_WP`cop_categories?per_page=100`,
    COP_LANGUAGES_DATA: BASE_PATH_WP`cop_languages`,
    SOCIAL_PREVIEW: (id) => SAME_URL`api/socialpreview/` + id,
  },
  POST: {
    SEND_MAIL: `${window.location.origin}/api/sendmail/`,
    // SEND_MAIL: BASE_PATH_PROXY`api/sendmail/`,
    SUBSCRIBE: {
      URL: "https://gmail.us21.list-manage.com/subscribe/post-json",
      PROXY: SAME_URL`api/mailchimp/subscribe`,
    },
  },
};
