import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  MainSection,
  BgImgTopRight,
  BgImgBottomLeft,
  LowerLinksSection,
  FooterLowerLink,
  TopGradient,
  InfoSection,
  MiniMenu,
  SocialLinks,
  ContactFormWrapper,
  InfoImage,
  FooterLogo,
  InfoContent,
  FooterSubTitle,
  MiniMenuList,
  MiniMenuItemList,
  MiniMenuLink,
  SocialLink,
  SocialImag,
  Row,
  MainContent,
} from "./style";
import {
  COP_LOCALSTORAGE_LANG_KEY,
  SM_MEDIA_QUERY_THRESHOLD,
} from "../../config";

import { FormInput } from "../FormInput/FormInput";
import { FormRadio } from "../FormRadio/FormRadio";
import { FormButton } from "../FormButton/FormButton";
import { ROUTES } from "../../config/menuRoutes";
import { SendMail } from "../../calls/get/SendMail";

const rowInputStyle = { width: "45%", style: { width: "45%" } };

export const Footer = ({
  social_url_1,
  social_url_2,
  social_url_3,
  social_img_1 = null,
  social_img_2 = null,
  social_img_3 = null,
  footer_logo,
  footer_info_image,
  footer_info_content,
  contact_us_email,
  designer_url,
  developer_url,
  onPrivacyLinkClick,
  bg_img_top_right,
  bg_img_bottom_left,
  designer_label,
  developer_label,
  onDesignerLinkClick,
  onDeveloperLinkClick,
  onContactUsLink,
  FooterMenuLinks,
  multiLang = false,
  copPageData = null,
  ...props
}) => {
  let linkList = FooterMenuLinks;
  const [contactUsSenderEmail, setContactUsSenderEmail] = useState("");
  const [contactUsMessage, setContactUsMessage] = useState("");
  const [privacyPolicyAgreed, setprivacyPolicyAgreed] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(null);
  const [errorTestEmailRadio, setErrorTestEmailRadio] = useState("");
  const [errorTextSenderEmail, setErrorTextSenderEmail] = useState("");
  const [errorTextContactUsMessage, setErrorTextContactUsMessage] =
    useState("");
  const socialNetworksDatas = [
    [social_img_1, social_url_1],
    [social_img_2, social_url_2],
    [social_img_3, social_url_3],
  ].filter(([img]) => !!img);

  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;

  const sendContactUsMail = async () => {
    setErrorTestEmailRadio(null);
    setErrorTextContactUsMessage(null);
    setErrorTextSenderEmail(null);
    if (!privacyPolicyAgreed) {
      setErrorTestEmailRadio("you need to accept the privacy policy");
      return;
    }
    if (contactUsMessage === "") {
      setErrorTextContactUsMessage("you need to fill this field");
      return;
    }
    if (contactUsSenderEmail === "") {
      setErrorTextSenderEmail("you need to fill this field");
      return;
    }
    const sendEmailSuccess = await SendMail(
      contactUsSenderEmail,
      contactUsMessage
    );
    setSendEmailSuccess(sendEmailSuccess);
    alert(sendEmailSuccess ? "mail sent successfully" : "mail not sent");
  };

  if (multiLang) {
    const currentLang = window.localStorage.getItem(COP_LOCALSTORAGE_LANG_KEY) || 'eng';
    const {
      acf: {
        tools: { section_1, section_2, section_3 },
      },
    } = copPageData.find(({ acf: { lang } }) => lang === currentLang);
    const menuItems = [section_1, section_2, section_3].map(
      ({ title, button_url }) => ({
        title,
        href: button_url,
      })
    );
    linkList = menuItems;
  }
  return (
    <Wrapper id="footer">
      <TopGradient />
      <BgImgTopRight src={bg_img_top_right.url} />
      {!isMobile && <BgImgBottomLeft src={bg_img_bottom_left.url} />}
      <MainContent>
        <FooterLogo src={footer_logo.url} />

        <MainSection>
          <InfoSection>
            <InfoImage src={footer_info_image.url} />
            <InfoContent
              dangerouslySetInnerHTML={{ __html: footer_info_content }}
            />
          </InfoSection>
          <MiniMenu>
            <FooterSubTitle>Explore</FooterSubTitle>
            <MiniMenuList>
              {linkList
                .filter(({ title }) => title !== "Contact us")
                .map(({ href, title, children = null }, key) => {
                  return (
                    <MiniMenuItemList {...{ key }}>
                      <MiniMenuLink {...{ href }}>{title}</MiniMenuLink>
                      {children &&
                        children?.length &&
                        children.map(({ href, title }) => (
                          <MiniMenuItemList {...{ key }}>
                            <MiniMenuLink
                              {...{
                                href,
                                key: title,
                                style: {
                                  fontSize: "0.9em",
                                  lineHeight: "0px",
                                  marginLeft: "5px",
                                },
                              }}
                            >
                              · {title}
                            </MiniMenuLink>
                          </MiniMenuItemList>
                        ))}
                    </MiniMenuItemList>
                  );
                })}
            </MiniMenuList>
          </MiniMenu>
          <SocialLinks>
            <FooterSubTitle>Follow Us</FooterSubTitle>
            <Row style={{ justifyContent: "flex-start" }}>
              {socialNetworksDatas.map(([{ url: src }, href], key) => (
                <SocialLink {...{ key, href }}>
                  <SocialImag {...{ src }} />
                </SocialLink>
              ))}
            </Row>
          </SocialLinks>
          <ContactFormWrapper>
            <FooterSubTitle>Contact Us</FooterSubTitle>
            <FormInput
              styleType={"b"}
              placeholder="Email address"
              width={isMobile ? "90vw" : "400px"}
              onChange={(e) => setContactUsSenderEmail(e.target.value)}
              errorText={errorTextSenderEmail}
            />
            <FormInput
              styleType={"b"}
              textarea
              placeholder={"...your message"}
              errorText={errorTextContactUsMessage}
              width={isMobile ? "90vw" : "400px"}
              onChange={(e) => setContactUsMessage(e.target.value)}
            />
            <FormRadio
              onPrivacyLinkClick={() =>
                (window.location.href = ROUTES.COMING_SOON_PRIVACY)
              }
              onChange={(e) => {
                setprivacyPolicyAgreed(e.target.checked);
              }}
              errorText={errorTestEmailRadio}
            />
            <FormButton
              styleType={"b"}
              name="Submit"
              value="SEND"
              onClick={sendContactUsMail}
              style={{
                backgroundColor: "var(--comanage-green-2)",
                width: "150px",
                letterSpacing: "0px",
              }}
              onHoverStyle={{
                backgroundColor: "var(--comanage-green-1)",
                borderColor: "var(--comanage-green-1)",
              }}
            />
          </ContactFormWrapper>
        </MainSection>

        <LowerLinksSection>
          <FooterLowerLink onClick={onPrivacyLinkClick}>
            Privacy Policy
          </FooterLowerLink>
          {" | "} Designed by{" "}
          <FooterLowerLink target={"_blank"} href={designer_url}>
            {designer_label}
          </FooterLowerLink>
          {" | "} Developed by{" "}
          <FooterLowerLink target={"_blank"} href={developer_url}>
            {developer_label}
          </FooterLowerLink>
          {"."}
        </LowerLinksSection>
      </MainContent>
    </Wrapper>
  );
};

Footer.propTypes = {
  designer_label: PropTypes.string,
  developer_label: PropTypes.string,
  footer_info_content: PropTypes.string,
  contact_us_email: PropTypes.string,
  designer_url: PropTypes.string,
  developer_url: PropTypes.string,
  social_url_1: PropTypes.string,
  social_url_2: PropTypes.string,
  social_url_3: PropTypes.string,
  footer_logo: PropTypes.object,
  footer_info_image: PropTypes.object,
  social_img_1: PropTypes.any,
  social_img_2: PropTypes.any,
  social_img_3: PropTypes.any,
  bg_img_top_right: PropTypes.object,
  bg_img_bottom_left: PropTypes.object,
  onPrivacyLinkClick: PropTypes.func,
  onDesignerLinkClick: PropTypes.func,
  onDeveloperLinkClick: PropTypes.func,
  onContactUsLink: PropTypes.func,
};

Footer.defaultProps = {
  onPrivacyLinkClick: () => {},
  onDesignerLinkClick: () => {},
  onDeveloperLinkClick: () => {},
  onContactUsLink: () => {},
  FooterMenuLinks: [],
  designer_label: "designer_label",
  developer_label: "developer_label",
  footer_info_content: "footer_info_content",
  contact_us_email: "contact_us_email",
  designer_url: "designer_url",
  developer_url: "developer_url",
  social_url_1: "social_url_1",
  social_url_2: "social_url_2",
  social_url_3: "social_url_3",
  footer_logo: {
    url: "url",
  },
  footer_info_image: {
    url: "url",
  },
  social_img_1: {
    url: "url",
  },
  social_img_2: {
    url: "url",
  },
  social_img_3: {
    url: "url",
  },
  bg_img_top_right: {
    url: "url",
  },
  bg_img_bottom_left: {
    url: "url",
  },
};
