import React, { useState } from "react";
import { CardLibrary } from "../../../components/CardLibrary/CardLibrary";
import { FormButton } from "../../../components/FormButton/FormButton";
import { download } from "../../../utils/download";
import { DeliverablesWrapper, Row } from "../style";

const deliverableStep = 4;

export const Deliverables = ({ deliverables }) => {
  const [deliverablesLength, setDeliverablesLength] = useState(
    deliverableStep - 1
  );
  const displayedDeliverables = deliverables.filter(
    (_, i) => i <= deliverablesLength
  );
  return (
    <>
      <DeliverablesWrapper>
        {displayedDeliverables.map(
          ({ image: { url: img }, title, files: { filename, url } }, key) => (
            <CardLibrary
              {...{
                withContent: false,
                img,
                title,
                key,
                onButtonClick: () => download(url, filename),
              }}
            />
          )
        )}
      </DeliverablesWrapper>
      {deliverablesLength < displayedDeliverables.length && (
        <Row style={{ justifyContent: "center", marginTop: "5%" }}>
          <FormButton
            {...{
              style: { color: "black" },
              name: "SHOW MORE",
              value: "SHOW MORE",
              onHoverStyle: {
                backgroundColor: "var(--comanage-green-1)",
                borderColor: "var(--comanage-green-1)",
              },
              onClick: () =>
                setDeliverablesLength(deliverablesLength + deliverableStep),
            }}
          />
        </Row>
      )}
    </>
  );
};
