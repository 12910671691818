const rules = [
  "marginLeft",
  "marginRight",
  "borderLeftWidth",
  "borderRightWidth",
];

export const getRenderedWidth = (pureJsDomElement) => {
  const rawWidth = pureJsDomElement.getBoundingClientRect().width;
  const raw = Object.entries(window.getComputedStyle(pureJsDomElement))
    .filter(([rule]) => rules.includes(rule))
    .map(([, value]) => value);
  const styledAmplitude = raw.reduce(
    (a, e) => a + Number(e.match(/\d+/gm)[0]),
    0
  );

  return styledAmplitude + rawWidth;
};
