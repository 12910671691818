import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";
export const Background = styled.div`
  background-color: var(--comanage-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vw 0vw;
`;

export const Wrapper = styled.div`
  width: 68vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100vw;
  }
`;
