import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const PageContentWrapper = styled.div`
  transition: top 2s, left 2s;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 400px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    transform: scale(0.7) translateY(-200px);
  }
`;
export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: var(--window-width);
  min-height: var(--window-height);
  max-height: var(--window-height);
  margin-top: 400px;
  margin-bottom: 400px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 95vw;
  }
`;

export const Window = styled.div`
  position: relative;
  width: 65vw;
  /* width: var(--window-width); */
  min-height: var(--window-height);
  max-height: var(--window-height);
  /* border: solid 1px purple; */
  overflow: hidden;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 95vw;
  }
`;
