import styled from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  width: 90%;
  margin-left: 5%;
  min-height: 1000px;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    margin-left: 0%;
    width: var(--max-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const MainImage = styled.img`
  width: 50%;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100vw;
  }
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    /* width: calc(var(--max-width) / 2); */
    width: 629.85px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
