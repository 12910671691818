import { Link, useLoaderData, useNavigate } from "react-router-dom";
import {
  Wrapper,
  HomepageHeader,
  Section1Detail as S1d,
  Title,
  Section4Styled as S4,
  NoContentWrapper,
} from "./style";
import { download } from "../../utils/download";

import {
  COP_LOCALSTORAGE_LANG_KEY,
  COP_LOCALSTORAGE_LANG_LIST_KEY,
  COP_DEFAULT_LANG,
  SM_MEDIA_QUERY_THRESHOLD,
} from "../../config";
import { useEffect } from "react";
import { CardM } from "../../components/CardM/CardM";
import { SimpleDocumentsDownloadSection } from "./Components/SimpleDocumentsDownloadSection";
import { ROUTES } from "../../config/menuRoutes";

export const COPCategoryPage = () => {
  const [, currentCategory] = window.location.search
    .replace(/^\?/gm, "")
    .split("=");
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const {
    copPage,
    categories,
    contents: contents_raw,
    langs,
    homepage,
  } = useLoaderData();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!currentCategory) window.location.replace(ROUTES.COPPAGE);
  const currentCategoryData_raw = categories.find(
    ({ acf: { id } }) => id === currentCategory
  );
  const currentCategoryData = currentCategoryData_raw.acf;
  const currentLang =
    window.localStorage.getItem(COP_LOCALSTORAGE_LANG_KEY) || COP_DEFAULT_LANG;
  const {
    acf: {
      title,
      subtitle,
      header_image,
      header_logo,
      header_image_sm,
      e_learning_platform_section,
      dss_section,
      digital_hub_section,
      categories_title,
      platforms_list,
      ...fetchedData
    } = {},
  } = copPage.find(({ acf: { lang } }) => currentLang === lang);

  const contents = contents_raw.map((c) => c.acf);
  const currentContents = contents.filter(
    ({ section_id, lang }) =>
      section_id === currentCategory && lang === currentLang
  );

  const langlist = JSON.stringify(
    Object.fromEntries(
      langs.map(
        ({
          acf: {
            id,
            icon: { url },
          },
        }) => [id, url]
      )
    )
  );
  window.localStorage.setItem(COP_LOCALSTORAGE_LANG_LIST_KEY, langlist);

  const visualsDocs = currentContents.filter(
    ({ content_type }) => content_type === "visual"
  );
  const docs = currentContents
    .filter(({ content_type }) => content_type === "document")
    .map(
      ({ section_id, lang, content_type, visual, document_type_section }) => ({
        id: `${section_id}_${lang}`,
        section_id,
        lang,
        content_type,
        visual,
        document_type_section,
      })
    );
  const onVisualDocClick = ({
    button_text,
    content,
    file,
    image,
    post_id,
    subtitle,
    title,
    type,
    url,
    category_id,
  }) => {
    switch (type) {
      case "post":
        navigate("/" + ROUTES.COP_POST.replace(":id", post_id));
        break;
      case "category":
        navigate(`/${ROUTES.COP_CATEGORY_PAGE}?id=${category_id}`);
        break;
      case "file":
        download(file.url);
        break;
      case "url":
        window.open(url, "_blank").focus();
        break;

      default:
        break;
    }
  };
  return (
    <Wrapper>
      <HomepageHeader.Wrapper img={header_image.url}>
        <HomepageHeader.Logo src={header_logo.url} />
        <HomepageHeader.Content>
          <HomepageHeader.Title>{title}</HomepageHeader.Title>
          <HomepageHeader.SubTitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </HomepageHeader.Content>
      </HomepageHeader.Wrapper>
      <Title style={{ margin: "50px 0px" }}>{currentCategoryData.name}</Title>

      {visualsDocs.length ? (
        <S1d.VisualWrapper>
          {visualsDocs.map(({ visual: visualDoc }) => {
            const { title, content, image, subtitle, button_text, file, type } =
              visualDoc;
            return (
              <CardM
                {...{
                  withOpenSans: true,
                  style: {
                    cursor: "pointer",
                  },
                  onClick: () => onVisualDocClick(visualDoc),
                  // key: `visual_${title}`,
                  key: `visual_${title}_${type}`,
                  title: title,
                  content: content,
                  img: image.url,
                  date: false,
                }}
              />
            );
          })}
        </S1d.VisualWrapper>
      ) : (
        ""
      )}
      {docs.length ? (
        <SimpleDocumentsDownloadSection {...{ isMobile, docs }} />
      ) : (
        ""
      )}
      {!docs.length && !visualsDocs.length ? (
        <NoContentWrapper>
          <Title>No Contents yet</Title>
          <Link
            style={{
              all: "unset",
            }}
            to={`/${ROUTES.COPPAGE}`}
          >
            <S4.Content.Button>Go Back</S4.Content.Button>
          </Link>
        </NoContentWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};
