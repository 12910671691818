import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;
export const ErrorWarning = styled.div`
  margin-top: 5px;
  position: absolute;
  border: solid 1px red;
  background-color: white;
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  transform: translate(calc(-50% + ${({ width }) => width * 0.5}px), -50%);
  width: fit-content;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  color: red;
`;

const inputTypeA = css`
  ${({ width }) => (width ? `width:${width};` : "")}
  border-radius: 20px 3px 20px 3px;
  background: #0696b7;
  color: white;
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
  border: ${({ error }) =>
    error ? css` 2px solid #e85c41` : css`1px solid #abb0b2`};
  /* margin: 5px; */
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.8;
  font-family: "bicyclette" !important;
  letter-spacing: 1px;
  ::placeholder {
    color: white;
    padding-left: 20px;
  }
`;
const inputTypeB = css`
  ${({ width }) => (width ? `width:${width};` : "")}
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
  border: ${({ error }) =>
    error ? css` 2px solid #e85c41` : css`1px solid var(--comanage-green-2)`};
  /* margin: 5px; */
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.8;
  font-family: "bicyclette" !important;
  padding-left: 5px;
  ::placeholder {
    color: grey;
    padding-top: 5px;
    padding-left: 5px;
  }
`;

export const StyledFormTextArea = styled.textarea`
  ${({ width }) => (width ? `width:${width};` : "")}
  resize: none;
  height: 100px;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
  border: ${({ error }) =>
    error ? css` 2px solid #e85c41` : css`1px solid var(--comanage-green-2)`};
  /* margin: 5px; */
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.8;
  font-family: "bicyclette" !important;
  padding-left: 5px;
  ::placeholder {
    color: grey;
    padding-top: 5px;
    padding-left: 5px;
  }
`;

export const StyledFormInput = styled.input`
  ${({ styleType }) => {
    switch (styleType) {
      case "b":
        return inputTypeB;
      default:
        return inputTypeA;
    }
  }}
`;
