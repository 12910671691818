import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const MainSection = styled.section`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 200px;
`;

export const BgImage = styled.img`
  position: absolute;
  z-index: -1;
  top: 10px;
  max-width: 75%;
  min-width: 700px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    display: none;
    visibility: collapse;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const LogoImg = styled.img`
  width: 310px;
  height: 56px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100%;
    min-width: unset;
  }
`;

export const LeftSection = styled.div`
  max-width: 45%;
  min-width: 500px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    min-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const RightSection = styled.div`
  max-width: 45%;
  min-width: 400px;
  padding: 75px 0px 73px 12vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }
`;
export const LogoTitle = styled.h1`
  color: var(--comanage-purple);
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.6px;
  font-family: "bicyclette", sans-serif !important;
  margin-bottom: 50px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 60px;
  }
`;
export const Title = styled.h1`
  color: var(--comanage-purple);
  font-family: "bicyclette" sans-serif;
  font-size: 25px;
  font-weight: 900;
  margin-top: 130px;
  margin-bottom: 40px;
  line-height: 1.3;
  width: 690px;
  word-wrap: break-word;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 50px;
    font-weight: 700;
    width: 75%;
    text-align: center;
    margin-left: calc((100% - 75%) * 0.5);
  }
`;
export const MainContent = styled.p`
  word-wrap: break-word;
  font-family: "bicyclette", sans-serif !important;
  font-size: 16px;
  font-weight: 300;
  color: #444444;
  line-height: 1.9;
  max-width: 690px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90%;
    padding-left: 5%;
    text-align: center;
  }
`;
export const LogoWrapper = styled.div`
  margin-top: 200px;
  margin-left: 23vw;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
