import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const MainContentWrapper = styled.div`
  max-width: calc(950px + 20px);
  font-family: "bicyclette", sans-serif !important;
  p {
    font-weight: 300;
    margin: 0 0 1.5em;
    line-height: 1.9;
  }
  h2 {
    font-size: 2em;
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const MainTitle = styled.h1`
  font-family: "bicyclette", sans-serif !important;
  font-weight: 300;
  line-height: 1.4;
  font-size: 1.625rem;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Line = styled.div`
  width: 130px;
  height: 1px;
  background: #e6e6e6;
  margin: 40px auto 48px;
`;
