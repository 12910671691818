import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  Row,
  Title,
  Column,
  Line,
  NewsLetterSection,
  NewsLetterTitle,
  NewsLetterContent,
  Background,
  SignUpWrapper,
} from "./style";
import { FormButton } from "./../FormButton/FormButton";
import { CardHomepage } from "./../CardHomepage/CardHomepage";
import { SingUpForm } from "./../SingUpForm/SingUpForm";
import { CardL } from "../CardL/CardL";
import { CardM } from "../CardM/CardM";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/menuRoutes";
import {
  EVENTS_NEWS_ENEBLED,
  SM_MEDIA_QUERY_THRESHOLD,
} from "./../../config/index";
import { css } from "styled-components";
import { DateTime } from "luxon";

export const SubscribeToNewsLetterSection = () => (
  <Row
    href="#newsletter"
    style={{
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "30px",
      flexWrap: "wrap",
    }}
  >
    <NewsLetterSection>
      <NewsLetterTitle>Newsletter</NewsLetterTitle>
      <NewsLetterContent>
        Subscribe to our newsletter to receive updates and upcoming training
        session news about the COMANAGE toolkit.
      </NewsLetterContent>
    </NewsLetterSection>
    <SignUpWrapper>
      <SingUpForm />
    </SignUpWrapper>
  </Row>
);

export const EventsNewsSection = ({
  event: {
    header_img: { url: img = "" } = {},
    event_date = "event_date",
    preview_text: content = "preview_text",
    title = "title",
    id = 0,
  } = {},
  news = [],
}) => {
  const date = DateTime.fromFormat(event_date, "dd/MM/yyyy h:mm a").toFormat(
    "dd MMMM yyyy"
  );

  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const now = DateTime.now().toUnixInteger();
  const eventTime = DateTime.fromFormat(
    event_date,
    "dd/MM/yyyy h:mm a"
  ).toUnixInteger();
  const subTitle = eventTime > now ? "Upcoming events" : "";
  if (news.length > 2) news = news.filter((_, i) => i < 1);
  return (
    <Background>
      <Wrapper>
        {EVENTS_NEWS_ENEBLED && (
          <>
            {" "}
            <Row
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Title>Events & News</Title>
              {!isMobile && (
                <Link
                  to={`/${ROUTES.NEWS_EVENTS}`}
                  style={{ all: "unset", cursor: "pointer" }}
                >
                  <FormButton
                    {...{
                      style: {
                        backgroundColor: "rgba(0,0,0,0)",
                        borderColor: "var(--comanage-green-3)",
                      },
                      onHoverStyle: {
                        backgroundColor: "var(--comanage-green-3)",
                      },
                      value: "READ MORE",
                      name: "READ MORE",
                    }}
                  />
                </Link>
              )}
            </Row>
            <Row
              style={{
                flexWrap: "wrap",
                gap: "30px",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Link
                to={`/post/${id}`}
                style={{ all: "unset", cursor: "pointer" }}
              >
                {isMobile ? (
                  <CardHomepage
                    {...{
                      title,
                      date,
                      content,
                      img,
                      contentMaxLine: "5",
                      subTitle,
                    }}
                  />
                ) : (
                  <CardL
                    {...{
                      title,
                      date,
                      content,
                      img,
                      contentMaxLine: "5",
                      subTitle,
                    }}
                  />
                )}
              </Link>
              <Row
                style={{
                  justifyContent: isMobile ? "center" : "flex-end",
                  marginTop: isMobile ? "30px" : "unset",
                  width: "fit-content",
                }}
              >
                <Column>
                  {news.map(
                    (
                      {
                        date,
                        header_img: { url: img },
                        title,
                        preview_text: content,
                        id,
                      },
                      key
                    ) => (
                      <Link
                        key={key}
                        to={`/post/${id}`}
                        style={{ all: "unset", cursor: "pointer" }}
                      >
                        <CardHomepage {...{ date, img, title, content }} />
                      </Link>
                    )
                  )}
                </Column>
              </Row>
            </Row>
            <Line />
          </>
        )}
        <SubscribeToNewsLetterSection />
      </Wrapper>
    </Background>
  );
};

EventsNewsSection.propTypes = {
  title: PropTypes.string,
  event: PropTypes.object,
  news: PropTypes.array,
};

EventsNewsSection.defaultProps = {
  title: "title",
};
