import { getNewsEventPageData } from "../../calls/get/newsEventsPage";
import { getPostList } from "../../calls/get/postList";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
import { NewsEventsPage } from "./index";

export const newsEventsPageRoute = {
  path: ROUTES.NEWS_EVENTS,
  element: <NewsEventsPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    pageData: await getNewsEventPageData(),
    list: await getPostList(),
  }),
};
