import React, { useEffect, useRef, useState } from "react";

import { RadioLabel, PrivacyLink, ErrorWarningRadio, Wrapper } from "./styles";
import { getRenderedWidth } from "../../utils/getRenderedWidth";
import PropTypes from "prop-types";

export const FormRadio = ({
  onPrivacyLinkClick,
  name,
  value,
  errorText,
  onChange = () => {},
}) => {
  const [pxwidth, setpxwidth] = useState(0);
  const ref = useRef();
  useEffect(() => {
    setpxwidth(getRenderedWidth(ref.current));
  }, []);
  return (
    <Wrapper {...{ ref, withError: !!errorText }}>
      <input type="radio" name={name} value={value} onChange={onChange} />
      <RadioLabel>
        I agree to the{" "}
        <PrivacyLink onClick={onPrivacyLinkClick}>privacy policy</PrivacyLink>
      </RadioLabel>
      {errorText && (
        <ErrorWarningRadio {...{ width: pxwidth }}>
          {errorText}
        </ErrorWarningRadio>
      )}
    </Wrapper>
  );
};

FormRadio.propTypes = {
  onPrivacyLinkClick: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
};

FormRadio.defaultProps = {
  onPrivacyLinkClick: () => {},
  name: "name",
  value: "value",
  errorText: null,
};
