import React from "react";
import { Section1Styled as S1 } from "../style";

export const Section1 = ({
  title = "",
  tabs = [
    {
      title: "",
      content: "",
      url: "",
      button: "",
    },
  ],
}) => {
  return (
    <S1.Wrapper>
      <S1.Title>{title}</S1.Title>
      <S1.Row>
        {tabs.map(({ title, content, url, button }, key) => (
          <S1.Content.Wrapper {...{ key }}>
            <S1.Content.Title>{title}</S1.Content.Title>
            <S1.Content.Body>{content}</S1.Content.Body>
            {button === "COMING SOON" ? (
              <S1.Content.Button
              //  href={url}
              >
                {button}
              </S1.Content.Button>
            ) : (
              <S1.Content.Button href={url} target="_blank">
                {button}
              </S1.Content.Button>
            )}
          </S1.Content.Wrapper>
        ))}
      </S1.Row>
    </S1.Wrapper>
  );
};
