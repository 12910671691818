import React, { useState } from "react";
import { CardLibrary } from "../../../components/CardLibrary/CardLibrary";
import { FormButton } from "../../../components/FormButton/FormButton";
import { download } from "../../../utils/download";
import { Column, Row, Title, TmaterialsWrapper } from "../style";

const trainingStep = 4;
const bordered = { borderBottom: "solid 1px var(--comanage-green-2)" };

let smallListElementStyle = {};

export const TrainingMaterial = ({ training_material, isMobile }) => {
  const [trainingLength, setTrainingLength] = useState(trainingStep);
  if (isMobile) {
    smallListElementStyle = {
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      paddingBottom: "20px",
    };
  }

  return (
    <>
      <TmaterialsWrapper>
        <Row
          {...(() =>
            isMobile ? { style: { justifyContent: "flex-start" } } : {})()}
        >
          <CardLibrary
            {...{
              big: !isMobile,
              withContent: true,
              title: training_material[0].title,
              content: training_material[0].title,
              onButtonClick: () =>
                download(
                  training_material[0].files.url,
                  training_material[0].files.filename
                ),
            }}
          />
        </Row>
        <Column
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {training_material
            .filter((_, i) => i !== 0 && i <= trainingLength)
            .map(({ title, files: { filename, url } }, key) => (
              <Row
                {...{
                  key,
                  style: { ...bordered, ...smallListElementStyle },
                }}
              >
                <Title
                  style={{
                    color: "black",
                    maxWidth: "404px",
                    marginTop: "50px",
                  }}
                >
                  {title}
                </Title>
                <FormButton
                  {...{
                    name: "DOWNLOAD",
                    value: "DOWNLOAD",
                    onClick: () => download(url, filename),
                    style: {
                      backgroundColor: "var(--comanage-green-2)",
                      borderColor: "var(--comanage-green-2)",
                      letterSpacing: "0px",
                    },
                    onHoverStyle: {
                      backgroundColor: "var(--comanage-green-1)",
                      borderColor: "var(--comanage-green-1)",
                    },
                  }}
                />
              </Row>
            ))}
        </Column>
        {trainingLength < training_material.length - 1 && (
          <Row style={{ justifyContent: "center", marginTop: "5%" }}>
            <FormButton
              {...{
                name: "SHOW MORE",
                value: "SHOW MORE",
                style: { color: "black" },
                onClick: () => setTrainingLength(trainingLength + trainingStep),
              }}
            />
          </Row>
        )}
      </TmaterialsWrapper>
    </>
  );
};
