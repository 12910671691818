import React from "react";
import { Section5Styled as S5 } from "../style";

export const Section5 = ({ title = "", content = "" }) => {
  return (
    <S5.Wrapper>
      <S5.Title>{title}</S5.Title>
      <S5.Content dangerouslySetInnerHTML={{ __html: content }} />
    </S5.Wrapper>
  );
};
