import { useLoaderData } from "react-router-dom";
import { Pagewrapper, LeftSection, VerticalSlider, SliderDepth } from "./style";
import { useEffect, useRef, useState } from "react";
import { gettreeStruct } from "./utils";
import { Background } from "./components/Background";
import { InfoSection } from "./components/InfoSection";
import { History } from "./components/History";
import { TreeWizard } from "./components/TreeWizard";
import { ChatWrapper } from "./components/ChatWrapper";
import { DssSocket } from "../../calls/socket/dss.socket";

const dssSocket = new DssSocket();
export const HISTORY_CARD_SLIDE_ANIMATION_DURATION_MSEC = 2000;
const currentLang = window.localStorage.getItem("comanage_dss_lang") || "eng";
export const DssPage = () => {
  const { treeDssStruct, params, languages, dssInfoSection } = useLoaderData();
  const currentInfoSection = dssInfoSection.find((d) => d.lang === currentLang);
  const [history, setHistory] = useState([]);
  const [conversation, setConversation] = useState([
    { left: false, body: currentInfoSection?.dss_chat_first_message },
  ]);
  const [cleanHistory, setCleanHistory] = useState(false);
  const [targetPosition, setTargetPosition] = useState([0, 0]);
  const [chatConfig, setChatConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const wrapperref = useRef();
  const windowref = useRef();

  const onChatMessage = (message) => {
    setConversation((prev) => [...prev, { text: message, left: true }]);
    setLoading(true);
    dssSocket.askQuestion(message);
  };

  const putElementOnCenter = (node, preventHistory) => {
    const { position } = node;
    const targetid = "g" + position;
    const target = document.getElementById(targetid);
    if (!preventHistory) {
      const currentHistory = history;
      const newHistory = [
        ...currentHistory,
        {
          ...node,
          chartPosition: targetPosition,
          toRemove: false,
        },
      ];
      setHistory(newHistory);
    }
    if (!target) {
      setChatConfig(position);
      return;
    }
    const targetCurrentPosition = target.getBoundingClientRect();
    const wrapperPosition = wrapperref.current.getBoundingClientRect();
    const windowDimension = windowref.current.getBoundingClientRect();
    const newPosition = [
      targetCurrentPosition.y -
        wrapperPosition.y +
        targetCurrentPosition.height * 0.5 -
        windowDimension.height * 0.5,
      targetCurrentPosition.x -
        wrapperPosition.x +
        targetCurrentPosition.width * 0.5 -
        windowDimension.width * 0.5,
    ];

    setTargetPosition(newPosition);
  };

  useEffect(() => {
    putElementOnCenter({ position: "" }, true);
  }, []);

  dssSocket.waitAnswer("dss_page", (text, pages) => {
    setConversation((prev) => [
      ...prev,
      {
        text,
        left: false,
        references: pages,
      },
    ]);
    setLoading(false);
  });

  const layers = gettreeStruct(
    treeDssStruct.filter((card) => card.lang === currentLang)
  );

  if (cleanHistory) {
    const nodesToRemove = history.filter(({ toRemove }) => toRemove);
    if (nodesToRemove) {
      setTimeout(() => {
        const cleanedHistory = history.filter(({ toRemove }) => !toRemove);
        setHistory(cleanedHistory);
        setCleanHistory(false);
      }, HISTORY_CARD_SLIDE_ANIMATION_DURATION_MSEC - 10);
    }
  }

  console.log(conversation);

  return (
    <>
      <div>
        {false ? currentInfoSection.title : ""}
        <Background />
      </div>
      <Pagewrapper
        style={{
          "--animation-history-card-timing": `${
            HISTORY_CARD_SLIDE_ANIMATION_DURATION_MSEC / 1000
          }s`,
        }}
      >
        <LeftSection>
          <InfoSection
            languages={languages}
            data={dssInfoSection.find((d) => d.lang === currentLang)}
            currentLang={currentLang}
            onLangIconClick={({ id }) => {
              window.localStorage.setItem("comanage_dss_lang", id);
              window.location.reload();
            }}
          />
          <History
            oncCardClick={(node, index) => {
              setTargetPosition(node.chartPosition);
              const historyToClean = history.map((node, i) => ({
                ...node,
                toRemove: i >= index,
              }));
              setHistory(historyToClean);
              setCleanHistory(true);
              setChatConfig(null);
              setConversation([
                {
                  left: false,
                  body: currentInfoSection?.dss_chat_first_message,
                },
              ]);
            }}
            history={history}
          />
        </LeftSection>
        <VerticalSlider>
          <SliderDepth slideDown={!!chatConfig}>
            <TreeWizard
              {...{
                putElementOnCenter,
                windowref,
                layers,
                targetPosition,
                wrapperref,
                chatConfig,
                history: history,
                setHistory,
              }}
            />
            <ChatWrapper
              {...{
                conversation,
                onChatMessage,
                loading,
              }}
            />
          </SliderDepth>
        </VerticalSlider>
      </Pagewrapper>
      {false ? (
        <pre
          style={{
            position: "fixed",
            top: "2vh",
            width: "2vw",
            zIndex: 100,
            color: "red",
            fontSize: "1vw",
            backgroundColor: "rgba(255,255,255,0.2)",
          }}
        >
          {JSON.stringify({ loading }, null, "\t")}
        </pre>
      ) : (
        ""
      )}
    </>
  );
};
