import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const getPostDetail = async ({ id }) => {
  const url = ENDPOINTS.GET.POST.replace("%ID%", id);
  const data = await fetchData(url, {
    method: METHODS.GET,
  });

  return [data.find(({ id: pId }) => pId === +id)];
};
