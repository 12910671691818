import styled from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.footer`
  background-color: white;
  width: 100%;
  display: flex;
  position: relative;
  min-height: 600px;
  flex-wrap: wrap;
  @media (${SM_MEDIA_QUERY_RULE}) {
    min-height: 1000px;
  }
`;

export const FooterLogo = styled.img`
  width: 302px;
  height: 54px;
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  @media (${SM_MEDIA_QUERY_RULE}) {
    position: static;
    width: 90vw;
    min-height: fit-content;
    transform: translate(0%, 0%);
  }
`;

export const InfoSection = styled.div`
  flex-grow: 1.5;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
`;

export const InfoContent = styled.p`
  width: 350px;
  font-size: 11px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 100%;
  }
`;
export const MiniMenu = styled.div`
  flex-grow: 1;
`;

export const FooterSubTitle = styled.h4`
  font-family: "bicyclette" !important;
  font-size: 20px;
  margin-bottom: 20px;
`;
export const MiniMenuList = styled.div``;
export const MiniMenuItemList = styled.div``;
export const MiniMenuLink = styled.a`
  text-decoration: unset;
  font-family: "bicyclette" !important;
  color: var(--comanage-green-2);
  font-size: 17px;
`;
export const SocialLinks = styled.div`
  flex-grow: 1;
`;
export const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-grow: 1.5;
`;

export const Infosection = styled.div``;
export const FormSection = styled.div``;
export const InfoImage = styled.img`
  width: 220px;
`;
export const StyledForm = styled.form``;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const SocialMediaLinks = styled.div``;
export const SocialLink = styled.a``;
export const SocialImag = styled.img`
  height: 25px;
  margin-right: 10px;
  margin-right: 10px;
  :hover {
    opacity: 0.7;
  }
`;

export const SocialMadiaTitle = styled.h3``;

export const FormTitle = styled.h5``;

export const FetchResultMsgWrapper = styled.div``;

export const BgImgTopRight = styled.img`
  position: absolute;
  right: 10%;
  width: 15%;
  top: 0.5%;
  @media (${SM_MEDIA_QUERY_RULE}) {
    position: absolute;
    right: 2%;
    width: 77%;
    top: 0.5%;
  }
`;
export const BgImgBottomLeft = styled.img`
  position: absolute;
  left: 10%;
  width: 15%;
  bottom: 0%;
  @media (${SM_MEDIA_QUERY_RULE}) {
    visibility: hidden;
  }
`;

export const LowerLinksSection = styled.div`
  width: fit-content;
  bottom: 0px;
  width: 100%;
  text-align: center;
  font-family: "bicyclette" !important;
  font-weight: 300;
  margin-top: 30px;
  font-size: 15px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    width: 80vw;
    font-size: 12px;
    transform: translate(0%, 0%);
    position: static;
  }
`;
export const FooterLowerLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: var(--comanage-green-2);
`;

export const TopGradient = styled.div`
  position: absolute;
  width: 100%;
  min-height: 0.5%;
  max-height: 0.5%;
  background: linear-gradient(
    0.25turn,
    var(--comanage-green-1),
    var(--comanage-purple)
  );
`;

export const MainContent = styled.div`
  z-index: 2;
  position: absolute;
  top: 10%;
  left: 50%;
  width: 90vw;
  transform: translateX(-50%);
  bottom: 3%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (${SM_MEDIA_QUERY_RULE}) {
    align-items: center;
    width: 100vw;
  }
`;
