import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const CardWrapper = styled.div`
  * {
    margin: 0px;
  }
  color: white;
  width: 433px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 85vw;
  }
  border-radius: 20px 0px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center;
  margin: 10px 70px;
  background: linear-gradient( 0.25turn, var(--comanage-green-2), var(--comanage-green-1) );
  /* backdrop-filter: blur(15px) hue-rotate(362deg) brightness(1.1);
  background-image: radial-gradient(
    rgba(238, 0, 248, 0.47),
    rgb(219 0 255 / 36%)
  ); */
  /* box-shadow: rgba(130, 0, 222, 0.33) 2px 1px 3px,
    rgba(200, 119, 255, 0.4) 1px 0px 5px, rgb(93 10 162 / 69%) 20px 20px 60px,
    rgba(142, 69, 176, 0.46) -20px -20px 60px; */
  cursor: pointer;
  padding-bottom: 40px;
  position: relative;
  transition: all 0.5s ease 0s;
  left: 0px;
  border: solid 0.1px transparent;
  /* :hover {
    left: -30px;
    border: solid 0.1px rgb(189 120 0); */

    /* box-shadow: rgba(112, 112, 0, 0.35) 2px 1px 3px,
      rgba(255, 255, 133, 0.4) 1px 0px 5px, rgb(6 134 76 / 85%) 20px 20px 60px,
      rgb(0 222 122 / 51%) -20px -20px 60px; */
  /* } */
`;

export const CardTitle = styled.h4`
  margin-top: 40px;
  max-width: 300px;
  text-align: center;
  font-size: 17pt;
  letter-spacing: 1px;
  font: normal normal bold 20px/23px bicyclette;
  text-transform: capitalize;
  color: var(--comanage-purple);
`;
export const CardBody = styled.div`
  width: 299px;
  text-align: center;
  font-size: 15pt;
  font-weight: 300;
  line-height: 0.9em;
  text-align: justify;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90%;
  }
`;
export const CardButton = styled.a`
  all: unset;
  margin-bottom: 40px;
  margin: 10px;
  font: normal normal normal 15px/18px bicyclette;
  border: 2px solid white;
  border-radius: 20px 3px;
  width: 224px;
  min-height: 40px;
  max-height: 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
`;
