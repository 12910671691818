import React, { useEffect, useRef } from "react";

export const Map = ({ onCircleClick = () => {}, style = {}, svg }) => {
  const ref = useRef();
  useEffect(() => {
    const container = ref.current;
    const circles = Array.from(
      Array.from(container.childNodes).find((e) => e.tagName === "svg")
        .childNodes
    ).filter((e) => e.tagName === "circle");
    for (const circle of circles) {
      circle.addEventListener("click", (event) => {
        onCircleClick(event, circle.id);
      });
      circle.style.cursor = "pointer";
    }
  }, []);

  return (
    <div style={style} ref={ref} dangerouslySetInnerHTML={{ __html: svg }} />
  );
};
