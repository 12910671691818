import { useLoaderData, useNavigate } from "react-router-dom";
import { Wrapper, HomepageHeader } from "./style";
import { Section1 } from "./Sections/s1";
import { Section2 } from "./Sections/s2";
import { Section3 } from "./Sections/s3";
import { Section4 } from "./Sections/s4";
import { Section5 } from "./Sections/s5";
import { EventsNewsSection } from "../../components/EventsNewsSection/EventsNewsSection";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { getLatestEvent } from "../../utils/getLatestEvent";

const f = "dd/MM/yyyy h:mm a";

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const fetchedData = useLoaderData();
  const {
    homepage: [
      {
        acf: {
          title,
          subtitle,
          header_image,
          header_logo,
          header_image_sm,
          section_1_title,
          section_1_tab_1_title,
          section_1_tab_1_content,
          seciton_1_tab_1_url,
          section_1_tab_2_title,
          section_1_tab_2_content,
          section_1_tab_2_url,
          section_1_tab_3_title,
          section_1_tab_3_content,
          section_1_tab_3_url,
          seciton_1_tab_1_button_text,
          seciton_1_tab_2_button_text,
          seciton_1_tab_3_button_text,
          section_2_title,
          section_2_content,
          section_2_image,
          section_2_image_sm,
          section_3_title,
          section_3_content,
          section_3_svg,
          section_3_svg_background,
          section_4_row_1_title,
          section_4_row_2_title,
          section_4_row_1_content,
          section_4_row_1_button_text,
          section_4_row_2_button_text,
          section_4_row_1_button_url,
          section_4_row_2_button_url,
          section_5_title,
          section_5_content,
          section_4_row_2_content,
        } = {},
      } = {},
    ],
    postList: postListRaw = [],
  } = fetchedData;

  const section1Data = {
    title: section_1_title,
    tabs: [
      {
        title: section_1_tab_1_title,
        content: section_1_tab_1_content,
        url: seciton_1_tab_1_url,
        button: seciton_1_tab_1_button_text,
      },
      {
        title: section_1_tab_2_title,
        content: section_1_tab_2_content,
        url: section_1_tab_2_url,
        button: seciton_1_tab_2_button_text,
      },
      {
        title: section_1_tab_3_title,
        content: section_1_tab_3_content,
        url: section_1_tab_3_url,
        button: seciton_1_tab_3_button_text,
      },
    ],
  };

  const section2Data = {
    title: section_2_title,
    subTitle: section_2_content,
    img: isMobile ? section_2_image_sm.url : section_2_image.url,
    isMobile,
  };

  const section3Data = {
    title: section_3_title,
    content: section_3_content,
    svg: section_3_svg,
    bg: section_3_svg_background.url,
  };

  const section4Data = [
    {
      title: section_4_row_1_title,
      content: section_4_row_1_content,
      buttonText: section_4_row_1_button_text,
      buttonUrl: section_4_row_1_button_url,
    },
    {
      title: section_4_row_2_title,
      content: section_4_row_2_content,
      buttonText: section_4_row_2_button_text,
      buttonUrl: section_4_row_2_button_url,
    },
  ];

  const section5Data = {
    content: section_5_content,
    title: section_5_title,
  };

  const postList = postListRaw.map(({ acf, id, date_gmt: date }) => ({
    ...acf,
    id,
    date,
  }));

  const event = getLatestEvent(postList);
  const news = postList
    .filter(({ type }) => type === "news")
    .sort(({ date: A, date: B }) =>
      DateTime.fromFormat(A, f).toUnixInteger() >
      DateTime.fromFormat(B, f).toUnixInteger()
        ? 1
        : -1
    )
    .filter((_, i) => i <= 1);

  const onCircleClick = (event, circleId) => {
    navigate(`/pilot?id=${circleId}`);
  };

  return (
    <Wrapper>
      <HomepageHeader.Wrapper img={header_image.url}>
        <HomepageHeader.Logo src={header_logo.url} />
        <HomepageHeader.Content>
          <HomepageHeader.Title>{title}</HomepageHeader.Title>
          <HomepageHeader.SubTitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </HomepageHeader.Content>
      </HomepageHeader.Wrapper>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 {...{ ...section3Data, onCircleClick, isMobile }} />
      <Section4 {...{ list: section4Data }} />
      {isMobile ? (
        <>
          <Section5 {...section5Data} />
          <EventsNewsSection {...{ event, news }} />
        </>
      ) : (
        <>
          <EventsNewsSection {...{ event, news }} />
          <Section5 {...section5Data} />
        </>
      )}
    </Wrapper>
  );
};
