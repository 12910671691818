import styled from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";
import { SM_MEDIA_QUERY_RULE } from "../../config";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: white;
  width: 680px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    flex-direction: column;
    width: 90vw;
  }
`;
export const Img = styled.div`
  width: 272px;
  height: 188px;
  background: url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (${SM_MEDIA_QUERY_RULE}) {
    padding-bottom: 40px;
  }
`;
export const Title = styled.h3`
  width: 290px;
  font: normal normal bold 24px/28px bicyclette;
  margin-bottom: 13px;
  margin-top: 0px;
`;
export const Date = styled.div`
  margin-bottom: 10px;
  letter-spacing: 1px;
`;

export const Content = styled(LinesEllipsis)`
  width: 334px;
  font-size: 11px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    flex-direction: column;
    width: 80vw;
  }
`;
