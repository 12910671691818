import React from "react";
import { Section2Styled as S2 } from "../style";

export const Section2 = ({
  title = "",
  img = "",
  subTitle = "",
  isMobile = false,
}) => {
  return (
    <S2.Wrapper>
      <S2.Title>{title}</S2.Title>
      <S2.SubTitle>{subTitle}</S2.SubTitle>
      {isMobile ? (
        <S2.imgSm {...{ img }} />
      ) : (
        <S2.imgDesktop {...{ src: img }} />
      )}
    </S2.Wrapper>
  );
};
