import { PilotPage } from ".";
import { getPilotsList } from "../../calls/get/pilotsList";
import { getPostList } from "../../calls/get/postList";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const pilotRoute = {
  path: ROUTES.PILOT,
  element: <PilotPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    pilots: await getPilotsList(),
    postList: await getPostList(),
  }),
};
