import React from "react";
import PropTypes from "prop-types";
import LinesEllipsis from "react-lines-ellipsis";

import { Wrapper, Img, Colomn, Title, Date, Content } from "./style";

export const CardXL = ({ title, date, content, img }) => {
  return (
    <Wrapper>
      <Colomn>
        <Img src={img} />
      </Colomn>
      <Colomn>
        <Title>{title}</Title>
        <Date>{date}</Date>
        <Content
          text={content}
          maxLine="3"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </Colomn>
    </Wrapper>
  );
};

CardXL.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.string,
  img: PropTypes.string,
};

CardXL.defaultProps = {
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  date: "16 March",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat, turpis nec tristique…",
  img: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
};
