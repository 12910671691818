import { HomePage } from ".";
import { getHomePageData } from "../../calls/get/HomePageData";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
import { getPostList } from "./../../calls/get/postList";

export const homePageRoute = {
  path: ROUTES.HOMEPAGE,
  element: <HomePage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    homepage: await getHomePageData(),
    postList: await getPostList(),
  }),
};
