import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "../../../../config";

export const HistoryCardPosition = styled.div`
  @keyframes slidefromleft_sm {
    0% {
      left: calc(var(--window-width) * 1.5);
    }
    100% {
      left: 0px;
    }
  }
  @keyframes slidetoright_sm {
    0% {
      left: 0px;
    }
    100% {
      left: -100vw;
    }
  }

  @keyframes slidefromleft {
    0% {
      left: calc(var(--window-width) * 1.5);
    }
    100% {
      left: 0px;
    }
  }
  @keyframes slidetoright {
    0% {
      left: 0px;
    }
    100% {
      left: -50vw;
    }
  }
  @media (${SM_MEDIA_QUERY_RULE}) {
    animation-name: ${({ toRemove }) =>
      toRemove ? "slidetoright_sm" : "slidefromleft_sm"};
  }
  animation-name: ${({ toRemove }) =>
    toRemove ? "slidetoright" : "slidefromleft"};
  animation-duration: var(--animation-history-card-timing);
  position: relative;
  margin-left: ${({ index }) => index * 10}px;
  height: 100px;
  position: relative;
  top: -${({ index }) => index * 60}px;
  /* @media (${SM_MEDIA_QUERY_RULE}) {
    margin-left: -${({ index }) => index * 5}px;
  } */
`;
export const HistoryWrapper = styled.div`
  /* border: solid 1px blue; */
  width: 35vw;
  height: calc(var(--window-height) * 0.5);
  overflow: visible;
`;
