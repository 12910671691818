import { AboutPage } from ".";
import { getAboutPageData } from "../../calls/get/AboutPage";
import { getPostList } from "../../calls/get/postList";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const aboutPageRoute = {
  path: ROUTES.ABOUT,
  element: <AboutPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    page: await getAboutPageData(),
    postList: await getPostList(),
  }),
};
