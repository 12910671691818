import { PrivaciPolicy } from ".";
import { getComingSoonFooterData } from "../../calls/get/comingSoonFooter";
import { getComingSoonPrivacyPage } from "../../calls/get/comingSoonPrivacy";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const privacyPolicyRoute = {
  path: ROUTES.COMING_SOON_PRIVACY,
  element: <PrivaciPolicy />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    page: await getComingSoonPrivacyPage(),
    footer: await getComingSoonFooterData(),
  }),
};
