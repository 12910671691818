import React from "react";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { AiFillLinkedin, AiFillTwitterSquare } from "react-icons/ai";
import { ENDPOINTS } from "../../../config/endpoints";
export const SocialLogos = ({ id }) => {
  const url = ENDPOINTS.GET.SOCIAL_PREVIEW(id);
  return (
    <div>
      <LinkedinShareButton url={url} title="COMANAGE">
        <AiFillLinkedin
          style={{
            fill: "var(--comanage-green-2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          size={30}
        />
      </LinkedinShareButton>
      <TwitterShareButton url={url} title="COMANAGE">
        <AiFillTwitterSquare
          style={{
            fill: "var(--comanage-green-2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          size={30}
        />
      </TwitterShareButton>
    </div>
  );
};
