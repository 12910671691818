import { PartnersPage } from ".";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const partnersPageRoute = {
  path: ROUTES.PARTNERS,
  element: <PartnersPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({ test: "test", params }),
};
