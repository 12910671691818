import React, { useEffect, useState } from "react";
import { FormButton } from "../../../components/FormButton/FormButton";
import { download } from "../../../utils/download";
import { Body, Column, DocumentNoImageWrapper, Row, Title } from "../style";

const bordered = { borderBottom: "solid 1px var(--comanage-green-2)" };
const downloadStep = 2;

export const SimpleDocumentsDownloadSection = ({ docs, isMobile }) => {
  const tags = [
    ...new Set(docs.map(({ document_type_section: { tag } }) => tag)),
  ];
  const [downloadsLength, setDownloadsLength] = useState(downloadStep);
  const [currentTab, setCurrentTab] = useState(tags[0]);
  const currentTabData = docs.filter(
    ({ document_type_section: { tag } }) => tag === currentTab
  );
  let smStyle = {};
  if (isMobile) {
    smStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: "10px",
    };
  }

  return (
    <DocumentNoImageWrapper>
      <Body>
        <Row
          style={{
            justifyContent: "flex-start",
            gap: "3vw",
            ...bordered,
            alignItems: "center",
            width: "calc(100% - 40px)",
            marginLeft: "20px",
            paddingBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          {tags.map((tag) => (
            <FormButton
              {...{
                key: tag,
                value: tag.toUpperCase(),
                name: tag.toUpperCase(),
                onClick: () => {
                  setCurrentTab(tag);
                  setDownloadsLength(downloadStep);
                },
                outline: currentTab === tag,
                style: {
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  fontWeight: currentTab === tag ? 300 : 500,
                  color:
                    currentTab === tag ? "white" : "var(--comanage-purple)",
                },
              }}
            />
          ))}
        </Row>
        <Column style={{ width: "calc(100% - 40px)", marginLeft: "20px" }}>
          {currentTabData
            .filter((_, i) => i <= downloadsLength)
            .map(
              (
                {
                  section_id,
                  lang,
                  content_type,
                  visual,
                  document_type_section: { title, button_text, file, tag },
                  id,
                },
                key
              ) => {
                return (
                  <Row
                    {...{
                      key: id,
                      style: {
                        ...bordered,
                        ...smStyle,
                        width: "100%",
                        marginTop: "50px",
                      },
                    }}
                  >
                    <Title
                      style={{
                        color: "white",
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                    >
                      {title}
                    </Title>
                    <FormButton
                      {...{
                        value: button_text,
                        name: button_text,
                        onHoverStyle: {
                          backgroundColor: "var(--comanage-green-1)",
                          borderColor: "var(--comanage-green-1)",
                        },
                        onClick: () => download(file.url, title),
                      }}
                    />
                  </Row>
                );
              }
            )}
        </Column>

        {downloadsLength < currentTabData.length && (
          <Row
            style={{
              justifyContent: "center",
              marginTop: isMobile ? "20vw" : "5%",
            }}
          >
            <FormButton
              {...{
                name: "SHOW MORE",
                value: "SHOW MORE",
                onHoverStyle: {
                  backgroundColor: "var(--comanage-green-1)",
                  borderColor: "var(--comanage-green-1)",
                },
                onClick: () =>
                  setDownloadsLength(downloadsLength + downloadStep),
              }}
            />
          </Row>
        )}
      </Body>
    </DocumentNoImageWrapper>
  );
};
