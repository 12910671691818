import { Link, useLoaderData } from "react-router-dom";
import {
  Wrapper,
  HomepageHeader,
  Title,
  Section4Styled as S4,
  Section1Detail as S1d,
  VideWrapper,
} from "./style";

import {
  COP_LOCALSTORAGE_LANG_KEY,
  COP_LOCALSTORAGE_LANG_LIST_KEY,
  COP_DEFAULT_LANG,
  SM_MEDIA_QUERY_THRESHOLD,
  ANIMATION_TIMING_MILSECS,
} from "../../config";
import { useState } from "react";
import { Links } from "./Components/Links";
import { ROUTES } from "../../config/menuRoutes";

export const COPPage = () => {
  const [currentSection, setcurrentSection] = useState(null);
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const { copPage, categories, langs } = useLoaderData();
  const {
    acf: {
      title,
      subtitle,
      header_image,
      header_logo,
      header_image_sm,
      e_learning_platform_section,
      dss_section,
      digital_hub_section,
      categories_title,
      platforms_list,
      categories_section_title,
      tools: { title: toolSectionTitle, section_1, section_2, section_3 },
      embedded_youtube_video = null,
      ...fetchedData
    } = {},
  } = copPage.find(
    ({ acf: { lang } }) =>
      (window.localStorage.getItem(COP_LOCALSTORAGE_LANG_KEY) ||
        COP_DEFAULT_LANG) === lang
  );

  const currentCategories = categories.filter(
    ({ acf: { lang } }) =>
      (window.localStorage.getItem(COP_LOCALSTORAGE_LANG_KEY) ||
        COP_DEFAULT_LANG) === lang
  );

  const onCardClick = (e, title, content, url, illustration, id) => {
    document.location.replace(url);
  };
  const section1Cards = [
    {
      title: section_1.title,
      content: section_1.description,
      url: section_1.button_url,
      buttonText: section_1.button_text,
    },
    {
      title: section_2.title,
      content: section_2.description,
      url: section_2.button_url,
      buttonText: section_2.button_text,
    },
    {
      title: section_3.title,
      content: section_3.description,
      url: section_3.button_url,
      buttonText: section_3.button_text,
    },
  ].filter(({ title }) => !!title);

  const langlist = JSON.stringify(
    Object.fromEntries(
      langs.map(
        ({
          acf: {
            id,
            icon: { url },
          },
        }) => [id, url]
      )
    )
  );
  window.localStorage.setItem(COP_LOCALSTORAGE_LANG_LIST_KEY, langlist);
  return (
    <Wrapper>
      <HomepageHeader.Wrapper img={header_image.url}>
        <Link
          style={{ all: "unset", cursor: "pointer" }}
          to={window.location.origin}
        >
          <HomepageHeader.Logo src={header_logo.url} />
        </Link>
        <HomepageHeader.Content>
          <HomepageHeader.Title>{title}</HomepageHeader.Title>
          <HomepageHeader.SubTitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </HomepageHeader.Content>
      </HomepageHeader.Wrapper>
      <Links
        {...{
          currentSection,
          isMobile,
          title: toolSectionTitle,
          // title: categories_title,
          tabs: section1Cards,
          onCardClick,
        }}
      />
      {embedded_youtube_video ? (
        <VideWrapper
          dangerouslySetInnerHTML={{ __html: embedded_youtube_video }}
        />
      ) : (
        ""
      )}
      <Title style={{ margin: "50px 0px" }}>{categories_section_title}</Title>
      <S1d.Wrapper>
        {currentCategories
          .filter(({ acf: { maincategory = "yes" } }) => maincategory === "yes")
          .map(
            (
              {
                acf: {
                  name,
                  description,
                  buttonUrl = "",
                  buttonText = "OPEN",
                  id,
                },
              },
              key
            ) => (
              <S4.Content.Wrapper {...{ key }}>
                <S4.Content.Title>{name}</S4.Content.Title>
                <S4.Content.Row>
                  <S4.Content.Body
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <Link
                    className={ROUTES.COP_CATEGORY_PAGE}
                    style={{
                      all: "unset",
                      textAlign: isMobile ? "right" : "unset",
                    }}
                    to={`/${ROUTES.COP_CATEGORY_PAGE}?id=${id}`}
                  >
                    <S4.Content.Button className={ROUTES.COP_CATEGORY_PAGE}>
                      {buttonText}
                    </S4.Content.Button>
                  </Link>
                </S4.Content.Row>
              </S4.Content.Wrapper>
            )
          )}
      </S1d.Wrapper>
    </Wrapper>
  );
};
