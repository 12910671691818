import { ROUTES } from "./menuRoutes";

export const COMING_SOON_ACTIVE = false;

export const DEBOUNCE_TIMEOUT_MS = 500;

export const PATH_WP_LOCAL = `http://localhost:80/index.php/wp-json/wp/v2`;
export const PATH_WP = `https://comanage-dev.spindoxlabs.com/index.php/wp-json/wp/v2`;
export const BASE_PATH_WP_EC2 = `https://comanage-dev.spindoxlabs.com/`;

export const PATH_PROXY_LOCAL = `http://localhost:3001`;

export const METHODS = {
  GET: "GET",
  POST: "POST",
};

export const MENULESS_PAGES = [ROUTES.COP_POST.split("/")[0]].map(
  (c) => `/${c}`
);

export const MULTI_LANG_PAGES = [ROUTES.COPPAGE, ROUTES.COP_CATEGORY_PAGE].map(
  (c) => `/${c}`
);

export const COMING_SOON_FORM_DATA = {
  FNAME: "FNAME",
  LNAME: "LNAME",
  EMAIL: "EMAIL",
  MMERGE3: "MMERGE3",
  subscribe: "subscribe",
};

export const BIG_SCREEN_MEDIA_QUERY_THRESHOLD = 1400;
export const BIG_SCREEN_MEDIA_QUERY_RULE = `min-width: ${BIG_SCREEN_MEDIA_QUERY_THRESHOLD}px`;

export const SM_MEDIA_QUERY_THRESHOLD = 767;

export const SM_MEDIA_QUERY_RULE = `max-width: ${SM_MEDIA_QUERY_THRESHOLD}px`;

export const EMAIL_TEST_REGEX = /.+@.+\.\w{1,3}/gm;

export const SUBSCRIBE_FORM_VALIDATION_ERROR = "this field is required";

export const COMANAGE_LOCAL_LOGO_IMG = "COMANAGE-logo.png";
export const COMANAGE_LOCAL_BGI_MG = "COMANAGE-graphic.png";

export const EVENTS_NEWS_ENEBLED = true;
export const TRAINING_MATERIALS_NEWS_ENEBLED = false;

export const MENU_ITEMS = (children = []) => [
  { title: "Home", href: ROUTES.HOMEPAGE },
  { title: "About", href: ROUTES.ABOUT },
  { title: "Pilots", children },
  { title: "Library", href: ROUTES.LIBRARY },
  { title: "News & Events", href: ROUTES.NEWS_EVENTS },
  // { title: "Partners", href: ROUTES.ABOUT + "?target=partners" },
  { title: "Contact us", href: "#footer" },
];

export const COP_DEFAULT_LANG = "eng";
export const COP_LOCALSTORAGE_LANG_KEY = "comanage_cop_language";
export const COP_LOCALSTORAGE_LANG_LIST_KEY = "comanage_cop_language_list";

export const ANIMATION_TIMING_MILSECS = 500;

export const SOCKET_CONFIG = {
  reconnectionDelay: 1000,
  reconnection: true,
  reconnectionAttemps: 10,
  transports: ["websocket"],
  agent: false,
  upgrade: false,
  rejectUnauthorized: false,
};

export const SOCKET_DSS_MESSAGE_TYPE = "dss_question";

export const DRIVE_BASE_PATH_REDIRECT = 'https://drive.google.com/file/d/%FILE_ID%/view'