import { NewsletterPage } from ".";
import { getNewsEventPageData } from "../../calls/get/newsEventsPage";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const newsletterPageRoute = {
  path: ROUTES.NEWSLETTER,
  element: <NewsletterPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    params,
    pageData: await getNewsEventPageData(),
  }),
};
