export const ROUTES = {
  HOMEPAGE: "home",
  ABOUT: "about",
  LIBRARY: "library",
  PARTNERS: "partners",
  NEWS_EVENTS: "newsevents",
  PAST_EVENTS: "pastevents",
  COMING_SOON_HOMEPAGE: "",
  COMING_SOON_PRIVACY: "privacy",
  POST: "post/:id",
  COP_POST: "cop_post/:id",
  PILOT: "pilot",
  NEWSLETTER: "newsletter",
  COPPAGE: "cop",
  COP_CATEGORY_PAGE: "cop_category",
  REDIRECT: "",
  DSS: "dss",
};
