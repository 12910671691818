import styled from "styled-components";
import { BIG_SCREEN_MEDIA_QUERY_RULE, SM_MEDIA_QUERY_RULE } from "../../config";

export const Background = styled.div`
  background-color: var(--comanage-purple);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  @media (${BIG_SCREEN_MEDIA_QUERY_RULE}) {
    max-width: var(--max-width);
  }
`;

export const Row = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: var(--max-width);
  @media (${SM_MEDIA_QUERY_RULE}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
export const Title = styled.h3`
  color: white;
  font: normal normal bold 30px/36px bicyclette;
`;
export const Column = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    height: fit-content;
  }
`;

export const Line = styled.div`
  min-width: 100%;
  min-height: 2px;
  background-color: var(--comanage-green-3);
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const NewsLetterSection = styled.div`
  color: white;
  margin-bottom: 20px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    display: flex;
    flex-direction: column;
  }
`;
export const NewsLetterTitle = styled.h3`
  margin-top: 0px;
`;
export const NewsLetterContent = styled.div`
  max-width: 726px;

  min-width: 726px;
  @media (${SM_MEDIA_QUERY_RULE}) {
    max-width: 90vw;
    min-width: 90vw;
    height: fit-content;
    margin-bottom: 20px;
  }
`;

export const SignUpWrapper = styled.div`
  @media (${SM_MEDIA_QUERY_RULE}) {
    width: 90vw;
    height: fit-content;
    position: relative;
  }
`;
