import React, { useState } from "react";
import { FormButton } from "../../../components/FormButton/FormButton";
import { download } from "../../../utils/download";
import { Body, Column, CommKitsWrapper, Row, Title } from "../style";

const bordered = { borderBottom: "solid 1px var(--comanage-green-2)" };
const downloadStep = 2;

export const CommKits = ({ pilots, isMobile }) => {
  const [downloadsLength, setDownloadsLength] = useState(downloadStep);
  const [currentTab, setCurrentTab] = useState(pilots[0].acf.id);
  const { acf: currentTabData } = pilots.find(
    ({ acf: { id } }) => id === currentTab
  );
  const downloads = Object.entries(currentTabData).filter(
    ([key, val]) => key.includes("download") && !!val
  );
  let smStyle = {};
  if (isMobile) {
    smStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: "10px",
    };
  }

  return (
    <CommKitsWrapper>
      <Body>
        <Title style={{ color: "white", marginBottom: "70px" }}>
          Communication kit
        </Title>
        <Row
          style={{
            justifyContent: "flex-start",
            gap: "3vw",
            ...bordered,
            alignItems: "center",
            width: "calc(100% - 40px)",
            marginLeft: "20px",
            paddingBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          {pilots.map(({ acf: { id } }) => (
            <FormButton
              {...{
                key: id,
                value: id.toUpperCase(),
                name: id.toUpperCase(),
                onClick: () => {
                  setCurrentTab(id);
                  setDownloadsLength(downloadStep);
                },
                outline: currentTab === id,
                style: {
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  fontWeight: currentTab === id ? 300 : 500,
                  color: currentTab === id ? "white" : "var(--comanage-purple)",
                },
              }}
            />
          ))}
        </Row>
        <Column style={{ width: "calc(100% - 40px)", marginLeft: "20px" }}>
          {downloads
            .filter((_, i) => i <= downloadsLength)
            .map(([, { filename, url }], key) => {
              return (
                <Row
                  {...{
                    key: filename + key,
                    style: {
                      ...bordered,
                      ...smStyle,
                      width: "100%",
                      marginTop: "50px",
                    },
                  }}
                >
                  <Title
                    style={{
                      color: "white",
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                  >
                    {filename}
                  </Title>
                  <FormButton
                    {...{
                      value: "DOWNLOAD",
                      name: "DOWNLOAD",
                      onHoverStyle: {
                        backgroundColor: "var(--comanage-green-1)",
                        borderColor: "var(--comanage-green-1)",
                      },
                      onClick: () => download(url, filename),
                    }}
                  />
                </Row>
              );
            })}
        </Column>

        {downloadsLength < downloads.length && (
          <Row
            style={{
              justifyContent: "center",
              marginTop: isMobile ? "20vw" : "5%",
            }}
          >
            <FormButton
              {...{
                name: "SHOW MORE",
                value: "SHOW MORE",
                onHoverStyle: {
                  backgroundColor: "var(--comanage-green-1)",
                  borderColor: "var(--comanage-green-1)",
                },
                onClick: () =>
                  setDownloadsLength(downloadsLength + downloadStep),
              }}
            />
          </Row>
        )}
      </Body>
    </CommKitsWrapper>
  );
};
