import { COMING_SOON_FORM_DATA, METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const comingSoonSubscribe = async (event) => {
  const formData = new FormData(event.target);
  const partialQuery = Object.values(COMING_SOON_FORM_DATA)
    .filter(Boolean)
    .map((val) => [val, formData.get(val)]);
  const rawQuery = {
    ...Object.fromEntries(partialQuery),
    u: "4a69bb28447cf107c6b051e91",
    id: "b71aa0a847",
    c: "jQuery190010418289168819705_1678356477412",
    f_id: "006efee1f0",
    b_4a69bb28447cf107c6b051e91_b71aa0a847: "",
    "-": Date.now(),
    [event.target[4].name]: event.target[4].value,
  };
  const query = Object.entries(rawQuery)
    .map((e) => e.filter(Boolean).join("="))
    .join("&")
    .replace(/\s/gm, "+");

  const url = `${ENDPOINTS.POST.SUBSCRIBE.URL}?${query}`;
  const proxyUrl = ENDPOINTS.POST.SUBSCRIBE.PROXY;
  const raw_resp = await fetchData(proxyUrl, {
    method: METHODS.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url }),
  });
  try {
    const response = JSON.parse(raw_resp.match(/{.+}/gm)[0]);
    return response;
  } catch (err) {
    console.error(err);
    return {
      result: "error",
      msg: "something went wrong",
    };
  }
};

export const subscribe = async ({ EMAIL }) => {

  const rawQuery = {
    EMAIL, MMERGE3: 'I+agree+to+privacy+policy',
    u: "4a69bb28447cf107c6b051e91",
    id: "b71aa0a847",
    c: "jQuery190010418289168819705_1678356477412",
    f_id: "006efee1f0",
    b_4a69bb28447cf107c6b051e91_b71aa0a847: "",
    "-": Date.now(),
    subscribe: 'SIGN UP'
  };
  const query = Object.entries(rawQuery)
    .map((e) => e.filter(Boolean).join("="))
    .join("&")
    .replace(/\s/gm, "+");

  const url = `${ENDPOINTS.POST.SUBSCRIBE.URL}?${query}`;
  const proxyUrl = ENDPOINTS.POST.SUBSCRIBE.PROXY;
  try {
    const raw_resp = await fetchData(proxyUrl, {
      method: METHODS.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    });
    const response = JSON.parse(raw_resp.match(/{.+}/gm)[0]);
    return response;
  } catch (err) {
    console.error(err);
    return {
      result: "error",
      msg: "something went wrong",
    };
  }
};