import { COPPage } from ".";
import { getCopPageCategoriesData } from "../../calls/get/CopPageCategoriesData";
import { getCopPageContentsData } from "../../calls/get/CopPageContentsData";
import { getCopPageData } from "../../calls/get/CopPageData";
import { getLanguagesData } from "../../calls/get/LanguagesData";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";
// import { getPostList } from "./../../calls/get/postList";

export const COPPageRoute = {
  path: ROUTES.COPPAGE,
  element: <COPPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    copPage: await getCopPageData(),
    categories: await getCopPageCategoriesData(),
    contents: await getCopPageContentsData(),
    langs: await getLanguagesData(),
  }),
};
