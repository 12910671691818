import { METHODS } from "../../config";
import { ENDPOINTS } from "../../config/endpoints";
import { fetchData } from "../../utils/fetchData";

export const getRedirectPageData = async () => {
  const data = await fetchData(ENDPOINTS.GET.REDIRECT_PAGE, {
    method: METHODS.GET,
  });
  return data;
};
