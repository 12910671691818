import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorWarning,
  StyledFormInput,
  Wrapper,
  StyledFormTextArea,
} from "./style";
import { getRenderedWidth } from "../../utils/getRenderedWidth";

export const FormInput = ({
  name,
  width,
  placeholder,
  errorText,
  style = {},
  textarea,
  styleType,
  onChange = () => {},
}) => {
  const [pxwidth, setpxwidth] = useState(0);
  const ref = useRef();
  useEffect(() => {
    setpxwidth(getRenderedWidth(ref.current));
  }, []);
  if (width) style.width = width;

  const inputProps = {
    type: "text",
    name,
    placeholder,
    ref,
    error: !!errorText,
    width,
    styleType,
    onChange,
  };
  return (
    <Wrapper {...{ style }}>
      {textarea ? (
        <StyledFormTextArea {...inputProps} />
      ) : (
        <StyledFormInput {...inputProps} />
      )}
      {errorText && (
        <ErrorWarning {...{ width: pxwidth }}>{errorText}</ErrorWarning>
      )}
    </Wrapper>
  );
};

FormInput.propTypes = {
  textarea: PropTypes.bool,
  styleType: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.any,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  width: PropTypes.string,
};

FormInput.defaultProps = {
  textarea: false,
  styleType: "a",
  name: "LNAME",
  placeholder: "placeholder",
  errorText: "",
  width: "200px",
};
