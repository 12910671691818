import { LibraryPage } from ".";
import { getLibraryPageData } from "../../calls/get/LibraryPage";
import { getPilotsList } from "../../calls/get/pilotsList";
import { getLibraryPageDownloadsData } from "../../calls/get/LibraryPageDownloads";
import { ROUTES } from "../../config/menuRoutes";
import { FetchErrorPage } from "../FetchError";

export const libraryRoute = {
  path: ROUTES.LIBRARY,
  element: <LibraryPage />,
  errorElement: <FetchErrorPage />,
  loader: async ({ params }) => ({
    test: "test",
    params,
    files: await getLibraryPageDownloadsData(),
    pageData: await getLibraryPageData(),
    pilots: await getPilotsList(),
  }),
};
