import React from "react";
import PropTypes from "prop-types";
import { FormButton } from "./../FormButton/FormButton";

import { Wrapper, Img, Title, Column, Content } from "./style";

export const CardLibrary = ({
  title,
  onButtonClick,
  img,
  big = false,
  withContent = false,
  content,
}) => {
  return big ? (
    <Wrapper
      big={big}
      style={{ gap: "20px", width: "100%", flexDirection: "row" }}
    >
      <Column
        style={{
          flexGrow: 6,
          background: `url(${img})`,
          minHeight: "367px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Column>
      <Column
        style={{
          flexGrow: 0.5,
          justifyContent: "space-around",
          minHeight: "367px",
        }}
      >
        <div>
          <Title>{title}</Title>
          {withContent && (
            <Content
              text={content}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </div>
        <FormButton
          {...{
            value: "DOWNLOAD",
            name: "DOWNLOAD",
            style: {
              backgroundColor: "var(--comanage-green-2)",
              borderColor: "var(--comanage-green-2)",
            },
            onHoverStyle: {
              backgroundColor: "var(--comanage-green-1)",
              borderColor: "var(--comanage-green-1)",
            },
            onClick: onButtonClick,
          }}
        />
      </Column>
    </Wrapper>
  ) : (
    <Wrapper>
      <Column style={{ height: "174px" }}>
        <Img src={img} />
      </Column>
      <Column style={{ paddingBottom: "20px" }}>
        <Title>{title}</Title>
        <Content
          text={content}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
        <FormButton
          {...{
            value: "DOWNLOAD",
            name: "DOWNLOAD",
            style: {
              backgroundColor: "var(--comanage-green-2)",
              borderColor: "var(--comanage-green-2)",
            },
            onHoverStyle: {
              backgroundColor: "var(--comanage-green-1)",
              borderColor: "var(--comanage-green-1)",
            },
            onClick: onButtonClick,
          }}
        />
      </Column>
    </Wrapper>
  );
};

CardLibrary.propTypes = {
  title: PropTypes.string,
  big: PropTypes.bool,
  withContent: PropTypes.bool,
  img: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

CardLibrary.defaultProps = {
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  img: "https://photographylife.com/wp-content/uploads/2010/04/20100415-Dead-Horse-Point-040.jpg",
  content: "",
  big: false,
  withContent: false,
  onButtonClick: () => {},
};
