import styled, { css } from "styled-components";
import { SM_MEDIA_QUERY_RULE } from "./../../config/index";
export const Wrapper = styled.div`
  width: 653px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  color: ${({ color }) => color};
  @media (${SM_MEDIA_QUERY_RULE}) {
    flex-direction: column;
    width: 90vw;
  }
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 5px;
`;

export const Title = styled.h3`
  color: ${({ color }) => color};
  margin-top: 0px;
  margin-bottom: 10px;
  font: normal normal bold 30px/36px bicyclette;
`;
export const Content = styled.div`
  color: ${({ color }) => color};
  font: normal normal normal 16px/19px bicyclette;
`;
export const Column = styled.div`
  ${({ keepMarginLogo }) =>
    keepMarginLogo
      ? css`
          min-width: 24px;
        `
      : ""}
`;
