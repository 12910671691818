import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { EventsNewsSection } from "../../components/EventsNewsSection/EventsNewsSection";
import { InfoContent } from "../../components/Footer/style";
import { Header } from "../../components/Header/Header";
import { InfoPoint } from "../../components/InfoPoint/InfoPoint";
import {
  Paragraph,
  PARAGRAPH_COLORS,
} from "../../components/Paragraph/Paragraph";
import {
  ParagraphList,
  PARAGRAPH_LIST_BORDERS,
} from "../../components/ParagraphList/ParagraphList";
import { SM_MEDIA_QUERY_THRESHOLD } from "../../config";
import { Wrapper, Row, MainImage, Column } from "./style";
import { getLatestEvent } from "../../utils/getLatestEvent";

export const PilotPage = () => {
  const isMobile = window.innerWidth <= SM_MEDIA_QUERY_THRESHOLD;
  const { pilots, postList: postListRaw = [] } = useLoaderData();
  const [, pilotId] = window.location.search.replace(/^\?/gm, "").split("=");
  const {
    "header-logo": headerLogo,
    title,
    header_bg_image,
    content,
    subtitle: subTile,
    infographics_1,
    infographics_2,
    infographics_3,
    content_1_a_title,
    content_1_a,
    content_1_b_title,
    content_1_b,
    content_2_a_title,
    content_2_a,
    content_2_b_title,
    content_2_b,
    content_type_b_logo,
    list_1_a_content,
    list_1_a_title,
    list_2_a_content,
    list_2_a_title,
    list_1_b_content,
    list_1_b_title,
    list_2_b_content,
    list_2_b_title,
    pilot_image,
  } = pilots.find(({ acf: { id } }) => pilotId == id).acf;

  const postList = postListRaw.map(({ acf, id, date_gmt: date }) => ({
    ...acf,
    id,
    date,
  }));

  const event = getLatestEvent(postList);
  const news = postList
    .filter(({ type }) => type === "news")
    .sort(({ date: A, date: B }) =>
      new Date(A).getTime() > new Date(B).getTime() ? 1 : -1
    )
    .filter((_, i) => i <= 1);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <Header
        {...{
          logo: headerLogo.url,
          title,
          subTile,
          content,
          imageTopRight: null,
          background: header_bg_image.url,
          clearBg: true,
        }}
      />
      <Wrapper>
        <Row
          style={{
            justifyContent: isMobile ? "flex-start" : "space-around",
            flexDirection: isMobile ? "column" : "row",
            marginTop: "70px",
            flexWrap: "wrap",
          }}
        >
          <InfoPoint content={infographics_1} />
          <InfoPoint content={infographics_2} />
          <InfoPoint content={infographics_3} />
        </Row>
        <Row
          style={{
            justifyContent: "space-between",
            marginTop: isMobile ? "35px" : "70px",
            flexWrap: "wrap",
            gap: isMobile ? "35px" : "0px",
          }}
        >
          <Paragraph
            {...{
              title: content_1_a_title,
              logo: null,
              content: content_1_a,
              color: PARAGRAPH_COLORS.MIXED,
            }}
          />
          <Paragraph
            {...{
              title: content_1_b_title,
              logo: content_type_b_logo.url,
              content: content_1_b,
              color: PARAGRAPH_COLORS.PURPLE,
            }}
          />
        </Row>
        <Row
          style={{
            justifyContent: "space-between",
            marginTop: "70px",
            flexWrap: "wrap",
            gap: "75px",
          }}
        >
          <MainImage src={pilot_image.url} />
          <Column style={isMobile ? { gap: "35px" } : {}}>
            <Paragraph
              {...{
                title: content_2_a_title,
                logo: null,
                content: content_2_a,
                color: PARAGRAPH_COLORS.MIXED,
                keepMarginLogo: true,
              }}
            />
            <Paragraph
              {...{
                style: isMobile ? {} : { marginTop: "70px" },
                title: content_2_b_title,
                logo: content_type_b_logo.url,
                content: content_2_b,
                color: PARAGRAPH_COLORS.PURPLE,
              }}
            />
          </Column>
        </Row>
        <Row
          style={{
            justifyContent: "space-around",
            marginTop: "70px",
            flexWrap: "wrap",
          }}
        >
          <ParagraphList
            title={list_1_a_title}
            content={list_1_a_content.split("\n")}
            logo={null}
            border={PARAGRAPH_LIST_BORDERS.GREEN}
          />
          <ParagraphList
            title={list_1_b_title}
            content={list_1_b_content.split("\n")}
            logo={content_type_b_logo.url}
            border={PARAGRAPH_LIST_BORDERS.PURPLE}
          />
        </Row>
        <Row
          style={{
            justifyContent: "space-around",
            marginTop: "70px",
            marginBottom: "40px",
            flexWrap: "wrap",
            marginBottom: isMobile ? "70px" : "100px",
            gap: isMobile ? "35px" : "0px",
          }}
        >
          <ParagraphList
            title={list_2_a_title}
            content={list_2_a_content.split("\n")}
            logo={null}
            border={PARAGRAPH_LIST_BORDERS.GREEN}
          />
          <ParagraphList
            title={list_2_b_title}
            content={list_2_b_content.split("\n")}
            logo={content_type_b_logo.url}
            border={PARAGRAPH_LIST_BORDERS.PURPLE}
          />
        </Row>
      </Wrapper>
      <EventsNewsSection {...{ event, news }} />
    </>
  );
};
