import { useEffect, useState } from "react";
import { Outlet, Link, useLoaderData } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Menu } from "../../components/Menu/Menu";
import { MENULESS_PAGES, MENU_ITEMS } from "../../config";
import { ROUTES } from "../../config/menuRoutes";
import styled from "styled-components";
const MainWrapper = styled.div`
  /* max-width: 1400px; */
`;

function AppTemplate() {
  const { footer, pilots, multiLang, languages, copPageData } = useLoaderData();
  const [menuLess, setMenuLess] = useState(false);
  const menuSubitems = pilots.map(({ acf: { id }, title: { rendered } }) => ({
    href: `pilot?id=${id}`,
    title: rendered.toUpperCase(),
  }));
  const footerSubItems = pilots.map(({ acf: { id } }) => ({
    href: `pilot?id=${id}`,
    title: id.toUpperCase(),
  }));
  useEffect(() => {
    const queryString = window.location.search;
    const { hash } = window.location;
    if (!queryString.includes("target")) window.scrollTo({ top: 0 });
    if (hash) {
      try {
        const element = document.querySelectorAll(`[href="${hash}"]`)[0];
        if (element) {
          element.scrollIntoView({
            block: "start",
            inline: "start",
          });
        }
      } catch (err) {
        console.log("element not found, cant scroll into element", err);
      }
    }
  }, []);
  useEffect(() => {
    const mLess = !!MENULESS_PAGES.find((e) =>
      window.location.pathname.includes(e)
    );
    if (mLess !== menuLess) setMenuLess(mLess);
  });
  return (
    <>
      <Menu
        {...{
          ...footer[0].acf,
          menuLinks: MENU_ITEMS(menuSubitems),
          multiLang,
          menuLess,
          languages,
        }}
      />
      <MainWrapper>
        <Outlet />
      </MainWrapper>
      <Footer
        {...{
          ...footer[0].acf,
          FooterMenuLinks: MENU_ITEMS(footerSubItems),
          multiLang,
          copPageData,
          onPrivacyLinkClick: () =>
            (window.location.href = ROUTES.COMING_SOON_PRIVACY),
        }}
      />
    </>
  );
}

export default AppTemplate;
